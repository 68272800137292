import React, { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { User } from '@axiom/validation';
import { CondensedLarge, ContextMenuItem, ToastUtil } from '@axiom/ui';

import { isSalesUser, isTalentSuccessUser } from '../../utils/roles';
import { EnvUtil } from '../../utils/env-util';

interface CandidateCalendarMenuContentProps extends PropsWithChildren {
  user: User;
  onEditAvailability: Dispatch<SetStateAction<boolean>>;
}

export const CandidateCalendarMenuContent: FC<
  CandidateCalendarMenuContentProps
> = ({ user, onEditAvailability, children }) => {
  if (!user || isSalesUser(user)) return null;
  return (
    <>
      {children}
      {isTalentSuccessUser(user) && (
        <ContextMenuItem onClick={() => onEditAvailability(true)} name="EDIT">
          Edit Interview Availability
        </ContextMenuItem>
      )}
      <ContextMenuItem
        name="COPY_URL"
        onClick={async () => {
          await navigator.clipboard.writeText(
            `${EnvUtil.portalUrl}/talent/profile/interview-availability`
          );
          const toastName = `COPY_TALENT_AVAILABILITY`;

          ToastUtil.add({
            name: toastName,
            type: 'info',
            children: (
              <CondensedLarge>Talent availability link copied!</CondensedLarge>
            ),
          });
        }}
      >
        Copy Interview Availability Link
      </ContextMenuItem>
    </>
  );
};
