/* eslint-disable unicorn/no-thenable */
import Ajv from 'ajv';
import { z } from 'zod';
import {
  HUBSPOT_ACTIONS,
  HUBSPOT_PARAMS,
  EMAIL_TYPE,
  HUBSPOT_TYPE,
} from '@axiom/const';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';

export const NotificationsSchema = z.object({
  id: z.string().uuid(),
  isRead: z.boolean(),
  userId: z.string().uuid(),
  type: z.string().max(255),
  emails: z.string(),
  subject: z.string().nullable(),
  body: z.string().nullable(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const notificationCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    subject: { type: ['string', 'null'], minLength: 1 },
    body: { type: ['string', 'null'] },
    isRead: { type: 'boolean' },
    type: { type: 'string' },
    action: { type: ['string', 'null'] },
    emails: {
      type: 'array',
      items: {
        type: 'string',
        minLength: 1,
      },
    },
    from: { type: ['string', 'null'] },
    cc: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    bcc: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    userId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    [HUBSPOT_PARAMS]: {
      type: 'object',
      additionalProperties: false,
      properties: {
        displayName: { type: ['string', 'null'] },
        profilePhoto: { type: ['string', 'null'] },
        firstName: { type: ['string', 'null'] },
        lastName: { type: ['string', 'null'] },
        position: { type: ['string', 'null'] },
        profile_summary: { type: ['string', 'null'] },
        client: { type: ['string', 'null'] },
        updateNotificationPreferences: { type: ['string', 'null'] },
        viewBio: { type: ['string', 'null'] },
        viewBench: { type: ['string', 'null'] },
        requestRate: { type: ['string', 'null'] },
        hours: { type: ['number', 'null'] },
        requestInfo: { type: ['string', 'null'] },
        formattedSendDate: { type: ['string', 'null'] },
        largeTitleText: { type: ['string', 'null'] },
        messageBody: { type: ['string', 'null'] },
        ctaText: { type: ['string', 'null'] },
        ctaLink: { type: ['string', 'null'] },
        opportunityName: { type: ['string', 'null'] },
        opportunityId: { type: ['string', 'null'] },
        createdAt: { type: ['string', 'null'] },
        opportunityLink: { type: ['string', 'null'] },
        talentName: { type: ['string', 'null'] },
        talentLink: { type: ['string', 'null'] },
        talentFirstName: { type: ['string', 'null'] },
        viewOpportunityFeedLink: { type: ['string', 'null'] },
        manageAlertsUrl: { type: ['string', 'null'] },
        notificationFrequency: { type: ['string', 'null'] },
        companyName: { type: ['string', 'null'] },
        lossCode: { type: ['string', 'null'] },
        oppCards: { type: ['string', 'null'] },
        experienceReqs: { type: ['string', 'null'] },
        link: { type: ['string', 'null'] },
        manager: { type: ['string', 'null'] },
        managerEmail: { type: ['string', 'null'] },
        digitalLink: { type: ['string', 'null'] },
        clientName: { type: ['string', 'null'] },
        reason: { type: ['string', 'null'] },
        activityDate: { type: ['string', 'null'] },
        salesforceId: { type: ['string', 'null'] },
        message: { type: ['string', 'null'] },
        name: { type: ['string', 'null'] },
        projectName: { type: ['string', 'null'] },
        projectUrl: { type: ['string', 'null'] },
        requestType: { type: ['string', 'null'] },
        signInLink: { type: ['string', 'null'] },
        passReason: { type: ['string', 'null'] },
        clientFeedback: { type: ['string', 'null'] },
        talentFullName: { type: ['string', 'null'] },
        imageUrl: { type: ['string', 'null'] },
        talentRole: { type: ['string', 'null'] },
        talentExperience: { type: ['string', 'null'] },
        talentRate: { type: ['string', 'null'] },
        talentEducation: { type: ['string', 'null'] },
        talentAdmissions: { type: ['string', 'null'] },
        viewProfileLink: { type: ['string', 'null'] },
        talentNote: { type: ['string', 'null'] },
        talentDescription: { type: ['string', 'null'] },
        oppName: { type: ['string', 'null'] },
      },
    },
  },
};

const createNotificationValidation = {
  ...notificationCommonValidation,
  // TODO need to move custom fields to a new sub object
  required: ['type'],
  if: {
    properties: {
      type: {
        enum: [EMAIL_TYPE, HUBSPOT_TYPE],
      },
    },
  },
  then: {
    required: ['emails'],
    if: {
      required: ['action'],
      properties: {
        action: {
          enum: [HUBSPOT_ACTIONS.newUserSetPassword],
        },
      },
    },
    then: {
      required: ['userId'],
    },
  },
  else: {
    required: ['userId'],
  },
};

export const createNotificationValidator = ajv.compile(
  createNotificationValidation
);

export const createNotificationArrayValidator = arrayValidatorCreator(
  createNotificationValidation
);

export const updateNotificationValidator = ajv.compile({
  ...notificationCommonValidation,
  anyOf: Object.keys(notificationCommonValidation.properties).map(key => ({
    required: [key],
  })),
});
