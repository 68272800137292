/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, bool, func } from 'prop-types';
import { Droppable as DNDDroppable } from 'react-beautiful-dnd';

export const Droppable = ({
  render,
  id,
  isDropDisabled,
  className,
  ...props
}) => {
  return (
    <DNDDroppable droppableId={id} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          className={className}
          ref={provided.innerRef}
        >
          {React.createElement(render, {
            ...props,
            placeholder: provided.placeholder,
            isDraggingOver: snapshot.isDraggingOver,
            provided,
            snapshot,
          })}
        </div>
      )}
    </DNDDroppable>
  );
};

Droppable.defaultProps = {
  isDropDisabled: false,
  className: null,
};
Droppable.propTypes = {
  render: func.isRequired,
  isDropDisabled: bool,
  className: string,
  id: string.isRequired,
};
