import { createSelector } from 'reselect';
import fMap from 'lodash/fp/map';

import { sortByLastName, sortByName } from '../../utils/sort';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { PreloadedGroupsStore } from '../../stores/preloaded-groups-store';

export const groupsLastUpdatedSelector = state => state.groups.lastUpdated;

export const groupsSelector = createSelector(
  state => PreloadedGroupsStore.selectData(state),
  state => PreloadedUsersStore.selectData(state),
  (groups, users) =>
    groups.map(group => ({
      ...group,
      users: group.users
        ? group.users
            .map(userId => users.find(u => u.id === userId))
            .sort(sortByLastName)
        : [],
    }))
);

export const groupsByIdSelector = createSelector(
  state => PreloadedGroupsStore.select(state),
  state => PreloadedUsersStore.selectData(state),
  (groups, users) =>
    groups.reduce((t, group) => {
      t[group.id] = {
        ...group,
        users: group.users
          ? group.users
              .map(userId => users.find(user => user.id === userId))
              .sort(sortByLastName)
          : [],
      };
      return t;
    }, {})
);

export const groupsByUserIdSelector = createSelector(
  state => PreloadedGroupsStore.selectData(state),
  groups =>
    groups.reduce((groupsByUserId, { users, ...group }) => {
      if (users) {
        users.forEach(userId => {
          groupsByUserId[userId] = groupsByUserId[userId] || [];
          groupsByUserId[userId].push(group);
        });
      }
      return groupsByUserId;
    }, {})
);

export const groupMembersByUserIdSelector = createSelector(
  state => PreloadedGroupsStore.select(state),
  state => PreloadedUsersStore.selectData(state),
  (groups, users) =>
    users
      .map(user => user.id)
      .reduce((map, userId) => {
        map[userId] = groups
          .filter(group => group.users && group.users.includes(userId))
          .reduce((t, group) => [...t, ...group.users], [])
          .map(memberId => users.find(user => user.id === memberId))
          .sort(sortByLastName)
          .filter(
            (member, index, members) =>
              member.id !== userId &&
              (index === 0 || members[index - 1].id !== member.id)
          );
        return map;
      }, {})
);

export const sortedGroupsSelector = createSelector(groupsSelector, groups =>
  groups.sort(sortByName)
);

export const groupsDropdownOptionsSelector = createSelector(
  sortedGroupsSelector,
  fMap(group => ({
    label: `${group.name}`,
    value: `${group.id}`,
  }))
);
