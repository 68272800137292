import luceneQueryParser from 'lucene-query-parser';

function getTerm(term, quotedTerms) {
  /**
   * lucene strips out the double-quotes
   * this logic puts them back
   * */
  const textWithQuotes = `"${term}"`;
  return quotedTerms.has(textWithQuotes) ? textWithQuotes : term;
}

function recombinedSearch(node, quotedTerms = new Set()) {
  const terms = [];

  if (node.left) {
    const term = getTerm(node.left.term, quotedTerms);
    terms.push(term);
  }

  if (node?.operator !== '<implicit>') terms.push(node.operator);

  if (node.right) {
    if (node.right.term) {
      const term = getTerm(node.right.term, quotedTerms);
      terms.push(term);
    } else {
      return [...terms, ...recombinedSearch(node.right, quotedTerms)].join(' ');
    }
  }

  return terms.join(' ');
}

export const removeInvalidQuotes = query => {
  /*
   * removes the last odd quote from the query,
   * as well as any empty set of quotes
   * */
  const quoteCount = (query.match(/"/g) || []).length;

  if (quoteCount % 2 !== 0) {
    query = query.replace(/"([^"]*)$/, `$1`);
  }
  query = query.replaceAll('""', '');
  return query;
};

const getSanitizeRegex = query => {
  const quoteCount = (query.match(/"/g) || []).length;
  const escapeRegEx = new RegExp(
    `([-/[\\]{}^:!+()~\\\\${quoteCount % 2 === 0 ? '])|"(.{0})"' : '"])'}`,
    'g'
  );
  return escapeRegEx;
};

export const sanitizedElasticSearchQuery = query => {
  if (!query) return query;

  /**
   * lucene throws errors for special characters -,/[]{}^:+()~
   * and a single double-quote
   *
   * Checking for single double-quotes and
   * escaping all double-quotes if there are
   * not an even mount of them
   *
   * lucene also can't handle commas
   * stripping them out all together
   * you can't escape an un-escapable character
   * */
  query = removeInvalidQuotes(query);
  const escapeRegEx = getSanitizeRegex(query);
  query = query.replace(escapeRegEx, String.raw`\$1`).replaceAll(',', '');

  // keeping track of text is double-quotes, for use later
  const regex = /".*?"/g;
  const terms = query.match(regex);
  const quotedTerms = new Set(Array.isArray(terms) ? terms : undefined);

  const rootNode = luceneQueryParser.parse(query);

  return recombinedSearch(rootNode, quotedTerms);
};

// filters are either a single value or an array
export const countFilters = (filterObj = {}) => {
  return Object.entries(filterObj).reduce((sum, [, value]) => {
    sum += Array.isArray(value) ? value.length : 1;
    return sum;
  }, 0);
};

export const containsInvalidCharacters = (searchTerm = '') => {
  const invalidChars = /([-/[\]{}^:!+()~\\])/g;
  return invalidChars.test(searchTerm);
};
export const invalidCharactersErrorMessage = String.raw`Your search contains one or more of the following invalid characters ^ : / \ ( ) { } [ ] !, -. Please update your search and try again`;
