import { CookieUtil } from '../../utils/auth/cookie-util';

export const timeInSeconds = {
  oneSecond: 1,
  fiveSeconds: 5,
  oneMinute: 60,
  oneHour: 3600,
  twoHours: 7200,
};

export const determineDelay = (expSeconds: number): number => {
  switch (true) {
    case expSeconds <= 0: {
      return timeInSeconds.fiveSeconds;
    }

    case expSeconds < timeInSeconds.oneHour: {
      return timeInSeconds.oneSecond;
    }

    case expSeconds < timeInSeconds.twoHours: {
      return timeInSeconds.oneMinute;
    }

    default: {
      return timeInSeconds.oneHour;
    }
  }
};

export const convertSeconds = (input: number): string => {
  if (input < 0) return '00:00';

  const parseTimePart = (
    timePart: number,
    hideIfNull = false
  ): string | null => {
    if (hideIfNull && timePart === 0) return null;
    return String(timePart).padStart(2, '0');
  };

  const hours = parseTimePart(Math.floor(input / 3600), true);
  const minutes = parseTimePart(Math.floor((input % 3600) / 60));
  const seconds = parseTimePart(Math.floor(input % 60));
  return [hours, minutes, seconds].filter(t => !!t).join(':');
};

export const checkSessionExpiry = (): number => {
  const currentExp: number = CookieUtil.getUserExp();
  const currentTime = Date.now() / 1000;
  return currentExp - currentTime;
};
