import fGet from 'lodash/fp/get';
import { isEmpty, isEqual, omit, reduce } from 'lodash';
import { getFormValues, getFormInitialValues } from 'redux-form';
import { CandidatesConst } from '@axiom/const';
import { call, put, takeLatest, select, all } from 'redux-saga/effects';

import { sanitizedElasticSearchQuery } from '../../utils/search';
import { addApiError } from '../actions/app';
import { searchesSubmit } from '../actions/searches';
import { getDecoratedOpportunity } from '../actions/opportunities';
import { normalizedAvailabilityPreferences } from '../normalizers/talent';
import {
  getDecoratedTalent,
  talentLoaded,
  talentError,
  talentSaveError,
  talentSaveSuccess,
  talentOpportunitiesEditSuccess,
  talentOpportunitiesEditError,
  singleTalentLoaded,
} from '../actions/talent';
import {
  TALENT_OPPORTUNITIES_SAVE,
  TALENT_OPPORTUNITIES_EDIT,
  SINGLE_TALENT_LOAD,
  UPDATE_TALENT,
} from '../constants';
import { BENCH_FORM, TALENT_FORM } from '../../api/constants';
import { getTalents, getTalent, patchTalent } from '../../api/talent';
import { postCollaboratorsByTalentId } from '../../api/talentCollaborators';
import { postLanguagesByTalentId } from '../../api/talentLanguages';
import {
  postOpportunitiesByTalentId,
  updateOpportunityStatusByTalent,
} from '../../api/talentOpportunities';
import { postTagsByTalentId } from '../../api/talentTags';
import {
  TALENT_FIELD_AXIOM_EMPLOYEE_TYPE,
  TALENT_FIELD_COLLABORATORS,
  TALENT_FIELD_PROFILE_STATUS,
  TALENT_FIELD_ID,
  TALENT_FIELD_LANGUAGES,
  TALENT_FIELD_TAGS,
  TALENT_FIELD_HOME_PHONE,
  TALENT_FIELD_MOBILE_PHONE,
} from '../../models/constants/talentFields';
import { talentModel } from '../../models/talent';

const {
  ProfileStatuses: { Active, Beach, InDiligence, Waitlist },
} = CandidatesConst;

export function* talentSagaGenerator(searchId, shouldResetPageCount) {
  try {
    const currentSearch = yield searchId
      ? select(fGet(`searches.byId.${searchId}.search`))
      : select(getFormValues(TALENT_FORM));
    const search = { ...currentSearch };

    if (search.search) {
      search.search = sanitizedElasticSearchQuery(search.search);
    }

    const filters = { ...search.filters };
    const profileStatusFilters = filters[TALENT_FIELD_PROFILE_STATUS];
    if (!profileStatusFilters || profileStatusFilters.length === 0) {
      filters[TALENT_FIELD_PROFILE_STATUS] = [
        Active,
        Beach,
        InDiligence,
        Waitlist,
      ];
    }
    const axiomEmployeeTypeFilters = filters[TALENT_FIELD_AXIOM_EMPLOYEE_TYPE];
    if (!axiomEmployeeTypeFilters || axiomEmployeeTypeFilters.length === 0) {
      filters[TALENT_FIELD_AXIOM_EMPLOYEE_TYPE] = ['Insourcing'];
    }
    search.filters = filters;

    const currentPage = yield select(fGet(`talent.currentPage`));
    search.page = shouldResetPageCount ? 1 : currentPage + 1;

    delete search.type;

    const talent = yield call(getTalents, search);
    yield put(talentLoaded(talent));
  } catch (e) {
    yield put(talentError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateTalentGenerator(form) {
  // eslint-disable-next-line no-useless-catch
  try {
    let initialValues = yield select(getFormInitialValues(form));
    initialValues = initialValues || {};
    const values = yield select(getFormValues(form));

    const safeValues = reduce(
      values,
      (result, rawValue, key) => {
        let value = rawValue;
        // If we deleted a previously filled value in the form, the value
        // is now an empty string. Convert it to a null here before sending
        // to the API to avoid validation errors.
        if (typeof rawValue === 'string') {
          value = rawValue.trim().length > 0 ? rawValue : null;
        }

        if (
          talentModel[key] &&
          value !== 'MISSING DATA' &&
          !isEqual(value, initialValues[key])
        ) {
          result[key] = value;
        }

        return result;
      },
      {}
    );

    const availabilityPreferences = normalizedAvailabilityPreferences(values);
    Object.entries(availabilityPreferences).forEach(([k, v]) => {
      safeValues[k] = v;
    });

    const safeValuesWithoutRelatedEntityFields = omit(safeValues, [
      TALENT_FIELD_COLLABORATORS,
      TALENT_FIELD_TAGS,
      TALENT_FIELD_LANGUAGES,
      TALENT_FIELD_ID,
      TALENT_FIELD_HOME_PHONE,
      TALENT_FIELD_MOBILE_PHONE,
    ]);

    const collaborators = safeValues[TALENT_FIELD_COLLABORATORS];
    const tags = safeValues[TALENT_FIELD_TAGS];
    const languageObjects = safeValues[TALENT_FIELD_LANGUAGES];
    const { barredLocations } = safeValues;
    const shouldPatch = !isEmpty(safeValuesWithoutRelatedEntityFields);

    const calls = [
      // Only patch if we have something to actually patch
      ...(shouldPatch
        ? [call(patchTalent, values.id, safeValuesWithoutRelatedEntityFields)]
        : []),
      // let's only include the call to post to collaborators if there are any.
      ...(collaborators
        ? [call(postCollaboratorsByTalentId, values.id, collaborators)]
        : []),
      ...(tags ? [call(postTagsByTalentId, values.id, tags)] : []),
      ...(languageObjects
        ? [
            call(
              postLanguagesByTalentId,
              values.id,
              languageObjects.map(obj => ({ ...obj, languageId: obj.id }))
            ),
          ]
        : []),
    ];

    const [patchPayload] = yield all(calls);

    if (shouldPatch) {
      yield put(talentSaveSuccess(patchPayload.data));
    }
    if (collaborators || tags || languageObjects || barredLocations) {
      yield put(getDecoratedTalent(values.id));
    }

    // If we're currently on the Bench view, we want to reissue the
    // API query to get an updated list of talent to show there, since
    // the edit we just made may change that list. We check this by
    // seeing if the bench filter form is currently mounted.
    const benchFormState = yield select(fGet(`form.${BENCH_FORM}`));
    if (benchFormState && benchFormState.values) {
      yield put(searchesSubmit({ form: BENCH_FORM }));
    }
  } catch (e) {
    throw e;
  }
}

export function* updateTalent(action) {
  const patchPayload = yield call(
    patchTalent,
    action.payload.candidateId,
    action.payload.props
  );

  yield put(singleTalentLoaded(patchPayload));
}

export function* talentAddOpportunities(action) {
  try {
    yield call(postOpportunitiesByTalentId, {
      candidateId: action.payload.id,
      opportunityIds: action.payload.selectedIds,
    });
    const activeRecord = yield select(fGet('app.activeRecordId'));
    if (action.payload.selectedIds.includes(activeRecord)) {
      yield put(getDecoratedOpportunity(activeRecord));
    }
    yield put(getDecoratedTalent(action.payload.id));
  } catch (e) {
    yield put(talentSaveError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateOpportunityByTalentSaga(action) {
  try {
    const result = yield call(updateOpportunityStatusByTalent, action.payload);
    yield put(talentOpportunitiesEditSuccess(result));
    yield put(getDecoratedTalent(action.payload.candidateID));
  } catch (e) {
    yield put(talentOpportunitiesEditError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* getSingleTalentSaga(action) {
  try {
    const talent = yield call(getTalent, action.payload);
    yield put(singleTalentLoaded(talent));
  } catch (e) {
    yield put(talentError(e));
    yield put(addApiError(e.applicationError));
  }
}

function* talentSaga() {
  yield takeLatest(TALENT_OPPORTUNITIES_SAVE, talentAddOpportunities);
  yield takeLatest(TALENT_OPPORTUNITIES_EDIT, updateOpportunityByTalentSaga);
  yield takeLatest(SINGLE_TALENT_LOAD, getSingleTalentSaga);
  yield takeLatest(UPDATE_TALENT, updateTalent);
}

export default talentSaga;
