const EngagementLengths = {
  ZeroToThree: '0-3',
  FourToSix: '4-6',
  SevenToNine: '7-9',
  TenToTwelve: '10-12',
  TwelvePlus: '12+',
} as const;

export const EngagementLengthConst = {
  EngagementLengths,
};
