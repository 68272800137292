import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const ExpressInterestSchema = z
  .object({
    opportunityId: z.string().uuid(),
    roleFitDescription: z.string().nullable().optional(),
    openToLowerEsc: z.boolean().default(false),
    openToLowerEscCompensationPercentage: z.number().optional(),
    hasNoConflicts: z.boolean().optional(),
  })
  .superRefine((schema, ctx) => {
    if (!schema.roleFitDescription) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['roleFitDescription'],
        message: 'Required',
      });
    }

    if (schema.roleFitDescription && schema.roleFitDescription.length > 1000) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: 1000,
        type: 'string',
        inclusive: true,
        path: ['roleFitDescription'],
        message: 'Maximum character limit reached: 1000',
      });
    }

    if (!schema.hasNoConflicts) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['hasNoConflicts'],
        message: 'Required',
      });
    }

    if (schema.openToLowerEsc && !schema.openToLowerEscCompensationPercentage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['openToLowerEscCompensationPercentage'],
        message: 'Selection required if willing to discuss compensation.',
      });
    }

    return ctx;
  });

const ajv = new Ajv(axiomValidationOptions());

const expressInterestValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    roleFitDescription: { type: 'string' },
    hasNoConflicts: { type: 'boolean', enum: [true] },
  },
  required: ['roleFitDescription', 'hasNoConflicts'],
};

export const expressInterestValidator = ajv.compile(expressInterestValidation);
