import {
  Button,
  CondensedHeader,
  ContextMenuItem,
  DataGrid,
  AsyncConnector,
  DataGridHeader,
  Layout,
  LayoutItem,
  useApi,
} from '@axiom/ui';
import React, { useMemo, useState } from 'react';
import { Opportunity, User } from '@axiom/validation';
import { useNavigate, Link } from 'react-router-dom';

import { UsersApi } from '../../api/users';
import {
  UserOpportunityApi,
  WorkspaceStages,
  WorkspaceTypes,
  readUserOpportunityResponseType,
} from '../../api/user-opportunities-api';
import { UserFullNameUtil } from '../../utils/user-full-name-util';
import { OPPORTUNITY_STAGE_OPTIONS } from '../../utils/opportunity-utils';

import { WorkspaceViewTimeToSubmission } from './WorkspaceViewTimeToSubmission';

export const baseWorkspaceNoRowMessageCopy =
  'Opportunities that are assigned to you will appear here';
export const filteredWorkspaceNoRowMessageCopy =
  'Not seeing any opportunities? Try updating or clearing your filters.';

type WorkspaceOpportunity = Opportunity & {
  positionsLength: number;
  displayedPositionId: string;
  displayedPositionJobDescription: string;
};

type WorkspaceFilters = {
  type: string;
  stage: string;
};

export const WorkspaceViewGrid = ({ user }: { user: User }) => {
  const [{ data: users }] = useApi(UsersApi.readUsers());
  const [resultCount, setResultCount] = useState<number>(0);
  const [filters, setFilters] = useState<WorkspaceFilters>({
    type: WorkspaceTypes.accountManagerOwnerOrSalesLead,
    stage: WorkspaceStages.needsFulfilment,
  });
  const navigate = useNavigate();

  const mapFilterStageType = (stageCodeArr: string): string[] => {
    if (!stageCodeArr) {
      return ['0', '1', '2', '3'];
    }
    switch (true) {
      case stageCodeArr.includes('Needs Fulfilment (Stage 0b - 2)'): {
        return ['0', '1', '2'];
      }
      case stageCodeArr.includes('Fulfilled (Stage 3)'): {
        return ['3'];
      }
      default: {
        return ['0', '1', '2', '3'];
      }
    }
  };

  const mapFulfillmentActive = (stageCodeArr: string[]) => {
    switch (true) {
      case stageCodeArr.includes('0') &&
        stageCodeArr.includes('1') &&
        stageCodeArr.includes('2') &&
        stageCodeArr.includes('3'): {
        return null;
      }
      case stageCodeArr.includes('3'): {
        return false;
      }
      default: {
        return true;
      }
    }
  };

  const mapSortFields = (sortObject: unknown) => {
    if (Object.values(sortObject)[0] === null) {
      return 'stageCode';
    }
    const sortField = Object.keys(sortObject)[0];
    switch (sortField) {
      case 'tsLeadName': {
        return 'ownerUser';
      }
      case 'sellerName': {
        return 'salesLead';
      }
      case 'positionsLength': {
        return 'positions';
      }
      default: {
        return sortField || 'stageCode';
      }
    }
  };
  const GridConnector = useMemo(
    () =>
      new AsyncConnector({
        getData: args => {
          return UserOpportunityApi.readUserOpportunities(user.id, args.page, {
            ...filters,
            isFulfillmentActive: mapFulfillmentActive(
              mapFilterStageType(filters.stage)
            ),
            stage: mapFilterStageType(filters.stage),
            sort: mapSortFields(args.sort),
            order: Object.values(args.sort)[0] || undefined,
          });
        },
        parseData: (results: readUserOpportunityResponseType) => {
          setResultCount(results?.meta.resultCount);
          const modifiedResults: WorkspaceOpportunity[] = [];
          results.data.forEach(opp => {
            const workspaceOpportunity: WorkspaceOpportunity = {
              ...opp,
              positionsLength: opp.positions.length,
              displayedPositionId: opp.positions[0]?.id,
              displayedPositionJobDescription: opp.positions[0]?.description,
            };
            modifiedResults.push(workspaceOpportunity);
          });
          return {
            currentPage: results?.meta.currentPage,
            totalPages: results?.meta.pageCount,
            data: modifiedResults,
          };
        },
      }),
    [user, filters]
  );

  return (
    <Layout direction="vertical" stretched>
      <LayoutItem topGutter="24px" bottomGutter="8px">
        <CondensedHeader name="OPP_COUNT">
          {resultCount} {resultCount === 1 ? 'Opportunity' : 'Opportunities'}
        </CondensedHeader>
      </LayoutItem>
      <LayoutItem stretched>
        <DataGrid
          name="WORKSPACE_GRID"
          displayMode="infinite"
          dataSource={GridConnector}
          noResultsMessage={
            filters.type === WorkspaceTypes.accountManagerOwnerOrSalesLead &&
            filters.stage === WorkspaceStages.needsFulfilment
              ? baseWorkspaceNoRowMessageCopy
              : filteredWorkspaceNoRowMessageCopy
          }
        >
          <DataGridHeader
            name="timeToSubmissionHours"
            displayName="TTS"
            sortingOrder={['asc', 'desc', null]}
            width={136}
            cellRender={({ data }: { data: Opportunity }) => {
              if (Number(data.stageCode) > 0) {
                return <WorkspaceViewTimeToSubmission opportunity={data} />;
              }
              return '';
            }}
          />
          <DataGridHeader
            name="jobName"
            displayName="Opportunity"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              const oppData = data as Opportunity;
              return (
                <Link to={`/opportunity-detail/${oppData.id}`}>
                  {oppData.jobName}
                </Link>
              );
            }}
          />
          <DataGridHeader
            name="stageCode"
            displayName="Stage"
            width={150}
            sortingOrder={['asc', 'desc', null]}
            FiltersRender={() => (
              <>
                {Object.values(WorkspaceStages).map(choice => (
                  <ContextMenuItem
                    name={`${choice
                      .replace(' ', '_')
                      .toUpperCase()}_FILTER_OPTION`}
                    key={choice}
                    onClick={() => {
                      const changedData = { ...filters, stage: choice };
                      setFilters(changedData);
                    }}
                  >
                    {choice}
                  </ContextMenuItem>
                ))}
              </>
            )}
            cellRender={({ data }) => {
              const { stageCode } = data as WorkspaceOpportunity;
              return OPPORTUNITY_STAGE_OPTIONS[+stageCode];
            }}
          />
          <DataGridHeader
            name="FilterByOwner"
            displayName="Filter by owner"
            FiltersRender={() => (
              <>
                {Object.values(WorkspaceTypes).map(type => (
                  <ContextMenuItem
                    name={`${type
                      .replace(' ', '_')
                      .toUpperCase()}_FILTER_OPTION`}
                    key={type}
                    onClick={() => {
                      const changedData = { ...filters, type };
                      setFilters(changedData);
                    }}
                  >
                    {type}
                  </ContextMenuItem>
                ))}
              </>
            )}
            cellRender={() => {
              return filters.type;
            }}
          />
          <DataGridHeader
            name="accountName"
            displayName="Account"
            sortingOrder={['asc', 'desc', null]}
          />
          <DataGridHeader
            name="positionsLength"
            displayName="Positions"
            width={112}
            sortingOrder={['asc', 'desc', null]}
          />
          <DataGridHeader name="nextStep" displayName="Next steps" />
          <DataGridHeader
            name="tsLeadName"
            displayName="TS lead"
            width={144}
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              const { ownerUserId } = data as WorkspaceOpportunity;
              return UserFullNameUtil(ownerUserId, users);
            }}
          />
          <DataGridHeader
            name="sellerName"
            displayName="Seller"
            width={144}
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              const { salesLeadId } = data as WorkspaceOpportunity;
              return UserFullNameUtil(salesLeadId, users);
            }}
          />
          <DataGridHeader
            name="displayedPositionJobDescription"
            displayName="Job description"
            width={250}
            cellRender={({ data }) => {
              const { displayedPositionJobDescription } =
                data as WorkspaceOpportunity;
              return displayedPositionJobDescription;
            }}
          />

          <DataGridHeader
            name="contextMenu"
            displayName=""
            pinned="right"
            lockPinned
            width={84}
            cellRender={({ data }) => {
              const { id, displayedPositionId } = data as WorkspaceOpportunity;
              return (
                <Button
                  name="OPEN_JOB_DESCRIPTION_BUTTON"
                  pattern="secondary"
                  variation="minimal"
                  onClick={() => {
                    navigate(
                      `/opportunity-detail/${id}?showMoreEnabled=${displayedPositionId}`
                    );
                  }}
                >
                  Open
                </Button>
              );
            }}
          />
        </DataGrid>
      </LayoutItem>
    </Layout>
  );
};
