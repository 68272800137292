import { Api } from '@axiom/ui';
import { TopTask } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

export type readTopTaskResponseType = {
  data: Array<TopTask>;
};

class TopTasksApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readTopTasks() {
    return super.read<readTopTaskResponseType>({
      endpoint: `/topTasks`,
      method: 'GET',
    });
  }

  refreshTopTasks() {
    return super.invalidate(`/topTasks`);
  }
}

export const TopTasksApi = new TopTasksApiClass();
