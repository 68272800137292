import { UserConst } from '@axiom/const';

export const isLpUser = user =>
  user?.roles && user.roles.includes(UserConst.Roles.LPUser);
export const isSalesUser = user =>
  user?.roles && user.roles.includes(UserConst.Roles.EnvoySales);
export const isEnvoyAdmin = user =>
  user?.roles && user.roles.includes(UserConst.Roles.EnvoyAdmin);
export const isEnvoyUser = user =>
  user?.roles && user.roles.includes(UserConst.Roles.EnvoyUser);
export const isClientUser = user =>
  user?.roles && user.roles.includes(UserConst.Roles.ClientUser);
export const isBoomiUser = user =>
  user?.roles && user.roles.includes(UserConst.Roles.BoomiSync);
export const isAFCUser = user => isEnvoyAdmin(user) || isClientUser(user);
