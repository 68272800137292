import { Layout, LayoutItem, Paragraph, SmallHeader } from '@axiom/ui';
import React from 'react';

import SuccessPersonGraphic from '../../public/svg/success-party-person.svg';

export const TopTasksNoResults = () => {
  return (
    <Layout name="NO_TOP_TASKS" direction="vertical" verticalGutter="16px">
      <LayoutItem position="center">
        <SuccessPersonGraphic width="100%" height="auto" />
      </LayoutItem>
      <LayoutItem>
        <SmallHeader name="noTopTasksHeader">You're all caught up!</SmallHeader>
      </LayoutItem>
      <LayoutItem>
        <Paragraph name="noTopTasksParagraph">
          Nice work! There are no tasks for you to action in this category at
          the moment.
        </Paragraph>
      </LayoutItem>
    </Layout>
  );
};
