type Prop = number | string;
export class CandidateYearsOfExperienceModel {
  prop?: Prop;

  _yearGroups = {
    ZERO: '0 - 3',
    FOUR: '4 - 7',
    EIGHT: '8 - 11',
    TWELVE: '12 - 15',
    SIXTEEN: '16 - 19',
    TWENTY: '20+',
  };

  constructor(prop?: Prop) {
    this.prop = prop;
  }

  _getYearsOfExperienceFilterObject(
    yearsExperienceValue?: (typeof this._yearGroups)[keyof typeof this._yearGroups]
  ) {
    const {
      _yearGroups: { FOUR },
    } = this;

    switch (yearsExperienceValue) {
      case FOUR: {
        return {
          start: 4,
          end: 7,
        };
      }
      case this._yearGroups.EIGHT: {
        return {
          start: 8,
          end: 11,
        };
      }
      case this._yearGroups.TWELVE: {
        return {
          start: 12,
          end: 15,
        };
      }
      case this._yearGroups.SIXTEEN: {
        return {
          start: 16,
          end: 19,
        };
      }
      case this._yearGroups.TWENTY: {
        return {
          start: 20,
        };
      }
      default: {
        return {
          start: 0,
          end: 3,
        };
      }
    }
  }

  formatYearsOfExperience() {
    const stringified = this.prop === undefined ? '' : String(this.prop);
    switch (stringified) {
      case 'null': {
        return '';
      }
      case '0': {
        return '< 1';
      }
      default: {
        return stringified;
      }
    }
  }

  getYearsOfExperienceRange() {
    if (this.prop === null || this.prop === undefined) {
      return '--';
    }

    const yoe =
      typeof this.prop === 'number' ? this.prop : Number.parseFloat(this.prop);
    let yoeText = null;

    if (yoe >= 20) {
      yoeText = this._yearGroups.TWENTY;
    } else if (yoe >= 16) {
      yoeText = this._yearGroups.SIXTEEN;
    } else if (yoe >= 12) {
      yoeText = this._yearGroups.TWELVE;
    } else if (yoe >= 8) {
      yoeText = this._yearGroups.EIGHT;
    } else if (yoe >= 4) {
      yoeText = this._yearGroups.FOUR;
    } else {
      yoeText = this._yearGroups.ZERO;
    }

    return yoeText;
  }

  getYearsOfExperienceGroupObject() {
    const yoe =
      typeof this.prop === 'number' ? this.prop : Number.parseFloat(this.prop);
    const { _getYearsOfExperienceFilterObject } = this;

    let yoeValue = null;

    if (yoe >= 20) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.TWENTY
      );
    } else if (yoe >= 16) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.SIXTEEN
      );
    } else if (yoe >= 12) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.TWELVE
      );
    } else if (yoe >= 8) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.EIGHT
      );
    } else if (yoe >= 4) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.FOUR
      );
    } else if (yoe >= 0) {
      yoeValue = _getYearsOfExperienceFilterObject.call(
        this,
        this._yearGroups.ZERO
      );
    }

    return yoeValue;
  }

  formatYearsOfExperienceGroupToString(yoeGroup: {
    start?: string;
    end?: string;
  }) {
    if (yoeGroup?.start !== undefined) {
      return yoeGroup.end
        ? `${yoeGroup.start} - ${yoeGroup.end}`
        : `${yoeGroup.start}+`;
    }
    return '';
  }

  formatYearsOfExperienceStringToGroup(yoeString: '--' | string): {
    start: string | number;
    end?: string | number;
  } {
    if (yoeString === '--') return undefined;

    const [start, end] = yoeString.split('-');

    const data: {
      start: string | number;
      end?: string | number;
    } = {
      start: start.trim(),
    };

    if (start.includes('+')) data.start = Number.parseInt(start, 10).toString();
    if (end) data.end = end.trim();

    return data;
  }
}
