import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Gutter,
  Header3,
  Header4,
  IconButton,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Text,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { AppFindContactForAccountModalStore } from '../../stores/app-find-contact-for-account-modal-store';
import { AccountStore } from '../../stores/account-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import SearchInput from '../SearchInput/SearchInput';
import { formatDataTestId } from '../../utils/dataTest';
import { DateUtil } from '../../utils/date-util';
import {
  generateBenchUrl,
  generateAFCProjectsUrl,
} from '../../utils/link-util';
import { isEnvoyAdmin } from '../../utils/roles';
import { ManageAccountsConfidential } from '../ManageAccountsConfidential/ManageAccountsConfidential';

import {
  ManageAccountsFormWrapper,
  ManageAccountsAccountDetails,
  ManageAccountsAssociatedUsersSearchRow,
  ManageAccountsColumn,
  ManageAccountsContactName,
  ManageAccountsEmail,
  ManageAccountsEmailColumn,
  ManageAccountsEmpty,
  ManageAccountsFormHeader,
  ManageAccountsRow,
} from './ManageAccountsFormStyles';

const ManageAccountsFormComponent = ({ accountState, user }) => {
  const [confirmContactId, setConfirmContactId] = useState(null);
  const [query, setQuery] = useState('');

  const handleQueryChange = e => setQuery(e.target.value);

  const queryRegExp = new RegExp(query, 'i');

  const filterContacts = (contacts, q) =>
    q
      ? contacts.filter(({ firstName, lastName }) =>
          queryRegExp.test(`${firstName} ${lastName}`)
        )
      : contacts;

  useEffect(() => {
    setQuery('');
  }, [accountState]);

  return (
    <StoreStateTreatment
      storeState={accountState}
      name="MANAGE_ACCOUNT_FORM"
      renderLoadedView={({ data }) => {
        const {
          contacts,
          name,
          calculatedName,
          parentAccount,
          id: accountId,
        } = data;

        return (
          <ManageAccountsFormWrapper>
            <ManageAccountsFormHeader>
              <Header3 name="CLIENT_NAME">{name}</Header3>

              <ContextMenu
                name="OPPORTUNITY_CONTEXT_MENU_MANAGE_ACCOUNT"
                direction="left"
              >
                <ContextMenuItem
                  onClick={() => {
                    window.open(generateAFCProjectsUrl(accountId));
                  }}
                >
                  Open AFC Projects
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => {
                    window.open(generateBenchUrl(accountId));
                  }}
                >
                  Open AFC Bench
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => {
                    AppFindContactForAccountModalStore.beginAddingToAccount(
                      [accountId],
                      accountId
                    );
                  }}
                >
                  Add associated user
                </ContextMenuItem>
              </ContextMenu>

              <ManageAccountsAccountDetails>
                <div>
                  <dt>Account Display Name</dt>
                  <dd data-test={formatDataTestId('ACCOUNT_DISPLAY_NAME')}>
                    {calculatedName || 'None'}
                  </dd>
                </div>
                <div>
                  <dt>Parent Account</dt>
                  <dd data-test={formatDataTestId('PARENT_ACCOUNT_NAME')}>
                    {parentAccount?.calculatedName || 'None'}
                  </dd>
                </div>
              </ManageAccountsAccountDetails>
            </ManageAccountsFormHeader>
            {isEnvoyAdmin(user) && (
              <ManageAccountsConfidential account={data} />
            )}
            <ManageAccountsAssociatedUsersSearchRow divider>
              <ManageAccountsColumn>
                <Gutter vertical="2rem">
                  <Header4>Associated Users</Header4>
                </Gutter>
              </ManageAccountsColumn>
              <ManageAccountsColumn>
                <SearchInput
                  data-test={formatDataTestId('ACCOUNT_CONTACT_SEARCH')}
                  onChange={handleQueryChange}
                  placeholder="Search Users"
                  value={query}
                />
              </ManageAccountsColumn>
            </ManageAccountsAssociatedUsersSearchRow>
            {filterContacts(contacts, query).map(
              ({
                firstName,
                id: contactId,
                lastName,
                email,
                user: contactUser,
              }) => (
                <ManageAccountsRow
                  key={contactId}
                  data-test={formatDataTestId('ASSOCIATED_CONTACT')}
                  divider
                >
                  <ManageAccountsColumn>
                    <ManageAccountsContactName
                      data-test={formatDataTestId('CONTACT_NAME')}
                    >
                      {`${firstName} ${lastName}`}
                    </ManageAccountsContactName>
                    <Text size="small" className="font-style-italic">
                      Last Sign-In:{' '}
                      <span data-test={formatDataTestId('LAST_SIGN_IN')}>
                        {DateUtil.displayDate(contactUser?.lastLoginAt) ||
                          'Never'}
                      </span>
                    </Text>
                  </ManageAccountsColumn>
                  <ManageAccountsColumn>
                    <ManageAccountsEmailColumn>
                      <ManageAccountsEmail
                        data-test={formatDataTestId('EMAIL')}
                      >
                        {email}
                      </ManageAccountsEmail>
                      <IconButton
                        icon="trash"
                        pattern="secondary"
                        variation="minimal"
                        title={`Remove ${firstName} ${lastName}`}
                        onClick={() => setConfirmContactId(contactId)}
                      />
                    </ManageAccountsEmailColumn>
                  </ManageAccountsColumn>
                </ManageAccountsRow>
              )
            )}
            {confirmContactId && (
              <Modal size="confirm">
                <ModalHeader onClose={() => setConfirmContactId(null)}>
                  Remove Contact
                </ModalHeader>
                <ModalSection>
                  <Paragraph>
                    Are you sure you would like to remove this contact from this
                    account?
                  </Paragraph>
                </ModalSection>
                <ModalFooter>
                  <Button
                    variation="outline"
                    onClick={() => setConfirmContactId(null)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (confirmContactId) {
                        AccountStore.removeContactFromAccount(
                          confirmContactId,
                          accountId
                        );
                        setConfirmContactId(null);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </ManageAccountsFormWrapper>
        );
      }}
      renderNonLoadedView={() => {
        return (
          <ManageAccountsEmpty>
            <Text>Select a search result.</Text>
          </ManageAccountsEmpty>
        );
      }}
    />
  );
};

ManageAccountsFormComponent.propTypes = {
  accountState: AccountStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};

export const ManageAccountsForm = connect(
  state => ({
    accountState: AccountStore.select(state),
    user: PreloadedUserStore.select(state),
  }),
  {}
)(ManageAccountsFormComponent);
