import React from 'react';
import { connect } from 'react-redux';

import { window } from '../../utils/global';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';

const JiraInitializerComponent = ({ featureFlags }) => {
  if (!PreloadedFeatureFlagsStore.isJiraIssueCollectorsEnabled(featureFlags)) {
    return null;
  }

  const REPORT_ISSUE_ID = 'f0642b98';
  const SUGGEST_FEATURE_ID = '23255e4d';

  const setCollector = collectorId => {
    if (window.document) {
      const appElement = window.document.querySelector('body');
      const scriptElement = window.document.querySelector(
        `#JIRA_${collectorId}`
      );
      if (appElement && !scriptElement) {
        const snippet = window.document.createElement('script');
        snippet.type = 'text/javascript';
        snippet.src = `https://axiomlegal.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/8ur02a/b/23/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=${collectorId}`;
        snippet.id = `JIRA_${collectorId}`;
        appElement.append(snippet);
      }
    }
  };

  setCollector(REPORT_ISSUE_ID);
  setCollector(SUGGEST_FEATURE_ID);

  try {
    return (
      <script type="text/javascript">
        {(() => {
          window.ATL_JQ_PAGE_PROPS = {
            [SUGGEST_FEATURE_ID]: {
              triggerFunction: showCollectorDialog => {
                window
                  .jQuery(document)
                  .on('click', '[data-test="SUGGEST_FEATURE"]', e => {
                    e.preventDefault();
                    showCollectorDialog();
                  });
              },
            },
          };
        })()}
      </script>
    );
    // eslint-disable-next-line no-empty
  } catch {}

  return null;
};

JiraInitializerComponent.propTypes = {
  featureFlags: PreloadedFeatureFlagsStore.getDataShape().isRequired,
};

const mapStateToProps = state => ({
  featureFlags: PreloadedFeatureFlagsStore.select(state),
});

export default connect(mapStateToProps)(JiraInitializerComponent);
