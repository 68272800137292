// These constants are also used in the bullhorn sync.  Keep them in sy
export const PracticeAreaConst = {
  PRACTICE_AREA_TYPES: {
    practiceArea: 'practicearea',
    specialty: 'specialty',
  },
  PracticeAreas: {
    Unknown: 'Unknown',
  },
};
