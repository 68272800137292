import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Paragraph } from '../../content/Paragraph/Paragraph';

export const BannerTypes = ['info', 'critical', 'error'];
export const BannerImpacts = ['low', 'high'];

export const Banner = ({
  className,
  children,
  impact = 'low',
  name,
  type = 'info',
}: {
  children?: React.ReactNode;
  className?: string;
  impact?: (typeof BannerImpacts)[number];
  name?: string;
  type?: (typeof BannerTypes)[number];
}) => (
  <div
    className={AttrsHelper.formatClassname(
      'banner',
      `impact-${impact}`,
      `type-${type}`,
      className
    )}
    data-test={name}
  >
    <Paragraph>{children}</Paragraph>
  </div>
);
