import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../element/Button/Button';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type BriefProps = {
  className?: string;
  children: React.ReactNode;
  briefMaxLines?: number; // Safari Note: Do not use this if you're wrapping an RTE display component. Otherwise you're free to use this.
  briefMaxChildren?: number;
  briefMaxHeight?: string;
  expanded?: boolean;
  name?: string;
  pattern?: 'primary' | 'secondary';
  position?: 'left' | 'center' | 'right';
  onToggle?: (opened: boolean) => void;
};

export const Brief = ({
  className,
  children,
  briefMaxLines,
  briefMaxChildren,
  briefMaxHeight,
  expanded,
  name,
  pattern = 'primary',
  position = 'center',
  onToggle,
}: BriefProps) => {
  const containerRef = useRef(null);
  const innerRef = useRef(null);

  const doesContentExceedMax = () => {
    return (
      innerRef?.current?.clientHeight > containerRef?.current?.clientHeight ||
      containerRef?.current?.scrollHeight >
        containerRef?.current?.clientHeight ||
      React.Children.count(children) > briefMaxChildren
    );
  };

  // 'hasBeenUsed' fixes bug CR-3663
  const [expandState, setExpandState] = useState<{
    isExpanded: boolean;
    contentIsChopped: boolean;
    hasBeenUsed: boolean;
  }>({
    isExpanded: null,
    contentIsChopped: null,
    hasBeenUsed: null,
  });

  const { contentIsChopped, isExpanded, hasBeenUsed } = expandState;
  if (
    [
      briefMaxLines,
      Number.parseInt(briefMaxHeight, 10),
      briefMaxChildren,
    ].reduce((count: number, item) => {
      if (item) {
        count += 1;
      }

      return count;
    }, 0) > 1
  ) {
    throw new Error(
      'You can only pass in one prop: briefMaxLines, briefMaxHeight or briefMaxChildren'
    );
  }

  useEffect(() => {
    if (contentIsChopped === null) {
      const doesExceed = doesContentExceedMax();
      setExpandState({
        isExpanded: !!(expanded === true || !doesExceed),
        contentIsChopped: doesExceed,
        hasBeenUsed: false,
      });
    }
  }, [
    containerRef?.current?.clientHeight,
    innerRef?.current?.clientHeight,
    innerRef?.current?.scrollHeight,
  ]);

  useEffect(() => {
    setExpandState(prev => ({
      ...prev,
      contentIsChopped: doesContentExceedMax(),
      isExpanded: expanded === true || prev.isExpanded,
    }));
  }, [expanded]);

  useEffect(() => {
    if (
      hasBeenUsed &&
      !isExpanded &&
      containerRef?.current?.scrollIntoView &&
      contentIsChopped !== null
    ) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isExpanded]);

  const processedChildren =
    briefMaxChildren && isExpanded !== true
      ? React.Children.toArray(children).slice(0, briefMaxChildren)
      : React.Children.toArray(children);

  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname('brief', className)}
    >
      <div
        ref={containerRef}
        className={AttrsHelper.formatClassname(
          'outer-container',
          'brief-content',
          isExpanded && `expanded`,
          briefMaxLines && isExpanded !== true && `max-lines-${briefMaxLines}`
        )}
        style={{
          ...(briefMaxHeight && isExpanded !== true
            ? { maxHeight: briefMaxHeight, overflow: 'hidden' }
            : {}),
        }}
      >
        <div className="inner-container" ref={innerRef}>
          {processedChildren}
        </div>
      </div>
      {contentIsChopped && (
        <div className="brief-control">
          <div className={`brief-control-button position-${position}`}>
            <Button
              name="expand-button"
              pattern={pattern}
              variation="minimal"
              icon={isExpanded ? 'arrow-up' : 'arrow-down'}
              onClick={() => {
                onToggle?.(!isExpanded);
                setExpandState(prev => ({
                  ...prev,
                  isExpanded: !isExpanded,
                  hasBeenUsed: true,
                }));
              }}
            >
              Show {isExpanded ? 'Less' : 'More'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
