import Ajv from 'ajv';
import { z } from 'zod';
import { LanguageConst } from '@axiom/const';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';

const LanguageProficienciesValues = Object.values(
  LanguageConst.LANGUAGE_PROFICIENCIES
) as NonEmptyArray<string>;

const LanguageSkillsValues = Object.values(
  LanguageConst.LANGUAGE_SKILLS
) as NonEmptyArray<string>;

export const OpportunityLanguageSchema = z.object({
  id: z.string().uuid(),
  opportunityId: z.string().uuid(),
  languageId: z.string().uuid(),
  requiredLanguageProficiency: z.enum(LanguageProficienciesValues).nullable(),
  requiredLanguageSkill: z.enum(LanguageSkillsValues).nullable(),
});

const ajv = new Ajv(axiomValidationOptions());

const opportunityLanguageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    languageId: {
      type: 'string',
      format: 'uuid',
    },
    requiredLanguageSkill: {
      type: ['string', 'null'],
    },
    requiredLanguageProficiency: {
      type: 'string',
    },
    requiredLanguageCEFR: {
      type: ['string', 'null'],
      enum: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', null],
    },
  },
  required: ['requiredLanguageProficiency', 'languageId'],
};

export const opportunityLanguageValidator = ajv.compile(
  opportunityLanguageValidation
);

export const opportunityLanguageArrayValidator = arrayValidatorCreator(
  opportunityLanguageValidation
);
