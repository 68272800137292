import React from 'react';

import { Badge } from '../../element/Badge/Badge';
import { Gutter } from '../Gutter/Gutter';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { IconName } from '../../element/Icon/IconTypes';
import { Icon } from '../../element/Icon/Icon';

export type TabProps = {
  // We don't actually use children in here because it gets moved
  // by Tabs. So declaring here to leverage type checking, but not used
  // in this component itself
  children: React.ReactNode;
  className?: string;
  countBadge?: number;
  icon?: IconName;
  label: React.ReactNode;
  name: string;
  onClick?: () => void;
};

export const Tab = ({
  className,
  countBadge,
  icon,
  label,
  name,
  onClick,
}: TabProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname('tab', className)}
      data-test={name}
      onClick={onClick}
    >
      <div
        className={AttrsHelper.formatClassname(
          'tab-label',
          icon && 'with-icon'
        )}
      >
        <span className="tab-label-text">{label}</span>
        {(icon || countBadge > 0) && (
          <span className="tab-decorators">
            {icon && (
              <span className="tab-icon">
                <Icon name={icon} />
              </span>
            )}
            {countBadge > 0 && (
              <Gutter left="4px" as="span">
                <Badge background="blue" className="tab-count-badge">
                  {countBadge}
                </Badge>
              </Gutter>
            )}
          </span>
        )}
      </div>
    </div>
  );
};
