import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { ActivityIcon } from '@axiom/ui';
import { connect } from 'react-redux';

import NoContentPlaceholder from '../NoContentPlaceholder/NoContentPlaceholder';
import { withRouter } from '../../hocs/with-router';
import { LogContainer, LogItems } from '../../styled/logs';
import { formatDataTestId } from '../../utils/dataTest';
import {
  AppEventItemModalStore,
  eventPurposeOptions,
} from '../../stores/app-event-item-modal-store';
import { EventsStore, EventSubjectConst } from '../../stores/events-store';
import { fullNameByIdSelector } from '../../redux/selectors/users';
import { ActivityLogsSearchStore } from '../../stores/activity-logs-search-store';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { CandidateOpportunitiesStore } from '../../stores/candidate-opportunities-store';
import { WithRouterShape } from '../../models/router';
import { WindowUtil } from '../../utils/window-util';

import ActivityLogItem from './ActivityLogItem';
import { ActivityLogFilter } from './ActivityLogFilter';

const INITIAL_STATE = {
  filter: null,
  sort: -1,
  search: null, // or array of event ids that match the search term
};

class ActivityLog extends Component {
  constructor(config) {
    super(config);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    const { eventsData, usersById, positions, opportunities, router } =
      this.props;

    ActivityLogsSearchStore.load(
      eventsData?.events,
      usersById,
      opportunities,
      positions
    );

    const { view, viewId } = router.params;
    const { pathname } = window.location;

    if (
      pathname.includes('opportunity-detail') &&
      !pathname.includes('talent')
    ) {
      EventsStore.load(EventSubjectConst.Opportunity, router.params.id);
    }

    if (view !== 'activitylog' || !viewId || viewId.length !== 36) return;

    if (viewId && viewId.length > 0) {
      const foundEvent = eventsData.events.find(e => e.id === viewId);
      if (foundEvent) {
        AppEventItemModalStore.openModal(eventsData.eventSubject, foundEvent);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { eventsData } = this.props;
    if (prevProps?.eventsData?.events !== eventsData?.events) {
      const { usersById, positions, opportunities } = this.props;

      ActivityLogsSearchStore.load(
        eventsData?.events,
        usersById,
        opportunities,
        positions
      );
    }

    const {
      router: {
        params: { viewId },
      },
    } = this.props;

    if (viewId !== prevProps.router.params.viewId) {
      if (viewId) {
        if (viewId !== prevProps.router.params.viewId) {
          const foundEvent = eventsData?.events?.find(e => e.id === viewId);
          if (foundEvent) {
            AppEventItemModalStore.openModal(
              eventsData?.eventSubject,
              foundEvent
            );
          }
        }
      } else {
        AppEventItemModalStore.clearState();
      }
    }
  }

  componentWillUnmount() {
    ActivityLogsSearchStore.unload();
  }

  handleFreeSearch = term =>
    this.setState(
      { searchTerm: term },
      debounce(() => {
        const ids = ActivityLogsSearchStore.search(
          term,
          this.props.searchableEventData
        );

        this.setState({ search: ids });
      }, 200)
    );

  handleEditClick = event => {
    const {
      eventsData,
      router: { navigate },
    } = this.props;
    const viewId = event.id;

    AppEventItemModalStore.openModal(eventsData.eventSubject, event);
    navigate(this.constructActivityLogUrl(viewId));
  };

  handleFilterChange = value => {
    this.setState({ filter: value.activities });
    if (value.search) {
      this.handleFreeSearch(value.search);
    } else {
      this.handleFreeSearch('');
    }
  };

  constructActivityLogUrl(append) {
    const {
      router: { location },
    } = this.props;
    const currentPath = location.pathname?.trim('/') || '';
    return WindowUtil.appendQueryParams(
      `${
        currentPath.includes('/activitylog')
          ? currentPath.slice(
              0,
              Math.max(0, currentPath.indexOf('/activitylog'))
            )
          : currentPath.pathname
      }/activitylog${append ? `/${append}` : ''}`
    );
  }

  openNewActivity = () => {
    const {
      eventsData,
      router: { navigate },
    } = this.props;

    const idPropName =
      eventsData.eventSubject === EventSubjectConst.Candidate
        ? 'candidateId'
        : 'opportunityId';

    AppEventItemModalStore.openModal(eventsData.eventSubject, {
      [idPropName]: eventsData.eventSubjectId,
    });

    navigate(this.constructActivityLogUrl());
  };

  render() {
    const { filter, sort, search, searchTerm } = this.state;
    const { eventsData } = this.props;

    let events = [...eventsData.events];
    let filterResults = [];

    if (filter && filter.length > 0) {
      filter.forEach(activity => {
        const filteredArr = events.filter(event => event.purpose === activity);
        filterResults = [...filterResults, ...filteredArr];
      });
      events = filterResults;
    } else {
      events = [...eventsData.events];
    }
    if (search !== null) {
      events = events.filter(event => search.includes(event.id));
    }

    events = events.sort(
      (a, b) => (new Date(a.scheduledDate) - new Date(b.scheduledDate)) * sort
    );

    const showFilters =
      search !== null || filter !== null || events?.length > 0;

    return (
      <>
        <ActivityLogFilter
          eventPurposeOptions={eventPurposeOptions}
          onAddActivity={this.openNewActivity}
          onFilterChange={this.handleFilterChange}
          showFilters={showFilters}
        />

        <LogContainer data-test={formatDataTestId('ACTIVITY_LOG_BODY')}>
          {events.length > 0 ? (
            <LogItems>
              {events.map(event => (
                <ActivityLogItem
                  key={event.id}
                  event={event}
                  eventSubjectId={eventsData.eventSubjectId}
                  eventSubject={eventsData.eventSubject}
                  onEditClick={this.handleEditClick}
                />
              ))}
            </LogItems>
          ) : (
            <NoContentPlaceholder
              image={<ActivityIcon />}
              title="No Activities"
              text={
                search === null
                  ? 'Add an activity to the log.'
                  : `"${searchTerm}" returns no activities, please try again.`
              }
            />
          )}
        </LogContainer>
      </>
    );
  }
}

ActivityLog.defaultProps = {
  positions: [],
  opportunities: [],
};

ActivityLog.propTypes = {
  eventsData: EventsStore.getDataShape().isRequired,
  usersById: PropTypes.shape({ id: PropTypes.string }).isRequired,
  positions: OpportunityPositionsStore.getDataShape(),
  opportunities: CandidateOpportunitiesStore.getDataShape(),
  searchableEventData: ActivityLogsSearchStore.getDataShape().isRequired,
  router: PropTypes.shape(WithRouterShape).isRequired,
};

const mapStateToProps = state => ({
  usersById: fullNameByIdSelector(state),
  searchableEventData: ActivityLogsSearchStore.selectData(state),
});

export default connect(mapStateToProps)(withRouter(ActivityLog));
