import memoize from 'lodash/memoize';

const fn = data =>
  Array.isArray(data)
    ? data
        .filter(f => !(f === null || f === undefined))
        .map(a => {
          const value =
            a?.ordinal === undefined
              ? a?.value || a?.id || a
              : a.ordinal.toString();

          const label = a?.v || a?.label || a?.name || a;

          return { value, label };
        })
    : Object.keys(data)
        .filter(key => !(data[key] === null || data[key] === undefined))
        .map(key => ({
          value: key,
          label: data[key]?.v || data[key],
        }));

export const toDropdownOptions = memoize(fn);
