import keyBy from 'lodash/keyBy';

import {
  GROUPS_LOADED,
  GROUP_CREATED,
  GROUP_DELETED,
  GROUP_UPDATED,
  GROUP_USER_ADDED,
  GROUP_USER_REMOVED,
  SET_USER_GROUPS_SUCCESS,
} from '../constants';

export const INITIAL_STATE = {
  byId: {},
  lastUpdated: 0,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GROUPS_LOADED: {
      const groups = payload.data;
      return {
        ...state,
        byId: keyBy(groups, 'id'),
        lastUpdated: Date.now(),
      };
    }

    case GROUP_CREATED:
    case GROUP_UPDATED: {
      const group = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [group.id]: group,
        },
      };
    }

    case GROUP_DELETED: {
      const groupId = payload;
      const byId = { ...state.byId };
      delete byId[groupId];
      return { ...state, byId };
    }

    case GROUP_USER_ADDED: {
      const { groupId, userId } = payload;
      const group = state.byId[groupId];
      if (!group || group.users.includes(userId)) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [groupId]: {
            ...group,
            users: [...group.users, userId],
          },
        },
      };
    }

    case GROUP_USER_REMOVED: {
      const { groupId, userId } = payload;
      const group = state.byId[groupId];
      if (!group || !group.users.includes(userId)) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [groupId]: {
            ...group,
            users: group.users.filter(id => id !== userId),
          },
        },
      };
    }

    case SET_USER_GROUPS_SUCCESS: {
      const { userId, groups } = payload;
      const groupIds = groups.map;

      const byId = { ...state.byId };

      // Remove user from groups they no longer belong to:
      Object.keys(byId)
        .filter(groupId => !groupIds.includes(groupId))
        .forEach(groupId => {
          const group = byId[groupId];
          const index = group.users.indexOf(userId);
          if (index !== -1) {
            const updatedUsers = [...group.users];
            updatedUsers.splice(index, 1);
            byId[groupId] = {
              ...group,
              users: updatedUsers,
            };
          }
        });

      // Add user to new groups
      Object.keys(groups).forEach(groupId => {
        const group = byId[groupId];
        byId[groupId] = {
          ...group,
          ...groups[groupId],
          users: group.users.includes(userId)
            ? group.users
            : [...group.users, userId],
        };
      });

      return {
        ...state,
        byId,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
