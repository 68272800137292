import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const TeamSchema = z.object({
  name: z.string().max(255),
  id: z.string().uuid(),
});

const ajv = new Ajv(axiomValidationOptions());

const teamValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: { type: 'string' },
  },
  required: ['name'],
};

export const teamValidator = ajv.compile(teamValidation);
