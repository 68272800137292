import React, { Component } from 'react';
import { bool, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Button, IconButton, Layout, LayoutItem } from '@axiom/ui';
import { OpportunitiesConst, ContactsOpportunitiesConst } from '@axiom/const';

import OpportunityEditForm from '../OpportunityEditForm/OpportunityEditForm';
// eslint-disable-next-line import/no-named-as-default
import OpportunityProfileNextStepsDescription from '../OpportunityProfileNextStepsDescription/OpportunityProfileNextStepsDescription';
import UnorderedList from '../UnorderedList/UnorderedList';
import ManageAccountsContextMenu from '../ManageAccountContextMenu/ManageAccountContextMenu';
import OpportunityProfilePositions from '../OpportunityProfilePositions/OpportunityProfilePositions';
import { CLOSED_LOST, CLOSED_WON } from '../../api/constants';
import { opportunityShape } from '../../models/opportunities';
import { opportunityLanguagesTexts } from '../../utils/languages';
import { formatDataTestId } from '../../utils/dataTest';
import { PreloadedLanguagesStore } from '../../stores/preloaded-languages-store';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { FormOpportunityProfileDetailStore } from '../../stores/form-opportunity-profile-detail-store';
import { PreloadedFeatureFlagsStore } from '../../stores/preloaded-feature-flags-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { NumberUtil } from '../../utils/number-util';
import { OpportunityConfidential } from '../OpportunityConfidential/OpportunityConfidential';
import { OpportunityFeedVisibilityForm } from '../OpportunityFeedVisibilityForm/OpportunityFeedVisibilityForm';

import {
  OPDFColumn,
  OPDFRows,
  OPDFRow,
  OPDFFirstRow,
  OPDFFirstRowRight,
  OPDFHr,
  OPDFLanguages,
  DetailColumn,
  DetailContainer,
} from './OpportunityProfileDetailFormStyles';

class OpportunityProfileDetailForm extends Component {
  constructor(props) {
    super(props);

    const { isDetail } = this.props;
    this.state = {
      showLess: !isDetail,
    };
  }

  onEditOpportunityClick = () => {
    const { opportunity } = this.props;
    FormOpportunityProfileDetailStore.load(opportunity);
  };

  toggleShowLess = () => {
    this.setState(state => ({ showLess: !state.showLess }));
  };

  render() {
    const {
      featureFlags,
      isDetail,
      languages,
      opportunity,
      namesById,
      practiceAreas,
    } = this.props;
    const { showLess } = this.state;
    const showEdit = ![CLOSED_WON, CLOSED_LOST].includes(opportunity.stage);
    const Container = isDetail ? DetailContainer : 'div';
    const Column = isDetail ? DetailColumn : 'div';
    const { Roles } = ContactsOpportunitiesConst;

    return (
      <Container data-test={formatDataTestId('PROFILE_CONTAINER')}>
        <Column>
          <Layout
            borderBottom="1px solid contentBreak"
            position="center stretch"
          >
            <LayoutItem fluid bottomGutter="18px">
              <OpportunityFeedVisibilityForm opportunity={opportunity} />
            </LayoutItem>
          </Layout>
          <OPDFRows>
            <OPDFFirstRow>
              <OPDFColumn>
                <UnorderedList
                  showAftIcon
                  title="Primary Contact"
                  values={opportunity.clientName}
                />
              </OPDFColumn>

              <OPDFFirstRowRight>
                {PreloadedFeatureFlagsStore.isManageClientsEnabled(
                  featureFlags
                ) && <ManageAccountsContextMenu opportunity={opportunity} />}
                {showEdit && (
                  <IconButton
                    pattern="secondary"
                    variation="minimal"
                    icon="pencil"
                    name="EDIT_PROFILE_BUTTON"
                    onClick={this.onEditOpportunityClick}
                  />
                )}
                <OpportunityEditForm languages={languages} />
              </OPDFFirstRowRight>
            </OPDFFirstRow>
            <OPDFRow>
              <OPDFColumn>
                <UnorderedList
                  title="Other Contacts"
                  values={opportunity?.contactsOpportunities
                    ?.filter(co => !!co.id)
                    ?.map(item =>
                      [
                        item.contact.fullName,
                        item.role && item.role !== Roles.None
                          ? Roles[item.role]
                          : null,
                      ]
                        .filter(Boolean)
                        .join(', ')
                    )}
                />
              </OPDFColumn>
            </OPDFRow>
            <OPDFRow>
              <OPDFColumn isDetail={isDetail}>
                <UnorderedList
                  showAftIcon
                  title="TS Lead"
                  values={
                    namesById?.[opportunity.ownerUserId]?.fullName ?? null
                  }
                />
              </OPDFColumn>
              <OPDFColumn isDetail={isDetail}>
                <UnorderedList
                  showAftIcon
                  title="Opportunity Owner"
                  values={
                    namesById?.[opportunity.salesLeadId]?.fullName ?? null
                  }
                />
              </OPDFColumn>
            </OPDFRow>

            <OPDFRow>
              <OPDFColumn isDetail={isDetail}>
                <UnorderedList
                  title="Forecast Category"
                  values={opportunity.forecastCategory}
                />
              </OPDFColumn>
            </OPDFRow>

            {showLess === false && (
              <>
                <OPDFHr />

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      showAftIcon
                      title="Account Manager"
                      values={
                        namesById?.[opportunity.salesforceCxLeadId]?.fullName
                      }
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Opportunity Collaborator"
                      values={opportunity.collaboratorId.map(
                        id => namesById?.[id]?.fullName ?? null
                      )}
                    />
                  </OPDFColumn>
                </OPDFRow>

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Practice Area"
                      values={
                        practiceAreas.find(
                          pa => pa.id === opportunity.practiceAreaId
                        )?.name || null
                      }
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Client Budget Confirmed"
                      values={
                        OpportunitiesConst.ClientBudgetConfirmed[
                          opportunity.clientBudgetConfirmed
                        ]
                      }
                    />
                  </OPDFColumn>
                </OPDFRow>

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Driving Need"
                      values={opportunity.drivingNeed}
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="How Client Will Cover Work, If Not Axiom"
                      values={opportunity.opportunityLostCoveragePlan}
                    />
                  </OPDFColumn>
                </OPDFRow>

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Client Budget Range - Min"
                      values={
                        opportunity.clientBudgetMin
                          ? NumberUtil.formatCurrency(
                              opportunity.clientBudgetMin,
                              opportunity.currency
                            )
                          : null
                      }
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Client Budget Range - Max"
                      values={
                        opportunity.clientBudgetMax
                          ? NumberUtil.formatCurrency(
                              opportunity.clientBudgetMax,
                              opportunity.currency
                            )
                          : null
                      }
                    />
                  </OPDFColumn>
                </OPDFRow>

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Onsite/Remote"
                      values={
                        OpportunitiesConst.WorkLocation[
                          opportunity.workLocation
                        ]
                      }
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      showAftIcon
                      title="Work Location"
                      values={opportunity.locationFullAddress.split('\n')}
                    />
                  </OPDFColumn>
                </OPDFRow>

                <OPDFRow>
                  <OPDFColumn isDetail={isDetail}>
                    <OPDFLanguages
                      title="Language"
                      values={opportunityLanguagesTexts({
                        opportunityLanguages: opportunity.languages,
                        languages,
                      })}
                    />
                  </OPDFColumn>
                  <OPDFColumn isDetail={isDetail}>
                    <UnorderedList
                      title="Talent Country"
                      values={opportunity.requiredTalentCountryCode}
                    />
                  </OPDFColumn>
                </OPDFRow>
                <OPDFRow>
                  <OpportunityConfidential opportunity={opportunity} />
                </OPDFRow>
              </>
            )}
            {!isDetail && (
              <Button
                pattern="secondary"
                variation="minimal"
                fluid
                className="gtm-show-more-less"
                onClick={this.toggleShowLess}
                name="SHOW_MORE"
                icon={showLess ? 'arrow-right' : 'arrow-up'}
              >
                {showLess ? 'Show More' : 'Show Less'}
              </Button>
            )}
          </OPDFRows>

          <OpportunityProfileNextStepsDescription opportunity={opportunity} />
          {!isDetail && <OpportunityProfilePositions />}
        </Column>
        {isDetail && (
          <Column>
            <OpportunityProfilePositions />
          </Column>
        )}
      </Container>
    );
  }
}

OpportunityProfileDetailForm.defaultFormProps = {
  destroyOnUnmount: false,
  enableReinitialize: true,
  persistentSubmitErrors: true,
  validate: () => ({}),
};

OpportunityProfileDetailForm.defaultProps = {
  isDetail: false,
  namesById: {},
};

OpportunityProfileDetailForm.propTypes = {
  featureFlags: PreloadedFeatureFlagsStore.getDataShape().isRequired,
  isDetail: bool,
  opportunity: opportunityShape.isRequired,
  languages: PreloadedLanguagesStore.getDataShape().isRequired,
  namesById: shape({}),
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
};

const mapStateToProps = state => ({
  featureFlags: PreloadedFeatureFlagsStore.select(state),
  namesById: PreloadedUsersStore.selectDataForById(state),
  languages: PreloadedLanguagesStore.selectData(state),
  practiceAreas: PreloadedPracticeAreasStore.selectData(state),
});

export default connect(mapStateToProps)(OpportunityProfileDetailForm);
