import {
  LawyerDesiredMaxHourOverride,
  LawyerDesiredMaxHoursOverrideEligibleCountries,
  ProfessionConst,
} from '@axiom/const';

export const extractEmailsBasedOnPreferences = ({
  preferredContactMethod,
  personalEmail,
  workEmail,
}) => {
  const normalizedContactMethod = (
    preferredContactMethod || 'none'
  ).toLowerCase();
  const emails = [];
  if (
    (normalizedContactMethod === 'both' ||
      normalizedContactMethod === 'personal') &&
    personalEmail
  ) {
    emails.push(personalEmail);
  }
  if (
    (normalizedContactMethod === 'both' ||
      normalizedContactMethod === 'axiom') &&
    workEmail
  ) {
    emails.push(workEmail);
  }
  return emails;
};

export const isEligibleForMaxHoursOverride = ({
  addressCountryCode,
  occupationType,
}) =>
  LawyerDesiredMaxHoursOverrideEligibleCountries.includes(addressCountryCode) &&
  ProfessionConst.lawyer.occupationTypes.includes(occupationType);

export const getPreferredWeeklyHoursSliderMaxValue = ({
  addressCountryCode,
  occupationType,
  maxHours: maxHoursFromWorkday,
}) =>
  isEligibleForMaxHoursOverride({ addressCountryCode, occupationType })
    ? LawyerDesiredMaxHourOverride
    : maxHoursFromWorkday;
