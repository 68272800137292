/* eslint-disable import/no-import-module-exports, unicorn/prefer-module, global-require */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { get } from 'lodash';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import { stores } from '../stores';
import GenericStore from '../classes/generic-store';

import reducers from './reducers';

const initializeStores = () => {
  Object.keys(stores).forEach(namespace => {
    if (stores[namespace] instanceof GenericStore) {
      const reduxNamespace = namespace.toUpperCase();
      stores[reduxNamespace].initializeNamespace({
        namespace: reduxNamespace,
        rootParent: stores[namespace],
        parent: stores[namespace],
      });
    }
  });
};

const makeReducers = () => {
  const returnReducers = { ...reducers };
  Object.keys(stores).forEach(namespace => {
    /**
     * getReducers returns an object of reducer events, so make a
     * func that will act as the reducer func call in redux
     */
    namespace = namespace.toUpperCase();
    const stateReducers = stores[namespace].getReducers() || {};

    /**
     * Setup an initialize state
     */
    returnReducers[namespace] = (state = {}, action) => {
      if (!action) {
        throw new Error('You need to pass an {type:###} to the dispatch');
      }
      if (!action || !action.type || typeof action.type !== 'string') {
        return state;
      }
      const actionType = action.type.toUpperCase();

      if (stateReducers[actionType]) {
        const allowedActionProps = ['payload', 'errors', 'error'];
        let payload = null;
        allowedActionProps.forEach(name => {
          if (Object.keys(action).includes(name)) {
            payload = action[name];
          }
        });
        if (actionType.includes('.')) {
          // eslint-disable-next-line unicorn/prefer-string-slice
          const substateNamespace = actionType.substring(
            actionType.indexOf('.') + 1,
            actionType.indexOf('/')
          );
          return {
            ...state,
            [substateNamespace]: stateReducers[actionType](
              get(state, substateNamespace) || {},
              payload
            ),
          };
        }
        return stateReducers[actionType](state, payload);
      }
      return state;
    };
  });

  return returnReducers;
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [
    '@@redux-form/REGISTER_FIELD',
    '@@redux-form/UNREGISTER_FIELD',
  ],
});

const initializeStore = () => {
  initializeStores();

  const state = Object.keys(stores).reduce((acc, namespace) => {
    if (stores[namespace] instanceof GenericStore) {
      acc[namespace] = stores[namespace].getInitialState();
    }
    return acc;
  }, {});

  const store = createStore(
    combineReducers(makeReducers()),
    state,
    composeEnhancers(applyMiddleware(sagaMiddleware, thunkMiddleware))
  );

  Object.keys(stores).forEach(namespace => {
    if (stores[namespace] instanceof GenericStore) {
      stores[namespace].initializeDispatch(store.dispatch);
    }
  });

  store.runSagaTask = () => {
    const newSagas = require('./sagas/index').default;
    store.sagaTask = sagaMiddleware.run(newSagas);
  };

  store.runSagaTask();
  return store;
};

if (module.hot) {
  // We decline dependencies to this module, because hot reloads to those
  // modules would require dynamically re-initializing the reducers in the store or
  // the current active sagas. While theoretically this is possible, Webpack does not
  // seem to be able to consistently recognize the handlers that would be installed for
  // them, so in certain situations the module is reloaded but the new code does not
  // actually get inserted into the existing redux store.
  // As a workaround, to guarantee that code chances are actually propagated into the
  // the running application everywhere that they're needed, updates to these items
  // will trigger a full-page reload. This means that any code transitively depended
  // on by this file, or this file itself, will trigger a page reload on change instead
  // of a hot module replacement.
  module.hot.decline();
}

export default initializeStore;
