import React from 'react';

import { HeaderLinkSection } from './HeaderBarStyles';

export const HeaderBarLinkSection = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name?: string;
}) => <HeaderLinkSection data-test={name}>{children}</HeaderLinkSection>;
