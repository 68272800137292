import has from 'lodash/has';

export const StateComponentUtil = {
  hasErrors(states) {
    return (
      states && states.some(state => state.errors && state.errors.length > 0)
    );
  },
  isLoading(states) {
    return (
      !states ||
      states.some(state => !has(state, 'isLoading') || state.isLoading)
    );
  },
  hasLoaded(states) {
    return (
      states &&
      states.every(state => has(state, 'hasLoaded') && state.hasLoaded)
    );
  },
  shouldShowInitialLoader(states) {
    if (this.hasErrors(states)) {
      return false;
    }

    if (this.isLoading(states) && !this.hasLoaded(states)) {
      return true;
    }

    return false;
  },
  shouldShowBlockingLoader(states) {
    if (this.hasErrors(states)) {
      return false;
    }

    if (this.isLoading(states) && this.hasLoaded(states)) {
      return true;
    }

    return false;
  },
  shouldShowBlockingError(states) {
    if (!this.hasErrors(states)) {
      return false;
    }

    return states.some(
      state =>
        state.errors &&
        state.errors.some(
          error =>
            error.statusCode !== 400 ||
            !error.errors ||
            error.errors.some(errMsg => !(typeof errMsg === 'string'))
        )
    );
  },
  shouldShowNonBlockingError(states) {
    if (!this.hasErrors(states)) {
      return false;
    }

    return states.every(
      state =>
        state.errors &&
        state.errors.length > 0 &&
        state.errors.every(
          error =>
            error.statusCode === 400 &&
            error.errors &&
            error.errors.every(errMsg => typeof errMsg === 'string')
        )
    );
  },

  shouldShowComponent(states) {
    if (this.shouldShowBlockingError(states)) {
      return false;
    }

    if (!this.hasLoaded(states)) {
      return false;
    }

    return true;
  },
};
