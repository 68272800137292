import React from 'react';
import moment from 'moment';

import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Button } from '../../element/Button/Button';
import { IconButton } from '../../element/Button/IconButton';

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
} as const;
type NavigateType = (typeof navigate)[keyof typeof navigate];

type CalendarActionsTypes = {
  date: Date;
  label: string;
  onNavigate: (navigateType: NavigateType) => void;
};

export const CalendarToolbar = ({
  date,
  label,
  onNavigate,
}: CalendarActionsTypes) => {
  return (
    <Gutter bottom="8px">
      <Layout position="middle" name="TOOLBAR">
        <LayoutItem fluid>
          <Button
            name="TODAY_BUTTON"
            pattern="secondary"
            variation="outline"
            onClick={() => onNavigate(navigate.TODAY)}
          >
            Today
          </Button>
        </LayoutItem>
        <LayoutItem rightGutter="8px">
          <IconButton
            name="PREVIOUS"
            pattern="secondary"
            variation="minimal"
            icon="arrow-left"
            disabled={!!moment(date).isSameOrBefore(new Date())}
            onClick={() => onNavigate(navigate.PREVIOUS)}
          />
        </LayoutItem>
        <LayoutItem name="DATE_LABEL">{label}</LayoutItem>
        <LayoutItem leftGutter="8px">
          <IconButton
            name="NEXT"
            pattern="secondary"
            variation="minimal"
            icon="arrow-right"
            onClick={() => onNavigate(navigate.NEXT)}
          />
        </LayoutItem>
      </Layout>
    </Gutter>
  );
};
