// https://sapling.ai/docs/sdk/JavaScript/frontend_documentation
export const SaplingUtil = {
  initialize(token?: string, userId?: string) {
    import('@saplingai/sapling-js/observer').then(({ Sapling }) => {
      const saplingProps: Record<string, string | Record<string, string>> = {
        key: token,
        lang: 'en',
        variety: 'null-variety',
      };

      if (userId) {
        saplingProps.userId = userId;
      }

      Sapling.init(saplingProps);
    });
  },
};
