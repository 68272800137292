import React from 'react';

import { HeaderUserSection } from './HeaderBarStyles';

export const HeaderBarUserSection = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name?: string;
}) => <HeaderUserSection data-test={name}>{children}</HeaderUserSection>;
