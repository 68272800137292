import Ajv from 'ajv';
import { WeekdayConst } from '@axiom/const';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

const generateNotificationsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    weeklyNotificationsDay: {
      type: 'string',
      enum: Object.keys(WeekdayConst),
    },
    scheduledOpportunityFeedEmailWeekly: {
      type: 'boolean',
    },
    scheduledOpportunityFeedEmailDaily: {
      type: 'boolean',
    },
  },
};

export const generateNotificationsValidator = ajv.compile(
  generateNotificationsValidation
);
