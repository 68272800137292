import moment from 'moment';
import { CalendarComponentEvent, CalendarEventType } from '@axiom/validation';

import { CalendarEventsUtil } from './calendar-events-util';
import { EventStates } from './CalendarEventsConst';

const getEventState = (
  fromState: CalendarComponentEvent['state'],
  toState: CalendarComponentEvent['state']
) => {
  return fromState === EventStates.NEW ? fromState : toState;
};

const getLastFreeTimeEvent = (events: CalendarComponentEvent[]) => {
  let idx = -1;

  for (let i = events.length - 1; i >= 0; i--) {
    const e = events[i];

    if (!e.busy && e.state !== EventStates.DELETE) {
      idx = i;
      break;
    }
  }

  return events[idx] || null;
};

export const useMergeEvents = () => {
  return (data: CalendarComponentEvent[]) => {
    const allEvents = CalendarEventsUtil.sortEvents(data);

    const newEvents = allEvents.reduce(
      (
        events: CalendarComponentEvent[],
        currentEvent: CalendarComponentEvent
      ) => {
        const lastEvent: CalendarComponentEvent = events.at(-1) || null;

        if (lastEvent) {
          const lastFreeTime = getLastFreeTimeEvent(events);
          const ceStart = moment(currentEvent.start);
          const ceEnd = moment(currentEvent.end);

          if (lastFreeTime && !currentEvent.busy) {
            // MERGE NON-staticEvent TYPE EVENTS
            const leStart = moment(lastFreeTime.start);
            const leEnd = moment(lastFreeTime.end);

            if (
              ceStart.isBetween(leStart, leEnd, 'minutes', '[]') ||
              ceEnd.isBetween(leStart, leEnd, 'minutes', '[]') ||
              (ceStart.isBefore(leStart, 'minutes') &&
                ceEnd.isAfter(leEnd, 'minutes'))
            ) {
              lastFreeTime.start = moment.min(ceStart, leStart).toDate();
              lastFreeTime.end = moment.max(ceEnd, leEnd).toDate();
              lastFreeTime.state = getEventState(
                lastFreeTime.state,
                EventStates.MODIFIED
              );
            } else {
              events.push(currentEvent);
            }
          } else if (
            lastEvent.busy &&
            lastEvent.state !== EventStates.SAVED &&
            currentEvent.busy &&
            currentEvent.state !== EventStates.SAVED
          ) {
            // is staticEvent type
            const leStart = moment(lastEvent.start);
            const leEnd = moment(lastEvent.end);

            if (
              ceStart.isSame(leStart, 'minutes') &&
              ceEnd.isSame(leEnd, 'minutes')
            ) {
              // staticEvent already exists in this  slot
              lastEvent.state = getEventState(
                lastEvent.state,
                EventStates.MODIFIED
              );
            } else {
              events.push(currentEvent);
            }
          } else {
            events.push(currentEvent);
          }
        } else {
          events.push(currentEvent);
        }

        return events;
      },
      [] as CalendarEventType[]
    );

    return newEvents;
  };
};
