const ResetPassword = 'ResetPassword';
const ConfirmAvailability = 'ConfirmAvailability';
const NoPasswordLogin = 'NoPasswordLogin';
const ViewBioNoPasswordLogin = 'ViewBioNoPasswordLogin';
const ViewBenchNoPasswordLogin = 'ViewBenchNoPasswordLogin';
const BenchUrlNoPasswordLogin = 'BenchUrlNoPasswordLogin';

const statementActions = {
  ResetPassword,
  ConfirmAvailability,
  NoPasswordLogin,

  // TODO: remove if not used by 1/1/2022
  ViewBioNoPasswordLogin,
  ViewBenchNoPasswordLogin,
  BenchUrlNoPasswordLogin,
};

export const TokenConst = {
  statementActions,
};
