import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaDate, SchemaLocation } from '@axiom/types';

import { OpportunitySchema } from './opportunity';
import { PositionSchema } from './positions';
import { axiomValidationOptions } from './options';
import { TagSchema } from './tag';

const ajv = new Ajv(axiomValidationOptions());

export const ExperienceTaxonomySchema = z.object({
  id: z.string().uuid(),
  experienceId: z.string().uuid(),
  practiceArea: z.string(),
  focusArea: z.string(),
  skillGroup: z.string(),
  legalSkills: z.array(z.string()),
  dsSuggestion: z.boolean(),
});

export const ExperienceFocusAreaOrderPreferenceSchema = z.object({
  experienceId: z.string().uuid(),
  focusAreas: z.array(z.string()),
});

export const ExperienceSchema = z
  .object({
    candidateId: z.string().uuid(),
    client: z.string().max(255), // being deprecated
    calculatedDisplayName: z.string().max(255),
    clientDomain: z.string().max(255).nullish(),
    description: z.string(),
    endDate: SchemaDate.nullish(),
    experiencesFocusAreaOrderPreferences: z
      .array(ExperienceFocusAreaOrderPreferenceSchema)
      .nullish(),
    experienceTaxonomy: z.array(ExperienceTaxonomySchema).nullish(),
    externalOpportunityName: z.string().max(255),
    generalSkills: z.array(z.string()).nullable(),
    id: z.string().uuid(),
    industryIsOverridden: z.boolean().default(false),
    industryValue: z.string().nullable(),
    isAxiom: z.boolean().default(false),
    isConfidential: z.boolean(),
    locationPoint: z
      .object({
        coordinates: z.array(z.number()).nullish(),
        crs: z
          .object({
            properties: z.object({ name: z.string() }).nullish(),
            type: z.string().nullish(),
          })
          .nullish(),
        type: z.string().nullish(),
      })
      .nullish(),
    opportunity: OpportunitySchema.extend({
      position: PositionSchema,
    }),
    opportunityId: z.string().uuid(),
    practiceAreaId: z.string().uuid().nullish(),
    startDate: SchemaDate,
    tags: z.array(TagSchema),
  })
  .merge(SchemaLocation);

export const GroupedExperiencesSchema = ExperienceSchema.pick({
  candidateId: true,
}).merge(
  z.object({
    isAxiom: z.boolean(),
    endDate: SchemaDate,
    startDate: SchemaDate,
    experiences: z.array(ExperienceSchema),
  })
);

export const ExperienceEditSchema = ExperienceSchema.omit({
  opportunity: true,
  isConfidential: true,
  calculatedDisplayName: true,
  isAxiom: true,
  experiencesFocusAreaOrderPreferences: true,
  experienceTaxonomy: true,
})
  .partial({
    id: true,
    opportunityId: true,
  })
  .extend({
    tags: z.array(z.string().uuid()).nullish(),
    generalSkills: z.array(z.string()).nullish(),
    industryValue: z.string(),
  });

const experienceCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    client: {
      type: 'string',
    },
    externalOpportunityName: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    startDate: {
      type: 'string',
      format: 'date',
    },
    endDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    clientDomain: {
      type: ['string', 'null'],
    },
    locationAddressComponents: {
      type: ['object', 'null'],
      additionalProperties: false,
      properties: {
        addresses: {
          items: {
            type: 'object',
            additionalProperties: false,
            properties: {
              types: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
              long_name: {
                type: 'string',
              },
              short_name: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    locationLatitude: {
      type: ['number', 'null'],
    },
    locationLongitude: {
      type: ['number', 'null'],
    },
    locationName: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    locationPlaceId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    isConfidential: {
      type: ['boolean'],
    },
    opportunityId: {
      type: 'string',
      format: 'uuid',
    },
    tags: { type: 'array', items: { type: 'string', format: 'uuid' } },
    industryValue: {
      type: 'string',
    },
  },
};

export const createExperienceValidator = ajv.compile({
  ...experienceCommonValidation,
  required: ['client', 'startDate'],
  dependencies: {
    locationLatitude: ['locationLongitude'],
    locationLongitude: ['locationLatitude'],
  },
});

const allowedExperienceUpdates = [
  { required: ['client'] },
  { required: ['description'] },
  { required: ['startDate'] },
  { required: ['endDate'] },
  { required: ['externalOpportunityName'] },
  { required: ['locationName'] },
  { required: ['locationAddressComponents'] },
  { required: ['locationLatitude'] },
  { required: ['locationLongitude'] },
  { required: ['locationPlaceId'] },
  { required: ['isConfidential'] },
  { required: ['tags'] },
  { required: ['industryValue'] },
];

export const updateExperienceValidator = ajv.compile({
  ...experienceCommonValidation,
  anyOf: [...allowedExperienceUpdates],
  dependencies: {
    locationLatitude: ['locationLongitude'],
    locationLongitude: ['locationLatitude'],
  },
});

export const updateExperienceEnvoyValidator = ajv.compile({
  ...experienceCommonValidation,
  anyOf: [...allowedExperienceUpdates, { required: ['opportunityId'] }],
  dependencies: {
    locationLatitude: ['locationLongitude'],
    locationLongitude: ['locationLatitude'],
  },
});
