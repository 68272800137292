import React from 'react';

import { Layout } from '../../layout/Layout/Layout';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const Pagination = ({
  className,
  currentPage,
  name,
  nextButton,
  prevButton,
  totalPages,
}: {
  className?: string;
  currentPage: number;
  name?: string;
  nextButton: React.ReactElement;
  prevButton: React.ReactElement;
  totalPages: number;
}) => {
  const next = React.cloneElement(nextButton, {
    disabled: currentPage >= totalPages,
  });
  const prev = React.cloneElement(prevButton, {
    disabled: currentPage <= 1,
  });

  return (
    <Layout
      inline
      className={AttrsHelper.formatClassname('pagination-wrapper', className)}
      position="middle"
      horizontalGutter="8px"
      name={name}
    >
      {prev}
      <span className={`pagination-text ${name}-pagination-contents`}>
        {currentPage} of {totalPages || 1}
      </span>
      {next}
    </Layout>
  );
};
