import {
  API_ERROR_RECEIVED,
  APP_LOADING,
  APP_RECEIVE_INITIAL_DATA,
  APP_SIDE_BAR_COLLAPSE,
  APP_SIDE_BAR_EXPAND,
  APP_SIDE_BAR_ACTIVE,
  APP_SIDE_BAR_INACTIVE,
  APP_SIDE_DRAWER_ACTIVE,
  APP_SIDE_DRAWER_INACTIVE,
  APP_ADD_ACTIVE_RECORD,
  TALENT_RECORD_TYPE,
  GET_DECORATED_TALENT,
  DECORATED_TALENT_LOADED,
  DECORATED_TALENT_ERROR,
  OPPORTUNITY_RECORD_TYPE,
  GET_DECORATED_OPPORTUNITY,
  DECORATED_OPPORTUNITY_LOADED,
  DECORATED_OPPORTUNITY_ERROR,
  RELATED_OPPORTUNITIES_LOAD,
  RELATED_OPPORTUNITIES_LOADED,
  RELATED_OPPORTUNITIES_ERROR,
  RELATED_RECORD_TYPE,
} from '../constants';

const INITIAL_STATE = {
  appLoading: false,
  hasInitialData: false,
  sideDrawerExpanded: false,
  sideBarExpanded: false,
  sideBarActive: true,
  sideDrawerActive: false,
  activeRecordId: -1,
  activeRecordType: null,
  lockedRecordId: -1,
  lockedRecordType: null,
  loadingContentType: null,
  apiErrors: [],
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case APP_LOADING: {
      return {
        ...state,
        appLoading: payload,
      };
    }

    case APP_RECEIVE_INITIAL_DATA: {
      return {
        ...state,
        hasInitialData: true,
      };
    }

    case API_ERROR_RECEIVED: {
      const apiErrors = [...state.apiErrors, payload];

      return {
        ...state,
        apiErrors,
      };
    }

    case RELATED_OPPORTUNITIES_LOAD: {
      return {
        ...state,
        loadingContentType: RELATED_RECORD_TYPE,
      };
    }

    case GET_DECORATED_TALENT: {
      return {
        ...state,
        lockedRecordId: payload.talentID,
        lockedRecordType: TALENT_RECORD_TYPE,
        loadingContentType: TALENT_RECORD_TYPE,
      };
    }

    case GET_DECORATED_OPPORTUNITY: {
      return {
        ...state,
        lockedRecordId: payload.opportunityId,
        lockedRecordType: OPPORTUNITY_RECORD_TYPE,
        loadingContentType: OPPORTUNITY_RECORD_TYPE,
      };
    }
    case DECORATED_TALENT_LOADED:
    case DECORATED_TALENT_ERROR:
    case DECORATED_OPPORTUNITY_LOADED:
    case DECORATED_OPPORTUNITY_ERROR:
    case RELATED_OPPORTUNITIES_LOADED:
    case RELATED_OPPORTUNITIES_ERROR: {
      return {
        ...state,
        lockedRecordId: -1,
        lockedRecordType: null,
        loadingContentType: null,
      };
    }

    case APP_SIDE_BAR_EXPAND: {
      return {
        ...state,
        sideBarExpanded: true,
      };
    }
    case APP_SIDE_BAR_COLLAPSE: {
      return {
        ...state,
        sideBarExpanded: false,
      };
    }

    case APP_SIDE_BAR_ACTIVE: {
      return {
        ...state,
        sideBarActive: true,
      };
    }
    case APP_SIDE_BAR_INACTIVE: {
      return {
        ...state,
        sideBarActive: false,
      };
    }

    case APP_SIDE_DRAWER_ACTIVE: {
      return {
        ...state,
        sideDrawerActive: true,
      };
    }
    case APP_SIDE_DRAWER_INACTIVE: {
      return {
        ...state,
        sideDrawerActive: false,
      };
    }

    case APP_ADD_ACTIVE_RECORD: {
      return {
        ...state,
        activeRecordId: payload.id,
        activeRecordType: payload.recordType,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
