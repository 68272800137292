import styled from 'styled-components';

import { getThemeProp } from '../../themes';
import { Col } from '../../themes/components';
// eslint-disable-next-line boundaries/element-types
import { CheckboxWithLabelWrapper } from '../CheckboxWithLabel/CheckboxWithLabelStyles';
import {
  ULTitle,
  UnorderedListWrapper,
  // eslint-disable-next-line boundaries/element-types
} from '../UnorderedList/UnorderedListStyles';

import { SubmissionModalRateInputWrapper } from './SubmissionModalStyles';

export const SMSCWrapper = styled.div``;
export const SMSCHeader = styled.div`
  padding: 1.5rem;
  background: ${getThemeProp('appBackground')};
  border-bottom: 0.0625rem solid ${getThemeProp('sectionBorder')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SMSCProposedCostWrapper = styled.div``;
export const SMSCHeaderLeft = styled.div``;
export const SMSCStatus = styled.div``;
export const SMSCHeaderRight = styled.div``;
export const SMSCPopOutButton = styled.div`
  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 2.8125rem;
    height: 2.8125rem;
    svg {
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }
`;
export const SMSCBody = styled.div`
  padding: 0 1.5rem;
`;
export const SMSCIncludeTalentCheckboxWrapper = styled.div`
  padding: 1rem 0 1.5rem 0;

  ${CheckboxWithLabelWrapper} {
    margin-left: 0;
  }
`;

export const SMSCRateWrapper = styled.div`
  ${Col}:not(:last-child) ${SubmissionModalRateInputWrapper} {
    padding-right: 0.3rem;
  }

  .talent-hourly-tooltip {
    margin-right: 6px;
  }

  ${UnorderedListWrapper} {
    width: 100%;
  }
  ${ULTitle} {
    display: block;
  }
`;

export const SMSCExperiencesDescription = styled.div`
  line-height: 1.5rem;
  margin-top: 1.5rem;
  color: #001d4c;
  margin-bottom: 2rem;
`;
export const SMSCSubmissionDetails = styled.div`
  padding: 1.5rem 0;
`;
export const SMSCSubmissionDetailsHeader = styled.div`
  font-weight: bold;
`;
export const SMSCSubmissionDetailsBody = styled.div`
  margin-top: 1rem;
`;

export const SMSCExperiencesDescriptionDetails = styled.div`
  padding-left: 1rem;
`;

export const SMSCExperienceDescriptionTitle = styled.div`
  padding: 1.125rem 0 0.625rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
