import { FetchWatcher } from './endpoint-cache';

export type DataFetcherParams = {
  search?: string;
  sort: Record<string, string>;
  page: number;
};
type DataFetcher = ({ sort, page }: DataFetcherParams) => FetchWatcher<unknown>;

type DataParser = (response: unknown) => {
  currentPage: number;
  totalPages: number;
  data: Array<Record<string, unknown>>;
};

export class AsyncConnector {
  private dataFetcher: DataFetcher;

  private dataParser: DataParser;

  private pageSize: number;

  private watcher: FetchWatcher<unknown>;

  private fetchCount = 0;

  constructor({
    getData,
    parseData,
    pageSize = 20,
  }: {
    getData: DataFetcher;
    parseData: DataParser;
    pageSize?: number;
  }) {
    this.dataFetcher = getData;
    this.dataParser = parseData;
    this.pageSize = pageSize;
  }

  getData(args: DataFetcherParams) {
    this.watcher = this.dataFetcher(args);
    return (
      this.watcher.hasResolved()
        ? this.watcher.refetch(false)
        : this.watcher.toPromise()
    ).then(data => {
      this.fetchCount += 1;
      return data;
    });
  }

  onInvalidate(evt: () => void) {
    return this.watcher?.listenForInvalidate(evt);
  }

  parseData(response: unknown) {
    return this.dataParser(response);
  }

  getPageSize() {
    return this.pageSize;
  }

  hasFetched() {
    return this.fetchCount > 0;
  }
}
