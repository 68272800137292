import React from 'react';

import { LayoutItem } from '../Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Layout } from '../Layout/Layout';

export const CardHeaderBackgrounds = {
  light: 'light',
  dark: 'dark',
  blue: 'blue',
} as const;

export type CardHeaderBackgrounds =
  (typeof CardHeaderBackgrounds)[keyof typeof CardHeaderBackgrounds];

export type CardHeaderProps = {
  background?: CardHeaderBackgrounds;
  Badge?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  onClick?: () => void;
};

export const CardHeader = ({
  background = 'light',
  Badge,
  className,
  children,
  name,
  onClick,
}: CardHeaderProps) => (
  <div
    className={AttrsHelper.formatClassname(
      'card-header-container',
      `background-${background}`,
      Badge && `flush-right`,
      onClick && 'clickable',
      className
    )}
    data-test={name}
    onClick={onClick}
  >
    <Layout
      position="middle"
      reversibleOn="mobile"
      stackableOn="mobile"
      verticalGutter="8px"
    >
      <LayoutItem fluid hug>
        <div className="header-contents">{children}</div>
      </LayoutItem>
      {Badge && <div className="header-badge">{Badge}</div>}
    </Layout>
  </div>
);
