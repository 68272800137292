import arrayMutators from 'final-form-arrays'; // for usage with `import { FieldArray } from 'react-final-form-arrays'`

/**
 * This util is to only be used by FormStateTreatment
 */

const nullify = (args, state, utils) => {
  const [fields] = args;

  // 'fields' is an array of field names
  fields.forEach(field => utils.changeValue(state, field, () => null));
};

const setValues = (args, state, utils) => {
  const [fields] = args;

  // 'fields' is an array of objects {name, value}
  fields.forEach(field =>
    utils.changeValue(state, field.name, () => field.value)
  );
};

export const Mutators = {
  ...arrayMutators,
  nullify,
  setValues,
};
