import orderBy from 'lodash/orderBy';
import { Candidate, SubmissionCandidate } from '@axiom/validation';

type Props = Candidate['industries'] | SubmissionCandidate['industries'];

export class CandidateIndustryModel {
  props: Props = [];

  constructor(props: Props) {
    this.props = props;
  }

  getDisplayableIndustries() {
    return this.props.filter(i => i.industryValue?.toLowerCase() !== 'other');
  }

  formatIndustries() {
    return orderBy(
      this.getDisplayableIndustries().map(
        (industry: SubmissionCandidate['industries'][number]) => ({
          ...industry,
          yearsOfExperience: Number.parseInt(industry.yearsOfExperience, 10),
        })
      ),
      ['yearsOfExperience', 'industryValue'],
      ['desc', 'asc']
    );
  }
}
