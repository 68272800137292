import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const LawSchoolSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255),
});

export const LawSchoolSearchSchema = z.object({
  data: z.array(LawSchoolSchema).nullish(),
  meta: z.object({
    aggregations: z.number().nonnegative().int(),
    currentPage: z.number().nonnegative().int(),
    pageCount: z.number().nonnegative().int(),
    resultCount: z.number().nonnegative().int(),
  }),
});

const ajv = new Ajv(axiomValidationOptions());

const lawSchoolsSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    search: { type: 'string' },
    page: { type: ['integer', 'string'], default: 1 },
    sort: { type: 'string', default: 'name' },
    filters: {
      type: 'object',
      additionalProperties: false,
      properties: {
        name: {
          type: ['string', 'null'],
          maxLength: 255,
        },
        tags: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
      },
    },
  },
};

const lawSchoolsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
    tags: { type: 'array', items: { type: 'string', format: 'uuid' } },
  },
  required: ['name'],
};

const lawSchoolsUpdateValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
    tags: {
      type: 'array',
      items: { type: 'string', format: 'uuid' },
    },
  },
  anyOf: [{ required: ['name'] }, { required: ['tags'] }],
};

export const lawSchoolsSearchValidator = ajv.compile(
  lawSchoolsSearchValidation
);

export const lawSchoolsValidator = ajv.compile(lawSchoolsValidation);

export const lawSchoolsUpdateValidator = ajv.compile(
  lawSchoolsUpdateValidation
);
