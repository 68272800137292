const customKeywords = {
  // https://github.com/ajv-validator/ajv/issues/399#issuecomment-275566352
  parseFloat: ajvInstance => ({
    type: 'string',
    modifying: true,
    compile: schema => (data, _dataPath, parentData, parentDataProperty) => {
      if (schema.type === 'number') {
        parentData[parentDataProperty] = Number.parseFloat(data);
      } else {
        throw new Error(`Unable to coerce 'string' to '${schema.type}'`);
      }

      return ajvInstance.compile(schema);
    },
  }),
};

export const addCustomKeywords = ajvInstance => {
  Object.keys(customKeywords).forEach(key => {
    ajvInstance.addKeyword(key, customKeywords[key](ajvInstance));
  });
};
