/**
 * Temp enum for js files to use Practice Area with PUT Opportunity Endpoint
 */

export enum PracticeArea {
  ArtificialIntelligence = 'ArtificialIntelligence',
  GeneralCounselServices = 'GeneralCounselServices',
  BankruptcyAndReorganization = 'BankruptcyAndReorganization',
  CapitalMarkets = 'CapitalMarkets',
  CommercialAndContractLaw = 'CommercialAndContractLaw',
  Corporate = 'Corporate',
  DataPrivacyAndCybersecurity = 'DataPrivacyAndCybersecurity',
  Finance = 'Finance',
  Insurance = 'Insurance',
  IntellectualProperty = 'IntellectualProperty',
  LaborAndEmployment = 'LaborAndEmployment',
  LegalSupport = 'LegalSupport',
  LitigationAndDisputeResolution = 'LitigationAndDisputeResolution',
  MergersAndAcquisitions = 'MergersAndAcquisitions',
  RealEstate = 'RealEstate',
  RegulatoryAndCompliance = 'RegulatoryAndCompliance',
  Tax = 'Tax',
}
