import GenericStore from '../classes/generic-store';
import { getJob as getOpportunity } from '../api/jobs';
import {
  refreshBurdenedCost,
  OpportunityLegacyApi,
} from '../api/opportunities-legacy-api';
import { opportunityShape } from '../models/opportunities';

import { OpportunityEngagementsStore } from './opportunity-engagements-store';
import { OpportunityPositionsStore } from './opportunity-positions-store';
import { OpportunityCandidatesStore } from './opportunity-candidates-store';
import { EditOpportunityTalentTabStore } from './edit-opportunity-talent-tab-store';
import { EventsStore, EventSubjectConst } from './events-store';

class OpportunityStoreClass extends GenericStore {
  load(opportunityId) {
    return this.setState(
      Promise.all([
        getOpportunity(opportunityId).then(data => data.data),
        OpportunityPositionsStore.load(opportunityId),
        OpportunityCandidatesStore.load(opportunityId, true),
        EventsStore.load(EventSubjectConst.Opportunity, opportunityId),
        OpportunityEngagementsStore.load(opportunityId),
      ]).then(async ([opportunity, positions, candidates, { events }]) => {
        await EditOpportunityTalentTabStore.load({
          opportunity,
          candidates,
          positions,
          events,
        });
        OpportunityLegacyApi.refreshOpportunityCandidates(opportunityId);
        OpportunityLegacyApi.refreshOpportunityPositions(opportunityId);
        return opportunity;
      })
    );
  }

  getDataShape() {
    return opportunityShape;
  }

  updateBurdenedCost(opportunityId, candidateId) {
    return refreshBurdenedCost(opportunityId, candidateId).then(() => {
      return this.load(opportunityId);
    });
  }
}

export const OpportunityStore = new OpportunityStoreClass();
