import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';
import { ContactsOpportunitiesConst } from '@axiom/const';

const { Roles } = ContactsOpportunitiesConst;
type RolesType = keyof typeof Roles;
const RolesValues = Object.values(
  ContactsOpportunitiesConst.Roles
) as NonEmptyArray<RolesType>;

export const ContactsOpportunitySchema = z.object({
  id: z.string().uuid(),
  contactId: z.string().uuid(),
  createdById: z.string().uuid().nullable(),
  opportunityId: z.string().uuid(),
  role: z.enum(RolesValues).nullable(),
  createdAt: SchemaTimestamp,
  updatedAt: SchemaTimestamp,
});
