import { DateUtil } from '@axiom/ui';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { patchTalent, LegacyTalentApi } from '../api/talent';
import { postCollaboratorsByTalentId } from '../api/talentCollaborators';
import { postLanguagesByTalentId } from '../api/talentLanguages';
import { getDecoratedTalent } from '../redux/actions/talent';
import { OpportunityLegacyApi } from '../api/opportunities-legacy-api';

const CandidateProfileEditValidation = AjvUtil.compileSchema({
  dependencies: {
    specialtyId: ['practiceAreaId'],
  },
  properties: {
    barredLocations: {
      type: 'array',
    },
    collaborators: {
      type: 'array',
    },
    displayFirstName: {
      type: ['string', 'null'],
    },
    displayLastName: {
      type: ['string', 'null'],
    },
    noticePeriod: {
      type: 'string',
    },
    specialtyId: {
      type: 'string',
      minlength: 1,
      not: {
        type: 'null',
      },
      errorMessage: 'Required',
    },
    isOpenToFullTime: {
      type: 'string',
      if: { pattern: 'false' },
      // eslint-disable-next-line unicorn/no-thenable
      then: {
        properties: {
          partTimeHoursMon: {
            type: 'number',
          },
          partTimeHoursTue: {
            type: 'number',
          },
          partTimeHoursWed: {
            type: 'number',
          },
          partTimeHoursThu: {
            type: 'number',
          },
          partTimeHoursFri: {
            type: 'number',
          },
        },
      },
    },

    daysRequiredRemote: {
      type: 'number',
      maximum: { $data: '1/daysDesiredRemote' },
    },

    daysDesiredRemote: {
      type: 'number',
    },

    isOpenToPartTime: {
      type: 'string',
      if: { pattern: 'false' },
      // eslint-disable-next-line unicorn/no-thenable
      then: {
        isOpenToAdHoc: {
          type: 'string',
        },
        isOpenToRemote: {
          type: 'string',
        },
      },
    },

    isOpenToCommute: {
      type: 'string',
      if: { pattern: 'false' },
      // eslint-disable-next-line unicorn/no-thenable
      then: {
        daysWillingToCommute: {
          type: 'number',
        },
        maxMinutesCommuteOneWay: {
          type: 'string',
        },
      },
    },

    languages: {
      type: 'array',
    },
  },
});

class FormTalentProfileEditClass extends FormStore {
  openModal(talent) {
    return this.setState({ talent });
  }

  validate(formData) {
    const specialtyId = formData.practiceAreaId
      ? formData.specialtyId || null
      : undefined;

    return AjvUtil.formatValidationForFinalForm(
      CandidateProfileEditValidation,
      {
        ...formData,
        specialtyId,
      }
    );
  }

  submit(changedFields, formData) {
    // when the below fields are empty RFF ignores them as 'changed'
    ['displayFirstName', 'displayLastName'].forEach(prop => {
      if (!changedFields[prop] && !formData[prop] && formData.talent[prop]) {
        changedFields[prop] = null;
      }
    });

    const candidateId = formData.talent.id;
    const promises = [];
    const patchTalentBody = Object.keys(changedFields)
      .filter(key => !['languages', 'collaborators'].includes(key))
      // Needs to be refactored

      .reduce((data, key) => {
        // This logic should not be here, this is API logic why does the UI care?
        const rawVal = changedFields[key];
        let val = rawVal === 'true' ? true : rawVal;
        val = rawVal === 'false' ? false : val;

        switch (key) {
          case 'isOpenToFullTime': {
            if (val) {
              data.isOpenToPartTime = changedFields.isOpenToPartTime || false;
              data.isOpenToAdHoc = changedFields.isOpenToAdHoc || false;
              data.partTimeHoursMon = changedFields.partTimeHoursMon || null;
              data.partTimeHoursTue = changedFields.partTimeHoursTue || null;
              data.partTimeHoursWed = changedFields.partTimeHoursWed || null;
              data.partTimeHoursThu = changedFields.partTimeHoursThu || null;
              data.partTimeHoursFri = changedFields.partTimeHoursFri || null;
            } else {
              data.isOpenToPartTime = changedFields.isOpenToPartTime || true;
              data.isOpenToAdHoc = changedFields.isOpenToAdHoc || null;
              data.partTimeHoursMon = changedFields.partTimeHoursMon || 0;
              data.partTimeHoursTue = changedFields.partTimeHoursTue || 0;
              data.partTimeHoursWed = changedFields.partTimeHoursWed || 0;
              data.partTimeHoursThu = changedFields.partTimeHoursThu || 0;
              data.partTimeHoursFri = changedFields.partTimeHoursFri || 0;
            }
            break;
          }
          case 'isOpenToRemote': {
            if (val) {
              data.daysDesiredRemote = changedFields.daysDesiredRemote || 0;
              data.daysRequiredRemote = changedFields.daysRequiredRemote || 0;
            }
            break;
          }
          case 'isOpenToCommute': {
            if (val) {
              data.daysWillingToCommute =
                changedFields.daysWillingToCommute || 1;
              data.maxMinutesCommuteOneWay =
                changedFields.maxMinutesCommuteOneWay || 30;
            }
            break;
          }
          case 'specialtyId': {
            // both practiceAreaId and specialtyId are practiceAreaId to the API
            data.practiceAreaId = changedFields.specialtyId;
            val = undefined;
            break;
          }
          case 'yearsPracticing': {
            data.practiceStartYear = DateUtil.yearsCountToYear(
              formData.yearsPracticing
            );
            val = undefined;
            break;
          }
          default:
        }

        data[key] = val;

        return data;
      }, {});

    const hasTalentBodyData = Object.keys(patchTalentBody).length > 0;
    if (hasTalentBodyData) {
      promises.push(patchTalent(candidateId, patchTalentBody));
    }

    if (changedFields.languages) {
      const putTalentLanguagesBody = (changedFields.languages || []).map(
        item => {
          item.languageId = `${item.id}`; // cloning value just in case.
          delete item.id;

          return item;
        }
      );

      promises.push(
        postLanguagesByTalentId(candidateId, putTalentLanguagesBody)
      );
    }

    if (changedFields.collaborators) {
      const putCollaboratorsBody = changedFields.collaborators || [];

      promises.push(
        postCollaboratorsByTalentId(candidateId, putCollaboratorsBody)
      );
    }

    return this.clearState(Promise.all(promises)).then(() => {
      this.dispatch(getDecoratedTalent(candidateId));
      LegacyTalentApi.invalidateAll();
      OpportunityLegacyApi.invalidateAll();
    });
  }
}

export const FormTalentProfileEditStore = new FormTalentProfileEditClass();
