import React from 'react';
import qs from 'qs';
import { bool, string, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Tabs, Tab, Gutter, Layout, LayoutItem } from '@axiom/ui';
import { CandidateOpportunitiesConst } from '@axiom/const';

import OpportunityProfileHeader from '../OpportunityProfileHeader/OpportunityProfileHeader';
import OpportunityProfileDetailForm from '../OpportunityProfileDetailForm/OpportunityProfileDetailForm';
import OpportunityEngagements from '../OpportunityEngagements/OpportunityEngagements';
import OpportunityTalent from '../OpportunityTalent/OpportunityTalent';
import ActivityLog from '../ActivityLog/ActivityLog';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import SubmissionModal from '../SubmissionModal/SubmissionModal';
import {
  opportunityFormInitialValues,
  opportunityShape,
} from '../../models/opportunities';
import { makeOpportunitiesViewSelector } from '../../redux/selectors/opportunitiesProfileComponentSelector';
import { window } from '../../utils/global';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EngagementsUtils } from '../../utils/engagements';
import { OpportunityEngagementsStore } from '../../stores/opportunity-engagements-store';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { OpportunityStore } from '../../stores/opportunity-store';
import { formatDataTestId } from '../../utils/dataTest';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { OpportunityUtils } from '../../utils/opportunity-utils';
import { EventsStore } from '../../stores/events-store';
import { WindowUtil } from '../../utils/window-util';
import { WithRouterShape } from '../../models/router';
import { withRouter } from '../../hocs/with-router';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';

import { JobProfileNoItems } from './OpportunityDetailsStyles';

class OpportunityDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { searchTerm } = nextProps;
    if (nextProps.isDetail) {
      const params = window && window.location && window.location.search;
      if (params) {
        const parsed = qs.parse(params.slice(1));
        searchTerm = parsed.search;
      }
    }
    if (searchTerm === prevState.searchTerm) return null;
    return { ...prevState, searchTerm };
  }

  renderTabs(
    engagements,
    eventsData,
    positions,
    editOpportunityTalentTabState
  ) {
    const {
      props: {
        isDetail,
        user,
        opportunity,
        router: { location, params, navigate },
      },
    } = this;

    const engagementsCount = EngagementsUtils.countEngagements(
      opportunity,
      engagements
    );

    let rootPath = 'opportunities';

    if (location.pathname?.includes('opportunity-detail')) {
      rootPath = 'opportunity-detail';
    } else if (location.pathname?.includes('workspace')) {
      rootPath = 'workspace/opportunities';
    }

    const basePath = `/${rootPath}/${params.id}`;

    const opportunityValues = opportunityFormInitialValues(opportunity);

    if (params.view === 'submission') {
      EditSubmissionStore.load(
        opportunity.submissionId,
        opportunity.id,
        params.viewId
      );
    }

    const goToTab = tabSlug => {
      navigate(WindowUtil.appendQueryParams(`${basePath}/${tabSlug}`));
    };

    const getStartTab = () => {
      // TODO: remove opportunities once sidedrawer is removed (HAL-1970)
      // this should a redirect in the Router.jsx
      const activityLogRegex =
        /(opportunities|opportunity-detail)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\/activitylog/i;

      switch (true) {
        case activityLogRegex.test(location.pathname): {
          return 'activity-log';
        }
        case location.pathname?.includes('/engagements'): {
          return 'engagements';
        }
        case location.pathname?.includes('/talent'): {
          return 'talent';
        }
        default: {
          return 'profile';
        }
      }
    };

    return (
      <Tabs startTab={getStartTab()} fluid scrollable stretched variant="card">
        <Tab
          name="profile"
          label="Details"
          onClick={() => {
            goToTab('profile');
          }}
        >
          <Layout background="panelLight">
            <LayoutItem fluid>
              <Gutter horizontal="16px">
                <OpportunityProfileDetailForm
                  form="editOpportunity"
                  initialValues={opportunityValues}
                  isDetail={isDetail}
                  isEnvoySalesUser={user.roles.includes('EnvoySales')}
                  opportunity={opportunity}
                />
              </Gutter>
            </LayoutItem>
          </Layout>
        </Tab>
        <Tab
          name="talent"
          label="Talent"
          countBadge={
            (editOpportunityTalentTabState?.data?.candidates ?? []).some(
              ({ candidateStatus }) =>
                !opportunity.isClosed &&
                [
                  CandidateOpportunitiesConst.CandidateStatuses.Interested,
                  CandidateOpportunitiesConst.OppCandMlRecStatuses.AiSuggested,
                ].includes(candidateStatus)
            ) || undefined
          }
          onClick={() => {
            goToTab('talent');
          }}
        >
          <Layout background="panelLight">
            <LayoutItem fluid>
              <OpportunityTalent />
            </LayoutItem>
          </Layout>
        </Tab>
        <Tab
          name="activity-log"
          label="Activity Log"
          onClick={() => {
            goToTab('activitylog');
          }}
        >
          <Layout stretched>
            <LayoutItem fluid stretched>
              <Gutter horizontal="16px">
                <ActivityLog eventsData={eventsData} positions={positions} />
              </Gutter>
            </LayoutItem>
          </Layout>
        </Tab>
        <Tab
          name="engagements"
          label="Engagements"
          countBadge={engagementsCount || undefined}
          onClick={() => {
            goToTab('engagements');
          }}
        >
          <Layout background="panelLight">
            <LayoutItem fluid>
              <Gutter horizontal="16px">
                <OpportunityEngagements
                  engagementsCount={engagementsCount}
                  opportunity={opportunity}
                />
              </Gutter>
            </LayoutItem>
          </Layout>
        </Tab>
      </Tabs>
    );
  }

  render() {
    const {
      state: { searchTerm },
      props: {
        expanded,
        isDetail,
        opportunityState,
        opportunityEngagementsState,
        opportunityPositionsState,
        eventsState,
        editOpportunityTalentTabState,
      },
    } = this;

    return (
      <StoreStateTreatment
        name="active_opportunity"
        stretched
        storeState={[
          opportunityState,
          opportunityEngagementsState,
          opportunityPositionsState,
          eventsState,
        ]}
        renderNonLoadedView={() => (
          <JobProfileNoItems
            data-test={formatDataTestId('no selected opportunity')}
          >
            Please select an opportunity from the list.
          </JobProfileNoItems>
        )}
        renderLoadedView={({
          data: [opportunity, engagements, positions, eventsData],
        }) => {
          if (expanded) {
            WindowUtil.title(opportunity.jobName);
          }
          return (
            <>
              <SubmissionModal
                opportunity={opportunity}
                readOnly={OpportunityUtils.isClosed(opportunity)}
              />
              <OpportunityProfileHeader
                opportunity={opportunity}
                isDetail={isDetail}
                searchTerm={searchTerm}
              />
              <Gutter vertical="16px" />
              <Routes>
                {[`/:view/:viewId/*`, `/:view`, '/'].map(p => (
                  <Route
                    key={p}
                    path={p}
                    element={this.renderTabs(
                      engagements,
                      eventsData,
                      positions,
                      editOpportunityTalentTabState
                    )}
                  />
                ))}
              </Routes>
            </>
          );
        }}
      />
    );
  }
}

OpportunityDetails.defaultProps = {
  expanded: false,
  isDetail: false,
  // connect
  opportunity: null,
  searchTerm: null,
  user: null,
};

OpportunityDetails.propTypes = {
  expanded: bool,
  isDetail: bool,
  // connect
  opportunity: opportunityShape,
  searchTerm: string,
  user: PreloadedUserStore.getDataShape(),
  opportunityState: OpportunityStore.getStateShape().isRequired,
  opportunityEngagementsState:
    OpportunityEngagementsStore.getStateShape().isRequired,
  opportunityPositionsState:
    OpportunityPositionsStore.getStateShape().isRequired,
  eventsState: EventsStore.getStateShape().isRequired,
  editOpportunityTalentTabState:
    EditOpportunityTalentTabStore.getStateShape().isRequired,
  router: shape(WithRouterShape).isRequired,
};

export default withRouter(
  connect(makeOpportunitiesViewSelector)(OpportunityDetails)
);
