import {
  CANDIDATE_COLLABORATORS_LOAD,
  CANDIDATE_COLLABORATORS_LOADED,
  CANDIDATE_COLLABORATORS_LOAD_ERROR,
} from '../constants';

export const INITIAL_STATE = {
  byTalentId: {},
  loading: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CANDIDATE_COLLABORATORS_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case CANDIDATE_COLLABORATORS_LOADED: {
      return {
        ...state,
        byTalentId: {
          ...state.byTalentId,
          [payload.id]: payload.data,
        },
        loading: false,
      };
    }
    case CANDIDATE_COLLABORATORS_LOAD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
