/**
 * Sapling is a third party SaaS vendor.  Sapling provides spelling and grammar checking capabilities in
 * Axiom Platform UI elements.
 *
 * The Axiom vendor assessment process has not approved Sapling to process sensitive data regarding individuals.
 * As such, this file controls the UI text entry fields where Sapling is enabled.  Only fields explicitly listed in
 * this file will have Sapling enabled.
 *
 * Access to edit this file is restricted.  Axiom employees granted permission to edit this file must ensure that
 * Sapling is only enabled on UI text entry fields  where its use is approved.
 *
 * Sapling is approved to process text that would be visible to the public or a client:
 * - Talent bio text is approved, such as experience descriptions and bio summaries.
 * - Client-facing submission text is approved, such as why Talent Success sees the talent as a fit for the client
 *    need or why the talent sees themselves as a fit for the client need.
 *
 * Sapling is not approved to process text that would be considered Axiom internal or potentially contain sensitive
 * personal information or protected health information.
 * - AHQ Activity Notes are not approved
 * - AHQ Availability Note is not approved
 * - Talent feedback on clients is not approved
 * - Client feedback on talents is not approved
 *
 * Additional use cases may be approved, but only through consultation with Axiom’s General Counsel (Ashlin Quirk).
 *
 * Format for adding new fields: <application-component-fieldName>
 */
export const GdprApprovedFields = [
  'portal-ProfileSummaryEdit-candidateSummary',
  'portal-WorkFeedConfirmInterestModal-roleFitDescription',
  'portal-WorkFeedDirectExpressionModal-roleFitDescription',
  'portal-ExperienceItemNonAxiomEdit-description',
  'portal-ExperienceItemAxiomEdit-description',
] as const;

export type GdprApprovedFieldType = (typeof GdprApprovedFields)[number];
