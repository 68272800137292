import Ajv from 'ajv';
import { z } from 'zod';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { PositionSchema } from './positions';
import { OpportunitySchema } from './opportunity';
import { CandidateSchema } from './candidate';

const CompensationValues = Object.values(
  CandidateOpportunitiesConst.CompensationTypes
) as NonEmptyArray<string>;

export const BaseCandidateOpportunitySchema = z.object({
  annualSalary: z.number().nonnegative().nullable(),
  baseHourlyCompensation: z.number().nonnegative().nullable(),
  calculatedDisplayName: z.string().nullable(),
  candidateAvailabilityNotes: z.string().nullable(),
  candidateBaseAnnualSalary: z.number().positive().nullable(),
  candidateBonusPercentage: z.number().nonnegative().nullable(),
  candidateExperienceNotes: z.string().nullable(),
  candidateExploringOutsideRoles: z.string().nullable(),
  candidateId: z.string().uuid(),
  candidateIsInterested: z.boolean().default(false),
  candidatePlannedTimeOffNotes: z.string().nullable(),
  candidateReasonForNo: z.string().max(255).nullable(),
  candidateStatus: CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
  candidateStatusMessage: z.string().nullable(),
  candidateStatusMessageDismissedAt: SchemaTimestamp.nullable(),
  candidateStatusUpdatedAt: SchemaTimestamp.nullable(),
  candidateWorkscheduleAnnualHours: z.number().nonnegative().nullable(),
  compensation: z.string().nullable(),
  compensationType: z.enum(CompensationValues).nullable(),
  createdAt: SchemaTimestamp,
  createdBy: z.string().uuid().nullable(),
  displayBillingRate: z.string().max(255).nullable(),
  exchangeRate: z.number().nonnegative().nullable(),
  hasBeenWarmed: z.boolean().nullable(),
  hasNoConflictOfInterest: z.boolean().nullable(),
  hourlyCompensation: z.string().nullable(),
  hourlyRate: z.number().nonnegative().nullable(),
  hourlyRateBurdened: z.number().nonnegative(),
  hourlySalary: z.number().nonnegative().nullable(),
  id: z.string().uuid(),
  isActiveForSubmission: z.boolean().nullable(),
  isCrossFulfilled: z.boolean().nullable(),
  lastUpdatedBy: z.string().uuid().nullable(),
  marginApprovalCeiling: z.number().nonnegative().nullable(),
  marginApprovalFloor: z.number().nonnegative().nullable(),
  marginFloorPct: z.number().nullable(),
  marginTargetPct: z.number().nullable(),
  marginUpperPct: z.number().nullable(),
  meetsMinPayRequirements: z.boolean().nullable(),
  openToLowerEsc: z.boolean().nullable(),
  openToLowerEscCompensationPercentage: z.number().nullable(),
  opportunityId: z.string().uuid(),
  position: PositionSchema.nullish(),
  positionId: z.string().uuid().nullable(),
  previousCandidateStatus:
    CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
  previousPositiveCandidateStatus:
    CandidateOpportunitiesConst.ZodCandidateStatuses.nullable(),
  productBurdenRateMultiple: z.number().nonnegative().nullable(),
  productPremiumRateMultiple: z.number().nonnegative().nullable(),
  proposedCostTarget: z.number().nonnegative().nullable(),
  proposedHourlyCompensation: z.number().nonnegative().nullable(),
  proposedHourlyRate: z.number().nonnegative().nullable(),
  proposedMarginTarget: z.number().nullable(),
  rejectionLossCode:
    CandidateOpportunitiesConst.ZodCandidateRejectionLossCodes.nullable(),
  rejectionLossCodeCustomMessage: z.string().nullable(),
  salesforceQuoteLineId: z.string().max(255).nullable(),
  submissionDescription: z.string().nullable(),
  submissionRank: z.number().nonnegative().nullable(),
  updatedAt: SchemaTimestamp.nullable(),
});

export const CandidateOpportunitySchema = BaseCandidateOpportunitySchema.pick({
  annualSalary: true,
  baseHourlyCompensation: true,
  calculatedDisplayName: true,
  candidateAvailabilityNotes: true,
  candidateBaseAnnualSalary: true,
  candidateBonusPercentage: true,
  candidateExperienceNotes: true,
  candidateExploringOutsideRoles: true,
  candidateId: true,
  candidateIsInterested: true,
  candidateStatus: true,
  candidateStatusMessage: true,
  candidateStatusUpdatedAt: true,
  compensation: true,
  compensationType: true,
  createdAt: true,
  displayBillingRate: true,
  exchangeRate: true,
  hasBeenWarmed: true,
  hasNoConflictOfInterest: true,
  hourlyCompensation: true,
  hourlyRate: true,
  hourlyRateBurdened: true,
  hourlySalary: true,
  id: true,
  isActiveForSubmission: true,
  lastUpdatedBy: true,
  marginApprovalCeiling: true,
  marginApprovalFloor: true,
  marginFloorPct: true,
  marginTargetPct: true,
  marginUpperPct: true,
  meetsMinPayRequirements: true,
  openToLowerEsc: true,
  openToLowerEscCompensationPercentage: true,
  opportunityId: true,
  position: true,
  positionId: true,
  previousCandidateStatus: true,
  previousPositiveCandidateStatus: true,
  productBurdenRateMultiple: true,
  productPremiumRateMultiple: true,
  proposedCostTarget: true,
  proposedHourlyCompensation: true,
  proposedHourlyRate: true,
  proposedMarginTarget: true,
  rejectionLossCode: true,
  rejectionLossCodeCustomMessage: true,
  submissionDescription: true,
  submissionRank: true,
}).extend({
  candidate: CandidateSchema.pick({
    id: true,
    calculatedDisplayName: true,
    profileImageKey: true,
    yearsOfExperience: true,
  }),
  badge: z.string().nullable(),
});

export const OpportunityCandidateSchema = OpportunitySchema.merge(
  BaseCandidateOpportunitySchema
);

const ajv = new Ajv(axiomValidationOptions());
const {
  CandidateRejectionLossCodes,
  CandidateStatuses,
  DisplayBillingRate,
  CompensationTypes,
  ReasonForNo,
} = CandidateOpportunitiesConst;

const candidateOpportunitiesValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateStatus: {
      type: 'string',
      enum: Object.values(CandidateStatuses),
    },
    candidateStatusMessage: {
      type: ['string', 'null'],
      maxLength: 1000,
    },
    endDate: { type: 'string', format: 'date' },
    candidateReasonForNo: {
      type: ['string', 'null'],
      enum: Object.values(ReasonForNo),
    },
    candidateAvailabilityNotes: {
      type: ['string', 'null'],
      maxLength: 1000,
    },
    candidateExperienceNotes: {
      type: ['string', 'null'],
      maxLength: 1000,
    },
    compensationType: {
      type: ['string', 'null'],
      enum: Object.values(CompensationTypes),
    },
    experiences: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        additionalProperties: false,
        properties: {
          id: {
            type: 'string',
            format: 'uuid',
          },
          isHighlighted: {
            type: ['boolean', 'null'],
          },
          experienceVisible: {
            type: 'boolean',
          },
          submissionDescription: {
            type: ['string', 'null'],
          },
        },
      },
    },
    displayBillingRate: {
      type: ['string', 'null'],
      enum: Object.keys(DisplayBillingRate),
    },
    rejectionLossCode: {
      type: ['string', 'null'],
      enum: Object.values(CandidateRejectionLossCodes),
    },
    rejectionLossCodeCustomMessage: {
      type: ['string', 'null'],
    },
    positionId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    proposedHourlyCompensation: {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
    proposedHourlyRate: {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
    proposedMarginTarget: {
      type: 'number',
      maximum: Number.MAX_SAFE_INTEGER,
    },
    salesforceQuoteLineId: {
      type: ['string', 'null'],
      maxLength: 255,
    },
    startDate: {
      type: ['string', 'null'],
      format: 'date',
    },
    hasNoConflictOfInterest: {
      type: ['boolean', 'null'],
    },
    candidateExploringOutsideRoles: {
      type: ['string', 'null'],
      maxLength: 1000,
    },
    candidatePlannedTimeOffNotes: {
      type: ['string', 'null'],
      maxLength: 1000,
    },
    candidateStatusMessageDismissedAt: {
      type: ['string', 'null'],
      format: 'date-time',
    },
  },
};

export const candidateOpportunitiesValidator = ajv.compile({
  ...candidateOpportunitiesValidation,
  anyOf: Object.keys(candidateOpportunitiesValidation.properties).map(key => ({
    required: [key],
  })),
});
