import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const ContextMenuItem = ({
  children,
  className,
  disabled,
  name,
  onClick,
  selected,
}: {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onClick?: () => void;
  selected?: boolean;
}) => {
  return (
    <Dropdown.Item
      className={AttrsHelper.formatClassname(
        'context-menu-item',
        `type-item`,
        className
      )}
      disabled={disabled}
      data-test={name}
      onClick={onClick}
      selected={selected}
    >
      {children}
    </Dropdown.Item>
  );
};
