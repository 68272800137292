import pick from 'lodash/pick';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { addApiError } from '../actions/app';
import { USER_GROUPS_LOAD, SET_USER_GROUPS, UPDATE_USER } from '../constants';
import { USER_MODEL_EDITABLE_PROPERTIES } from '../../models/user';
import { addGroupUser, removeGroupUser } from '../../api/groups';
import { getUserGroups, updateUser } from '../../api/users-api';
import { groupUserAddedError, loadGroups } from '../actions/groups';
import {
  updateUserSuccess,
  updateUserError,
  userGroupsLoaded,
  userGroupsError,
} from '../actions/users';
import { usersByIdSelector } from '../selectors/users';

// TODO: remove
export function* loadUserGroupsSagaGenerator({ payload: user }) {
  try {
    const response = yield call(getUserGroups, user);
    yield put(userGroupsLoaded({ user, teams: response.data }));
  } catch (e) {
    yield put(userGroupsError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* setUserGroupsSagaGenerator({ payload: form }) {
  try {
    const groups = yield select(state => state.groups.byId);

    const oldGroups = Object.keys(groups).reduce((accum, groupId) => {
      const userIndex = groups[groupId].users.indexOf(form.id);
      if (userIndex !== -1) {
        accum.push(groupId);
      }
      return accum;
    }, []);

    const { id: userId, team: newGroups } = form;

    const toAdd = newGroups.filter(id => !oldGroups.includes(id));
    const toRemove = oldGroups.filter(id => !newGroups.includes(id));

    const actions = [
      ...toAdd.map(groupId => call(addGroupUser, groupId, userId)),
      ...toRemove.map(groupId => call(removeGroupUser, groupId, userId)),
    ];

    yield all(actions);
    yield put(loadGroups());
  } catch (e) {
    yield put(groupUserAddedError(e));
    yield put(addApiError(e.applicationError));
  }
}

export function* updateUserSagaGenerator({ payload: form }) {
  try {
    const usersById = yield select(usersByIdSelector());
    const user = usersById[form.id];

    const safeValues = pick(form, USER_MODEL_EDITABLE_PROPERTIES);
    safeValues.id = form.id;

    // This field goes to a different endpoint, and will cause
    // a server-side validation error if submitted here.
    delete safeValues.team;

    if (safeValues.roles !== undefined && !Array.isArray(safeValues.roles)) {
      safeValues.roles = [safeValues.roles];
    }

    const updatedUser = yield call(updateUser, safeValues);
    yield put(updateUserSuccess({ user: { ...user, ...updatedUser.data } }));
  } catch (e) {
    yield put(updateUserError(e));
    yield put(addApiError(e.applicationError));
  }
}

function* usersSaga() {
  yield takeLatest(USER_GROUPS_LOAD, loadUserGroupsSagaGenerator);
  yield takeLatest(SET_USER_GROUPS, setUserGroupsSagaGenerator);
  yield takeLatest(UPDATE_USER, updateUserSagaGenerator);
}

export default usersSaga;
