import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const BarredLocationSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255).nullable(),
  addressState: z.string().max(255).nullable(),
  addressCountry: z.string().max(255).nullable(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
});

const barredLocationValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
    addressState: {
      type: ['string', 'null'],
    },
    addressCountry: {
      type: ['string', 'null'],
    },
  },
  required: ['name'],
};

export const updateBarredLocationValidation = {
  type: 'object',
  additionalProperties: false,
  properties: barredLocationValidation.properties,
  anyOf: Object.keys(barredLocationValidation.properties).map(key => ({
    required: [key],
  })),
};

const barredLocationQueryValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    names: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};

export const barredLocationsValidator = ajv.compile(barredLocationValidation);
export const barredLocationsQueryValidator = ajv.compile(
  barredLocationQueryValidation
);
export const updateBarredLocationsValidator = ajv.compile(
  updateBarredLocationValidation
);
