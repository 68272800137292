import { string, number, shape, arrayOf } from 'prop-types';
import { CandidatesConst } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { getTalents } from '../api/talent';
import {
  addCandidatesToOpportunity,
  addCandidatesToPosition,
  updateOpportunityCandidate,
} from '../api/opportunities-legacy-api';
import { postOpportunitiesByTalentId } from '../api/talentOpportunities';
import { CandidateUtil } from '../utils/candidate-util';

import { OpportunityStore } from './opportunity-store';

const {
  Certifying,
  InDiligence,
  Waitlist,
  PendingActive,
  Active,
  PendingBeach,
  Beach,
  Idle,
} = CandidatesConst.ProfileStatuses;

class AppFindCandidateForOppModalStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      opportunityIds: arrayOf(string.isRequired),
      reloadOpportunityId: string,
      positionId: string,
      positions: arrayOf(
        shape({
          id: string.isRequired,
          candidates: arrayOf(shape({})),
        })
      ),
      searchTerm: string,
      results: arrayOf(shape({})),
      totalResultsCount: number,
      page: number,
    });
  }

  beginAddingToOpportunity(opportunityIds, reloadOpportunityIdOnSubmit) {
    return this.setState({
      opportunityIds: opportunityIds || [],
      reloadOpportunityId: reloadOpportunityIdOnSubmit,
      positionId: null,
      positions: null,
      searchTerm: '',
      results: [],
      totalResultsCount: 0,
    });
  }

  beginAddingToPosition(opportunityId, opportunityPositions, positionId) {
    return this.setState({
      opportunityIds: [opportunityId],
      reloadOpportunityId: opportunityId,
      positionId: positionId || null,
      positions: Array.isArray(opportunityPositions)
        ? opportunityPositions.map(p => ({
            id: p.id,
            candidates: p.candidates || [],
          }))
        : null,
      searchTerm: '',
      results: [],
      totalResultsCount: 0,
    });
  }

  findCandidates(searchTerm, storeData) {
    if (searchTerm.length > 1) {
      return this.setState(
        getTalents({
          filters: {
            axiomEmployeeType:
              CandidateUtil.getCandidatesListFilterDefaults().axiomEmployeeType,
            /**
             * Product wants this to be a different set than the default statuses we use to find candidates
             */
            profileStatus: [
              Certifying,
              InDiligence,
              Waitlist,
              PendingActive,
              Active,
              PendingBeach,
              Beach,
              Idle,
            ],
          },
          search: searchTerm,
          page: storeData.page,
        }).then(data => ({
          searchTerm,
          page: data.meta.currentPage,
          results: [...storeData.results, ...(data.data || [])],
          totalResultsCount: data.meta.resultCount,
        }))
      );
    }

    return null;
  }

  isCandidateInPosition(positions, candidateId) {
    return (
      Array.isArray(positions) &&
      positions.some(p => p.candidates.find(pc => pc.id === candidateId))
    );
  }

  submit(storeData, candidateId) {
    if (candidateId && storeData.opportunityIds.length > 0) {
      const { opportunityIds, positions, reloadOpportunityId, positionId } =
        storeData;

      let promise;

      switch (true) {
        case opportunityIds.length > 1: {
          promise = postOpportunitiesByTalentId({
            opportunityIds,
            candidateId,
          });
          break;
        }
        case !positionId: {
          promise = addCandidatesToOpportunity({
            opportunityId: opportunityIds[0],
            candidateIds: [candidateId],
          });
          break;
        }
        case this.isCandidateInPosition(positions, candidateId): {
          promise = updateOpportunityCandidate({
            opportunityId: opportunityIds[0],
            candidateId,
            positionId,
          });
          break;
        }
        default: {
          promise = addCandidatesToPosition({
            opportunityId: opportunityIds[0],
            candidateIds: [candidateId],
            positionId,
          });
        }
      }
      return this.clearState(promise).then(() => {
        if (reloadOpportunityId && positionId) {
          return OpportunityStore.updateBurdenedCost(
            reloadOpportunityId,
            candidateId
          );
        } else if (reloadOpportunityId) {
          return OpportunityStore.load(reloadOpportunityId);
        }
        return null;
      });
    }
    return null;
  }
}

export const AppFindCandidateForOppModalStore =
  new AppFindCandidateForOppModalStoreClass();
