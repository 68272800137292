import { useApi } from '@axiom/ui';
import { useParams, useLocation } from 'react-router-dom';
import {
  Calendar,
  Candidate,
  OpportunityPosition,
  OpportunityRedux,
} from '@axiom/validation';

import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { CalendarApi } from '../../api/calendar-api';

export const useTalentInterviewAvailabilityModalData = (
  candidate: Candidate
): {
  opportunity: OpportunityRedux;
  position: OpportunityPosition;
  calendarData: Calendar;
} => {
  const { id } = useParams();
  const location = useLocation();
  const isOppLocation = location.pathname.includes('/opp');
  const shouldCallOppApi = isOppLocation && id ? true : null;

  const [
    { data: opportunity } = { data: {} },
    { data: positions } = { data: [] },
    { data: calendarData } = { data: {} },
  ] = useApi(
    shouldCallOppApi && OpportunityLegacyApi.readOpportunity(id),
    shouldCallOppApi && OpportunityLegacyApi.readOpportunityPositions(id),
    candidate.calendar?.id && CalendarApi.readCalendar(candidate.calendar.id)
  );

  const position =
    positions.find(pos => {
      return (
        pos.opportunityId === opportunity.id &&
        pos.candidates.find(cand => cand.candidateId === candidate.id)
      );
    }) || {};

  return {
    opportunity,
    position,
    calendarData,
  };
};
