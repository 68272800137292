export const normalizedAvailabilityPreferences = talent => {
  const availPrefsKeys = [
    'isOpenToFullTime',
    'isOpenToPartTime',
    'isOpenToAdHoc',
    'isOpenToRemote',
    'isOpenToCommute',
    'partTimeHoursMon',
    'partTimeHoursTue',
    'partTimeHoursWed',
    'partTimeHoursThu',
    'partTimeHoursFri',
    'daysRequiredRemote',
    'daysDesiredRemote',
    'daysWillingToCommute',
    'maxMinutesCommuteOneWay',
  ];

  const isAvailPrefsKey = key => availPrefsKeys.includes(key);
  const shouldNormalize = Object.entries(talent).some(
    ([key, value]) => isAvailPrefsKey(key) && value !== null
  );
  if (shouldNormalize) {
    const state = availPrefsKeys.reduce(
      // eslint-disable-next-line no-return-assign, no-sequences
      (innerState, key) => ((innerState[key] = talent[key]), innerState),
      {}
    );
    if (state.isOpenToFullTime) {
      state.isOpenToPartTime = state.isOpenToPartTime || false;
      state.isOpenToAdHoc = state.isOpenToAdHoc || false;
    } else {
      state.partTimeHoursMon = state.partTimeHoursMon || 0;
      state.partTimeHoursTue = state.partTimeHoursTue || 0;
      state.partTimeHoursWed = state.partTimeHoursWed || 0;
      state.partTimeHoursThu = state.partTimeHoursThu || 0;
      state.partTimeHoursFri = state.partTimeHoursFri || 0;
    }
    if (state.isOpenToRemote) {
      state.daysRequiredRemote = state.daysRequiredRemote || 0;
      state.daysDesiredRemote = state.daysDesiredRemote || 0;
    }
    if (state.isOpenToCommute) {
      state.daysWillingToCommute = state.daysWillingToCommute || 1;
      if (typeof state.maxMinutesCommuteOneWay !== 'number') {
        state.maxMinutesCommuteOneWay = 30;
      }
    }
    return state;
  }

  return {};
};
