import React, { useState, useEffect, useRef, useMemo } from 'react';
import { arrayOf, string, func, bool, oneOf } from 'prop-types';
import { CaretIcon, CheckboxIcon, CloseIcon, Label } from '@axiom/ui';
import debounce from 'lodash/debounce';

import { formatDataTestId } from '../../utils/dataTest';
import {
  toThreeLayerDropdownTree,
  toTwoLayerDropdownTree,
} from '../../utils/dropdown-tree-utils';
import { DropdownTreeShape } from '../../models/dropdown-options';

import {
  DTCategoryLabel,
  DTCategoryLabelContainer,
  DTCheckbox,
  DTContainer,
  DTDeleteAllSelected,
  DTDeleteAndOpen,
  DTDropdownCarrot,
  DTDropdownContainer,
  DTExpandCollapsCarrot,
  DTExpandCollapsContainer,
  DTHiddenContent,
  DTInputPlaceholder,
  DTItemDelete,
  DTNoOptions,
  DTSearchContainer,
  DTSearchInput,
  DTSelectedItem,
  DTSelectedItemContainer,
  DTSelectedSearchContainer,
  DTShowSelectedContainer,
  DTTreeContainer,
  DTTreeGradiant,
  DTTreeItem,
  DTTreeItemContainer,
  DTTreeText,
} from './DropdownTreeStyles';

const CONTAINER_ID = `DROPDOWN_${Math.round(Math.random() * 10000)}`;
const INPUT_DEFAULT_WIDTH = '1rem';
const SORT_BY = ['trunk', 'branch', 'name'];

const DropdownTree = ({
  options,
  selectedValues,
  placeholder,
  onChangeAction,
  label,
  name,
  disabled,
  onDropdownVisibilityChange,
  allowParentAsValue,
  treeLayers,
}) => {
  const refStoredSearchText = useRef(null);
  const refDisplaySearchText = useRef(null);
  const [fullDataSet, setFullDataSet] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTrunks, setSelectedTrunks] = useState({});
  const [expanded, setExpanded] = useState({});
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [inputWidth, setInputWidth] = useState(INPUT_DEFAULT_WIDTH);

  const outSideClick = e => {
    if (showDropdown) {
      let isDropDown = false;
      let currentDom = e.target;

      for (let i = 0; i < 15; i += 1) {
        if (!currentDom || currentDom.tagName === 'BODY') break;
        if (currentDom.dataset.container === CONTAINER_ID) {
          isDropDown = true;
          break;
        }

        currentDom = currentDom.parentNode;
      }

      if (!isDropDown) {
        // eslint-disable-next-line no-use-before-define
        clearSearchFields();
        // eslint-disable-next-line no-use-before-define
        search('');
        // eslint-disable-next-line no-use-before-define
        triggerDropdown();
      }
    }
  };

  const getCalculatedWidth = dom => dom.clientWidth || dom.offsetWidth;

  const sortBy = (a, b, sortProp, next = 0) => {
    const prop = sortProp[next];

    if (!a[prop] || !b[prop]) return 0;

    if (a[prop] > b[prop]) return 1;
    if (a[prop] < b[prop]) return -1;

    next += 1;

    return sortBy(a, b, sortProp, next);
  };

  const restructure = data => {
    const used = new Set();
    const tree = [];

    for (const item of data) {
      const key = item.trunk + item.branch;

      // Dirty fix to handle 2 layer dropdown trees
      // otherwise a null key will be created
      if (!item.trunk && !item.branch) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // We do not want to generate the tree from branches
      // The tree is generated from the leaves which contains
      // a refrence to their parent branch
      if (item.branchId === item.id) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (used.has(key)) {
        for (let g = tree.length - 1; g >= 0; g -= 1) {
          const treeItem = tree[g];

          if (treeItem.trunk + treeItem.branch === key) {
            tree[g].items[tree[g].items.length] = {
              id: item.id,
              name: item.name,
              trunk: item.trunk,
              branch: item.branch,
            };

            break;
          }
        }
      } else {
        used.add(key);

        tree[tree.length] = {
          trunk: item.trunk,
          branch: item.branch,
          id: item.branchId,
          items: [
            {
              id: item.id,
              name: item.name,
              trunk: item.trunk,
              branch: item.branch,
            },
          ],
        };
      }
    }

    return tree;
  };

  const memoSelected = useMemo(() => {
    const selected = {};
    Object.keys(selectedTrunks).forEach(key => {
      const trunk = selectedTrunks[key];
      const { items } = trunk;

      if (allowParentAsValue && trunk.selected) {
        selected[trunk.id] = trunk.selected;
      }

      items.forEach(item => {
        if (item.selected) {
          selected[item.id] = item.selected;
        }
      });
    });
    return selected;
  }, [selectedTrunks, allowParentAsValue]);

  const hasSelected = () => Object.keys(memoSelected).length > 0;

  useEffect(() => {
    if (showDropdown) window.addEventListener('click', outSideClick);

    return () => window.removeEventListener('click', outSideClick);
  }, [showDropdown, outSideClick]);

  useEffect(() => {
    if (options.length > 0 && fullDataSet.length === 0) {
      let sortedData = [];

      if (treeLayers === 2) {
        sortedData = toTwoLayerDropdownTree(options).sort((a, b) =>
          sortBy(a, b, SORT_BY)
        );
      } else if (treeLayers === 3) {
        sortedData = toThreeLayerDropdownTree(options).sort((a, b) =>
          sortBy(a, b, SORT_BY)
        );
      }

      setFullDataSet(sortedData);
      setDataSet(restructure(sortedData));
    }
  }, [options, sortBy, fullDataSet, treeLayers]);

  useEffect(() => {
    if (fullDataSet.length > 0) {
      const preTrunkSelected = {};

      fullDataSet.forEach(data => {
        // Pre-selected trunks should only be generated by the children, since
        // they contain information on their branch and trunk.
        // Otherwise unnecessary trunks will be created.
        if (data.trunk || data.branch) {
          const trunkKey = data.trunk + data.branch;
          if (!preTrunkSelected[trunkKey]) {
            preTrunkSelected[trunkKey] = {
              items: [],
              selected: selectedValues.includes(data.branchId),
              id: data.branchId,
            };
          }

          preTrunkSelected[trunkKey].items.push({
            id: data.id,
            selected: selectedValues.includes(data.id),
          });

          if (!allowParentAsValue) {
            preTrunkSelected[trunkKey].selected = preTrunkSelected[
              trunkKey
            ].items.every(tItem => tItem.selected === true);
          }
        }
      });

      setSelectedTrunks(preTrunkSelected);
    }
  }, [selectedValues, fullDataSet, allowParentAsValue]);

  const clearSearchFields = () => {
    refDisplaySearchText.current.value = '';

    setInputWidth(INPUT_DEFAULT_WIDTH);
    setInputHasFocus(false);
  };

  const triggerExpand = key => {
    setExpanded({
      ...expanded,
      [key]: !expanded[key],
    });
  };

  const afterSelect = data => {
    const toSave = Object.keys(data).reduce((aryParent, tkey) => {
      const trunk = data[tkey];

      if (allowParentAsValue && trunk.selected) {
        aryParent.push({ value: trunk.id });
      }

      const values = trunk.items.reduce((ary, item) => {
        if (item.selected) ary.push({ value: item.id });

        return ary;
      }, []);

      return [...aryParent, ...values];
    }, []);

    clearSearchFields();
    onChangeAction(toSave);
  };

  // Only triggers during selection of a child option
  // or removal of option from input field
  const triggerSelect = (parentKey, item) => {
    const selected = memoSelected;
    const isSelected = !selected[item.id];
    const trunkData = {
      ...selectedTrunks,
    };
    let trunk = trunkData[parentKey];

    // If selected item is a child option
    if (item.branch) {
      trunk.items.forEach(tItem => {
        if (tItem.id === item.id) {
          tItem.selected = isSelected;
        }
      });
      if (!allowParentAsValue) {
        trunk.selected = trunk.items.every(tItem => tItem.selected === true);
      }
    } else {
      // selected option is a parent item
      trunk = trunkData[item.trunk + item.name];
      trunk.selected = isSelected;
    }

    setSelectedTrunks(trunkData);
    afterSelect(trunkData);
  };

  // Only triggers during selection of a parent option
  const triggerSelectTrunk = trunkData => {
    const key = trunkData.trunk + trunkData.branch;
    const isSelected = !selectedTrunks[key].selected;
    const data = {
      ...selectedTrunks,
      [key]: {
        items: selectedTrunks[key].items.map(item => ({
          id: item.id,
          selected: allowParentAsValue ? item.selected : isSelected,
        })),
        selected: isSelected,
        id: selectedTrunks[key].id,
      },
    };

    setSelectedTrunks(data);
    afterSelect(data);
  };

  const filterBy = term => {
    const data = fullDataSet.filter(item =>
      (item.trunk + item.branch + item.name)
        .toLowerCase()
        .includes(term.toLowerCase())
    );

    setExpanded({});
    setDataSet(restructure(data.sort((a, b) => sortBy(a, b, SORT_BY))));
  };

  const search = term => {
    const dom = refStoredSearchText.current;
    // eslint-disable-next-line unicorn/prefer-dom-node-text-content
    dom.innerText = term;
    const newWidth = `${getCalculatedWidth(dom) + 1}px`;
    setInputWidth(newWidth);

    debounce(filterBy, 300)(term);
  };

  const removeAllSelected = () => {
    const trunks = {};
    Object.keys(selectedTrunks).forEach(tKey => {
      const trunk = selectedTrunks[tKey];

      trunk.selected = false;
      trunk.items.forEach(item => {
        item.selected = false;
      });

      trunks[tKey] = trunk;
    });

    setSelectedTrunks(trunks);
    onChangeAction([]);
  };

  const toggleDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false);
      onDropdownVisibilityChange();
    } else {
      setInputHasFocus(true);
      refDisplaySearchText.current.focus();
    }
  };

  const triggerDropdown = () => {
    onDropdownVisibilityChange();
    clearSearchFields();
    search('');
    setShowDropdown(!showDropdown);
  };

  const openDropdown = () => {
    if (!showDropdown) {
      onDropdownVisibilityChange();
      setShowDropdown(true);
    }
  };

  const buildSelected = () => {
    const items = [];
    const selected = memoSelected;

    if (!hasSelected()) return null;

    fullDataSet.forEach(data => {
      if (selected[data.id]) {
        items.push(
          <DTSelectedItemContainer
            key={data.id}
            onClick={() =>
              !disabled && triggerSelect(`${data.trunk}${data.branch}`, data)
            }
            data-test={formatDataTestId('selected_item')}
            data-value={data.name}
          >
            <DTSelectedItem title={data.name}>{data.name}</DTSelectedItem>
            {!disabled && (
              <DTItemDelete>
                <CloseIcon width={9} height={9} />
              </DTItemDelete>
            )}
          </DTSelectedItemContainer>
        );
      }
    });

    return items;
  };

  const formatTrunkText = (trunk, branch) => {
    return trunk ? `${trunk} > ${branch}` : branch;
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <DTContainer
        data-container={CONTAINER_ID}
        data-test={formatDataTestId(name || label || 'dropdown_tree')}
      >
        {disabled ? (
          <DTShowSelectedContainer>{buildSelected()}</DTShowSelectedContainer>
        ) : (
          <>
            <DTSelectedSearchContainer>
              <DTShowSelectedContainer
                onClick={toggleDropdown}
                data-test={formatDataTestId('selected_Container')}
              >
                {buildSelected()}
                <DTSearchContainer>
                  {!hasSelected() && (
                    <DTInputPlaceholder
                      style={{ display: showDropdown ? 'none' : 'block' }}
                    >
                      {placeholder || 'Select...'}
                    </DTInputPlaceholder>
                  )}
                  <DTSearchInput
                    ref={refDisplaySearchText}
                    type="text"
                    name={name}
                    onFocus={openDropdown}
                    onChange={e => search(e.target.value)}
                    data-test={formatDataTestId('search_input')}
                    style={{
                      position: inputHasFocus ? 'initial' : 'absolute',
                      width: inputWidth,
                    }}
                  />
                  <DTHiddenContent ref={refStoredSearchText} />
                </DTSearchContainer>
              </DTShowSelectedContainer>
            </DTSelectedSearchContainer>
            <DTDeleteAndOpen>
              {hasSelected() && (
                <DTDeleteAllSelected
                  onClick={removeAllSelected}
                  data-test={formatDataTestId('delete_All_Selected')}
                >
                  <CloseIcon height={11} width={11} />
                </DTDeleteAllSelected>
              )}
              <DTDropdownCarrot
                onClick={triggerDropdown}
                data-test={formatDataTestId('dropdown_Carrot')}
              >
                <CaretIcon direction="down" height={11} width={11} />
              </DTDropdownCarrot>
            </DTDeleteAndOpen>
            <DTDropdownContainer visible={showDropdown}>
              {showDropdown && (
                <DTTreeContainer data-test={formatDataTestId('dropdown')}>
                  {dataSet && dataSet.length > 0 ? (
                    dataSet.map(d => {
                      const key = d.trunk + d.branch;
                      const isTrunkSelected = selectedTrunks[key].selected;
                      const trunkItems = selectedTrunks[key].items;

                      return (
                        <DTTreeItemContainer
                          key={key}
                          data-value={formatTrunkText(d.trunk, d.branch)}
                        >
                          <DTCategoryLabelContainer
                            data-test={formatDataTestId('expand_Parent')}
                          >
                            <DTCheckbox
                              onClick={() => triggerSelectTrunk(d)}
                              checked={isTrunkSelected}
                              data-test={formatDataTestId('Parent_Checkbox')}
                            >
                              <CheckboxIcon checked={isTrunkSelected} />
                            </DTCheckbox>
                            <DTCategoryLabel
                              data-test={formatDataTestId('Parent_Label')}
                            >
                              <DTTreeText>
                                {formatTrunkText(d.trunk, d.branch)}
                              </DTTreeText>
                            </DTCategoryLabel>
                            <DTExpandCollapsContainer
                              onClick={() => triggerExpand(key)}
                              data-test={formatDataTestId('expand_Collapse')}
                            >
                              <DTExpandCollapsCarrot expand={expanded[key]} />
                            </DTExpandCollapsContainer>
                          </DTCategoryLabelContainer>
                          {expanded[key] && (
                            <DTTreeContainer
                              data-test={formatDataTestId('expanded_Children')}
                            >
                              {d.items.map(itm => {
                                const itemSelected = trunkItems.find(
                                  item => item.id === itm.id
                                ).selected;

                                return (
                                  <DTTreeItemContainer
                                    key={itm.id}
                                    onClick={() => triggerSelect(key, itm)}
                                    data-test={formatDataTestId(
                                      'expanded_Child'
                                    )}
                                    data-value={itm.name}
                                  >
                                    <DTTreeItem>
                                      <DTCheckbox
                                        checked={itemSelected}
                                        data-test={formatDataTestId(
                                          'expanded_Child_Checkbox'
                                        )}
                                      >
                                        <CheckboxIcon checked={itemSelected} />
                                      </DTCheckbox>
                                      <DTTreeText
                                        data-test={formatDataTestId(
                                          'expanded_Child_Text'
                                        )}
                                      >
                                        {itm.name}
                                      </DTTreeText>
                                    </DTTreeItem>
                                  </DTTreeItemContainer>
                                );
                              })}
                              <DTTreeGradiant direction="top" />
                              <DTTreeGradiant direction="bottom" />
                            </DTTreeContainer>
                          )}
                        </DTTreeItemContainer>
                      );
                    })
                  ) : (
                    <DTNoOptions data-test={formatDataTestId('no_Options')}>
                      No options
                    </DTNoOptions>
                  )}
                </DTTreeContainer>
              )}
            </DTDropdownContainer>
          </>
        )}
      </DTContainer>
    </>
  );
};

DropdownTree.defaultProps = {
  placeholder: null,
  label: null,
  name: null,
  disabled: false,
  onDropdownVisibilityChange: () => null,
  allowParentAsValue: false,
  treeLayers: 3,
};

DropdownTree.propTypes = {
  options: DropdownTreeShape.isRequired,
  selectedValues: arrayOf(string).isRequired,
  onChangeAction: func.isRequired,
  placeholder: string,
  label: string,
  name: string,
  disabled: bool,
  onDropdownVisibilityChange: func,
  allowParentAsValue: bool,
  treeLayers: oneOf([2, 3]),
};

export default DropdownTree;
