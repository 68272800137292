import { TopTask, TopTaskTypes } from '@axiom/validation';
import moment from 'moment';

import { TimeCopyUtil } from './time-copy-util';

export const TopTasksUtil = {
  getTopTaskBadgeColor: (date: string) => {
    return moment() > moment(date) ? 'dataVizRed' : 'dataVizYellow';
  },

  getTopTaskLink: (task: TopTask) => {
    if (task.topTaskType === TopTaskTypes.TIME_TO_SUBMISSION) {
      return `/opportunity-detail/${task.opportunityId}/talent`;
    }
    return `/talent-detail/${task.candidateId}`;
  },

  getTopTaskCopy: (task: TopTask) => {
    let daysOpen: number;
    let daysCopy: string;

    switch (task.topTaskType) {
      case TopTaskTypes.CHECK_IN: {
        return {
          header: `${TimeCopyUtil.getDaysCopy(
            Math.floor(
              moment
                .duration(moment().diff(moment(task.taskExpectedStartDateTime)))
                .as('days')
            )
          ).toLocaleLowerCase()} since last check in`,
          description: task.candidateCalculatedDisplayName,
        };
      }

      case TopTaskTypes.IDLE_REMINDER: {
        daysOpen = Math.floor(
          moment
            .duration(
              moment().diff(
                moment(task.taskExpectedCompletionDateTime).add(8, 'days')
              )
            )
            .as('days')
        );
        daysCopy = TimeCopyUtil.getDaysCopy(daysOpen);
        daysCopy =
          daysOpen <= 0
            ? TimeCopyUtil.getDaysCopy(Math.abs(daysOpen)).toLowerCase()
            : TimeCopyUtil.getDaysCopy(daysOpen).toLowerCase();
        return {
          header: `${daysCopy} ${daysOpen < 0 ? 'until ' : ''}idle`,
          description: task.candidateCalculatedDisplayName,
        };
      }

      default: {
        return {
          header: `Submission needed - Open ${TimeCopyUtil.getHoursOrDaysCopyFromNumberOfHours(
            TimeCopyUtil.getHoursDifferenceExcludingWeekends(
              task.taskExpectedStartDateTime,
              moment().format()
            )
          ).toLowerCase()}`,
          description: task.opportunityJobName,
        };
      }
    }
  },
};
