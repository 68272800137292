import { z } from 'zod';

export const TaxonomyIndustrySchema = z.object({
  displayValue: z.string(),
});
export const TaxonomyLegalTechCategorySchema = z.object({
  displayName: z.string(),
});
export const TaxonomyLegalTechSkillSchema = z.object({
  displayName: z.string(),
  legalTechSkillCategory: z.string(),
});
export const TaxonomyGeneralSkillSchema = z.object({
  displayName: z.string(),
});
export const TaxonomyLegalSkillSchema = z.object({
  skillGroup: z.string(),
  talentName: z.string(),
  alpEligible: z.boolean(),
  fullName: z.string(),
  shortName: z.string(),
});
export const TaxonomyPracticeAreaSchema = z.object({
  fullName: z.string(),
  talentName: z.string(),
  shortName: z.string(),
  alpEligible: z.boolean(),
});
export const TaxonomyFocusAreaSchema = z.object({
  talentName: z.string(),
  practiceArea: z.string(),
  fullName: z.string(),
  shortName: z.string(),
  alpEligible: z.boolean(),
});
export const TaxonomySkillGroupSchema = z.object({
  clientQuestion: z.string(),
  fullName: z.string(),
  talentQuestion: z.string(),
  focusArea: z.string(),
  alpEligible: z.boolean(),
});

export const TaxonomySchema = z.object({
  generalSkills: z.record(z.string(), TaxonomyGeneralSkillSchema),
  industries: z.record(z.string(), TaxonomyIndustrySchema),
  legalSkills: z.record(z.string(), TaxonomyLegalSkillSchema),
  legalTechSkillCategories: z.record(
    z.string(),
    TaxonomyLegalTechCategorySchema
  ),
  legalTechSkills: z.record(z.string(), TaxonomyLegalTechSkillSchema),
  practiceAreas: z.record(z.string(), TaxonomyPracticeAreaSchema),
  focusAreas: z.record(z.string(), TaxonomyFocusAreaSchema),
  skillGroups: z.record(z.string(), TaxonomySkillGroupSchema),
});
