import { z } from 'zod';
import { Opportunity } from '@axiom/validation';
import { Api } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';

export type readUserOpportunityResponseType = {
  data: Array<Opportunity>;
  meta: {
    currentPage: number;
    pageCount: number;
    resultCount: number;
    aggregations: number;
  };
};

export const WorkspaceTypes = {
  accountManagerOwnerOrSalesLead: 'Owned by me',
  collaborator: 'Collaborating on',
} as Record<string, string>;

const WorkspaceTypeValues = Object.keys(
  WorkspaceTypes
) as NonEmptyArray<string>;

export const WorkspaceStages = {
  needsFulfilment: 'Needs Fulfilment (Stage 0b - 2)',
  fulfilled: 'Fulfilled (Stage 3)',
  all: 'All',
} as Record<string, string>;

export const WorkspaceFilters = z.object({
  stage: z.array(z.string()),
  type: z.enum(WorkspaceTypeValues),
  sort: z.string().nullish(),
  order: z.string().nullish(),
  isFulfillmentActive: z.boolean().nullish(),
});

export type WorkspaceFilterType = z.infer<typeof WorkspaceFilters>;

class UserOpportunityApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readUserOpportunities(
    ownerUserId: string,
    page: number,
    { type, sort, stage, order, isFulfillmentActive }: WorkspaceFilterType
  ) {
    const filterOwnerType = Object.keys(WorkspaceTypes).find(workspaceType => {
      return WorkspaceTypes[workspaceType] === type;
    });
    return super.read<readUserOpportunityResponseType>({
      endpoint: `/users/${ownerUserId}/opportunities/search`,
      method: 'POST',
      body: {
        page,
        filters: {},
        isFulfillmentActive,
        order,
        sort,
        assigneeType: filterOwnerType,
        stageCode: stage,
      },
      encodeQuery: false,
    });
  }
}

export const UserOpportunityApi = new UserOpportunityApiClass();
