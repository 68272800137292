import { WindowUtil } from './window-util';

export const GoogleTagManagerUtil = {
  newEvent(event, options) {
    if (WindowUtil.exists()) {
      if (!window.dataLayer) window.dataLayer = [];
      window.dataLayer.push({
        event,
        ...options,
      });
    }
  },
  getEvents() {
    if (window?.dataLayer) return window.dataLayer;

    return [];
  },
};
