import { Opportunity } from '@axiom/validation';
import moment from 'moment';

import { TimeCopyUtil } from './time-copy-util';

export const TimeToSubmissionCopyUtil = (opportunity: Opportunity): string => {
  if (
    !opportunity.firstCandidateSubmittedAt &&
    opportunity.firstCandidateSelectedOrFurtherAt
  ) {
    return '';
  }
  if (opportunity.timeToSubmission.hours < 0) {
    return '';
  }
  if (opportunity.timeToSubmission.hours === 1) {
    return '1 Hr';
  }
  if (
    opportunity.timeToSubmission.hours === 0 &&
    moment(opportunity.qualifiedAt) >
      moment(opportunity.firstCandidateSubmittedAt)
  ) {
    return '';
  }
  return TimeCopyUtil.getHoursOrDaysCopyFromNumberOfHours(
    opportunity.timeToSubmission.hours
  );
};
