import React, { useEffect, useState } from 'react';
import { string, shape, func, bool, node } from 'prop-types';
import { Label } from '@axiom/ui';

import RadioButton from '../RadioButton/RadioButton';
import Input from '../Input/Input';
import UnorderedList from '../UnorderedList/UnorderedList';
import { formatDataTestId } from '../../utils/dataTest';

import {
  SubmissionModalDataTitleWrapper,
  SubmissionModalRateInputWrapper,
} from './SubmissionModalStyles';

const proposedRadioNames = {
  proposedHourlyCompensation: 'proposedHourlyCompensation',
  proposedMarginTarget: 'proposedMarginTarget',
  proposedHourlyRate: 'proposedHourlyRate',
};

export const SubmissionModalProposedRateInput = ({
  selectedProposedRadio,
  candidate,
  propLabel,
  propName,
  appendRadio,
  onProposedRadioSelected,
  onProposedInputChanged,
  readOnly,
}) => {
  const [inputValue, setInputValue] = useState(candidate[propName]);

  useEffect(() => {
    if (Number.parseFloat(inputValue) !== candidate[propName]) {
      setInputValue(candidate[propName]);
    }
  }, [candidate[propName]]);

  return (
    <SubmissionModalRateInputWrapper>
      <UnorderedList
        title={
          <SubmissionModalDataTitleWrapper>
            {readOnly ? (
              <Label name={`RADIO_${propName}`}>{propLabel}</Label>
            ) : (
              <>
                <RadioButton
                  labelSide="right"
                  label={propLabel}
                  data-test={formatDataTestId(`RADIO_${propName}`)}
                  name={`submissionModel${[propName]}Radio`}
                  domID={`submissionModel${[propName]}Radio`}
                  checked={
                    selectedProposedRadio === proposedRadioNames[propName]
                  }
                  value={proposedRadioNames[propName]}
                  onChange={({ target: { value } }) =>
                    onProposedRadioSelected(value)
                  }
                />
                {appendRadio}
              </>
            )}
          </SubmissionModalDataTitleWrapper>
        }
        values={
          <Input
            type="number"
            step="0.01"
            skipNormalize
            disabled={
              selectedProposedRadio === proposedRadioNames[propName] || readOnly
            }
            name={proposedRadioNames[propName]}
            value={inputValue}
            onWheel={e => e.target.blur()}
            onChange={event => {
              setInputValue(event.target.value);
              onProposedInputChanged(
                proposedRadioNames[propName],
                event,
                candidate
              );
            }}
          />
        }
      />
    </SubmissionModalRateInputWrapper>
  );
};
SubmissionModalProposedRateInput.propTypes = {
  appendRadio: node,
  selectedProposedRadio: string.isRequired,
  propName: string.isRequired,
  propLabel: node.isRequired,
  candidate: shape({}).isRequired,
  onProposedRadioSelected: func.isRequired,
  onProposedInputChanged: func.isRequired,
  readOnly: bool.isRequired,
};

SubmissionModalProposedRateInput.defaultProps = {
  appendRadio: null,
};
