import { useEffect, useRef } from 'react';

/**
 * This hook only runs the callback after the
 * component mounts... aka NOT on mount
 *
 * useEffect() runs on mount regardless of the dependencies
 * this only runs if the dependencies change
 * */
export const useOnDependencyUpdate = (
  callback: () => void,
  dependencies: unknown[] = []
): void => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    callback();
  }, dependencies);
};
