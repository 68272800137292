import { GenericDto } from './generic-dto';

export const CandidateOpportunitiesDto = class CandidateOpportunitiesDto extends GenericDto {
  constructor(body) {
    super(body, [
      'candidateAvailabilityNotes',
      'candidateBonusPercentage',
      'candidateExperienceNotes',
      'candidateExploringOutsideRoles',
      'candidatePlannedTimeOffNotes',
      'candidateReasonForNo',
      'candidateStatus',
      'candidateStatusMessage',
      'candidateStatusMessageDismissedAt',
      'displayBillingRate',
      'endDate',
      'experiences',
      'hasNoConflictOfInterest',
      'positionId',
      'productBurdenRateMultiple',
      'proposedHourlyCompensation',
      'proposedHourlyRate',
      'proposedMarginTarget',
      'rejectionLossCode',
      'rejectionLossCodeCustomMessage',
      'salesforceQuoteLineId',
      'startDate',
    ]);
  }
};
