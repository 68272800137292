import Ajv from 'ajv';
import { z } from 'zod';
import { DailyCronSystemEventMap } from '@axiom/const';
import { SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';

export const SystemEventSchema = z.object({
  createdAt: SchemaTimestamp.nullable(),
  id: z.string().uuid(),
  name: z.string().max(255).nullable(),
  status: z.string().max(255).nullable(),
  type: z.string().max(255).nullable(),
  updatedAt: SchemaTimestamp.nullish(),
  value: z.string().min(1).max(255).nullable(),
});

const ajv = new Ajv(axiomValidationOptions());

const systemEventCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    type: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    status: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    value: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
  },
};

export const systemEventDailyJobValidator = ajv.compile({
  type: 'object',
  additionalProperties: false,
  properties: {
    systemEvents: {
      type: 'array',
      items: {
        enum: Object.values(DailyCronSystemEventMap).map(({ name }) => name),
        type: ['string'],
      },
    },
  },
  required: ['systemEvents'],
});

export const systemEventValidator = ajv.compile(systemEventCommonValidation);
