const CANDIDATE_FILTERS = {
  addressCountryCode: 'addressCountryCode',
  barredLocations: 'barredLocations',
  industries: 'industries',
  practiceArea: 'practiceArea',
  profession: 'profession',
  state: 'state',
  yearsOfExperience: 'yearsOfExperience',
};

export const CandidateAggregationsConst = {
  CANDIDATE_FILTERS,
  CANDIDATE_FILTER_AGGREGATIONS: {
    addressCountryCode: {
      filterName: CANDIDATE_FILTERS.addressCountryCode,
      size: 100,
    },
    barredLocations: {
      filterName: CANDIDATE_FILTERS.barredLocations,
      size: 100,
    },
    industries: { filterName: CANDIDATE_FILTERS.industries, size: 100 },
    profession: { filterName: CANDIDATE_FILTERS.profession },
    practiceArea: { filterName: CANDIDATE_FILTERS.practiceArea, size: 100 },
    state: { filterName: CANDIDATE_FILTERS.state, size: 100 },
    yearsOfExperience: {
      filterName: CANDIDATE_FILTERS.yearsOfExperience,
    },
  },
};
