const ProfileCompletionCriteria = {
  needsNativeLanguage: 'needsNativeLanguage',
  needsNonAxiomExperience: 'needsNonAxiomExperience',
  needsExperienceUpdate: 'needsExperienceUpdate',
  needsLegalTechSkills: 'needsLegalTechSkills',
  needsDegrees: 'needsDegrees',
  needsCertifications: 'needsCertifications',
  needsAcceptableSummary: 'needsAcceptableSummary',
  needsValidName: 'needsValidName',
  needsPracticeStartYear: 'needsPracticeStartYear',
  needsUpdatedPreferences: 'needsUpdatedPreferences',
};

const ProfileCompletionSummaryBanner = {
  emptyTitle: 'Write a profile summary',
  emptyContent:
    'Your summary is the first thing a client sees. Help them get to know you and your experience better. We recommend writing at least 200 characters. You have a maximum of 500 characters.',
  tooShortTitle: 'Improve your profile summary',
  tooShortContent:
    'Your summary is too short. We find that summaries are received best by clients when they are between 200-500 characters.',
};

const ProfileCompletionNameBanner = {
  allCaps: 'Use sentence case, not all caps, for your display name.',
  qualifications:
    'Remove qualifications (JD, MBA, LLM, etc.) from your display name.',
  titles: 'Remove the title (Esq., Mr., Mrs., etc.) from your display name.',
};

const ProfileCompletionExperienceIndustryBanner = {
  missingTitle: 'Add an Industry',
  missingContent:
    'We require an Industry for each experience. This helps Axiom find your profile and know what types of engagements to submit you for in the future.',
};

const ProfileCompletionLegalTechSkillsBanner = {
  missingTitle: 'Add legal tech skills',
  missingContent:
    'Some roles require proficiency with specific tools. Add your proficiency so we can match and promote you for the opportunities that fit your skillset.',
};

const ProfileCompletionAccomplishmentsBanner = {
  missingTitle: 'Add an accomplishment',
  missingContent:
    'Demonstrate your passion and help your profile stand out by adding certifications, awards, and qualifications.',
};

const ProfileCompletionLanguagesBanner = {
  missingTitle: 'Add a language',
  missingContent:
    'Add your primary language and any other languages you speak. Make sure to include proficiency, skill, and CEFR (as needed).',
};

const ProfileCompletionEducationBanner = {
  missingTitle: 'Complete your education section',
  missingContent:
    'Make sure that at least one degree is present for each level of education entered, and all fields are filled out.',
};

export const ProfileCompletionConst = {
  ProfileCompletionCriteria,
  ProfileCompletionExperienceIndustryBanner,
  ProfileCompletionLegalTechSkillsBanner,
  ProfileCompletionSummaryBanner,
  ProfileCompletionNameBanner,
  ProfileCompletionAccomplishmentsBanner,
  ProfileCompletionLanguagesBanner,
  ProfileCompletionEducationBanner,
  minimumAcceptableSummaryLength: 200,
} as const;
