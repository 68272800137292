import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import styled from 'styled-components';
import { Label } from '@axiom/ui';

import { FFError, isErroring } from '../FFError/FFError';
import { formatDataTestId } from '../../utils/dataTest';
import AutoComplete from '../AutoComplete/AutoComplete';
import { DropdownOptionsShape } from '../../models/dropdown-options';

const FFAutoCompleteWrapper = styled.div`
  color: ${props => (props.isDisabled ? '#d3d3d3' : 'inherit')};
  margin-bottom: 25px;
  width: 100%;
`;
const FFAutoCompleteLabelErrorWrapper = styled.div`
  display: flex;
`;
const FFAutoCompleteErrorWrapper = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;

export const FFAutoComplete = ({
  input,
  filterable,
  onFilterChange,
  isDisabled,
  label,
  meta,
  placeholder,
  className,
  options,
  onValueChange,
  listNoDataRender,
}) => {
  const handleChange = ({ value }) => {
    input.onChange(value);
    onValueChange(value);
  };

  return (
    <FFAutoCompleteWrapper
      className={className}
      isDisabled={isDisabled}
      data-test={formatDataTestId(
        input.name || label || 'autocomplete_wrapper'
      )}
    >
      <FFAutoCompleteErrorWrapper>
        {label && <Label htmlFor={input.name}>{label}</Label>}
      </FFAutoCompleteErrorWrapper>

      <AutoComplete
        name={input.name}
        value={input.value}
        onChange={handleChange}
        valid={!isErroring(meta)}
        options={options}
        disabled={isDisabled}
        placeholder={placeholder}
        type={input.type}
        filterable={filterable}
        onFilterChange={onFilterChange}
        listNoDataRender={listNoDataRender}
      />
      <FFAutoCompleteLabelErrorWrapper>
        <FFAutoCompleteErrorWrapper>
          <FFError finalFormElementMeta={meta} />
        </FFAutoCompleteErrorWrapper>
      </FFAutoCompleteLabelErrorWrapper>
    </FFAutoCompleteWrapper>
  );
};

FFAutoComplete.propTypes = {
  input: shape({}).isRequired,
  filterable: bool,
  onValueChange: func,
  isDisabled: bool,
  label: string,
  meta: shape({}).isRequired,
  className: string,
  options: DropdownOptionsShape,
  onFilterChange: func,
  placeholder: string,
  listNoDataRender: func,
};

FFAutoComplete.defaultProps = {
  isDisabled: false,
  filterable: false,
  label: null,
  className: null,
  listNoDataRender: undefined,
  options: [],
  placeholder: undefined,
  onValueChange: () => {},
  onFilterChange: () => {},
};
