import { shape, string, number, bool } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { CandidateOpportunityUtils } from '@axiom/utils';

import {
  refreshBurdenedCost,
  updateOpportunityCandidate,
} from '../api/opportunities-legacy-api';
import GenericStore from '../classes/generic-store';

import { OpportunityStore } from './opportunity-store';
import { EditSubmissionStore } from './edit-submission-store';

const { DisplayBillingRate } = CandidateOpportunitiesConst;

class EditSubmissionRateStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      candidateId: string,
      consultant1099UtilizationWarning: bool,
      billingHoursPerDay: number,
      opportunityId: string,
      proposedHourlyCompensation: number,
      proposedMarginTarget: number,
      proposedHourlyRate: number,
      displayBillingRate: string,
      meetsMinPayRequirements: bool,
      baseHourlyCompensation: number,
      productBurdenRateMultiple: number,
      candidateBonusPercentage: number,
      marginApprovalFloor: number,
      marginApprovalCeiling: number,
      marginFloorPct: number,
      marginTargetPct: number,
      marginUpperPct: number,
    });
  }

  load(submission, opportunityCandidate) {
    const proposedDefaults =
      CandidateOpportunityUtils.getDefaultProposedFromPosition(
        opportunityCandidate
      );

    const isRateSet =
      CandidateOpportunityUtils.hasSubmittedRate(opportunityCandidate);

    const formData = {
      candidateId: opportunityCandidate.id,
      position: opportunityCandidate.position,
      hourlyRateBurdened: opportunityCandidate.hourlyRateBurdened,
      opportunityId: opportunityCandidate.opportunityId,
      submissionId: submission.submissionId,
      proposedMarginTarget: isRateSet
        ? opportunityCandidate.proposedMarginTarget
        : proposedDefaults.proposedMarginTarget,
      proposedHourlyRate: isRateSet
        ? opportunityCandidate.proposedHourlyRate
        : proposedDefaults.proposedHourlyRate,
      displayBillingRate: isRateSet
        ? opportunityCandidate.displayBillingRate
        : DisplayBillingRate.hourly,
      proposedHourlyCompensation: isRateSet
        ? opportunityCandidate.proposedHourlyCompensation
        : proposedDefaults.proposedHourlyCompensation,
      meetsMinPayRequirements: opportunityCandidate.meetsMinPayRequirements,
      consultant1099UtilizationWarning:
        opportunityCandidate.consultant1099UtilizationWarning,
      baseHourlyCompensation: opportunityCandidate.baseHourlyCompensation,
      productBurdenRateMultiple: opportunityCandidate.productBurdenRateMultiple,
      candidateBonusPercentage: opportunityCandidate.candidateBonusPercentage,
      marginApprovalFloor: opportunityCandidate.marginApprovalFloor,
      marginApprovalCeiling: opportunityCandidate.marginApprovalCeiling,
      marginFloorPct: opportunityCandidate.marginFloorPct,
      marginTargetPct: opportunityCandidate.marginTargetPct,
      marginUpperPct: opportunityCandidate.marginUpperPct,
    };
    return this.setState(formData);
  }

  getBurdenedCost(opportunityId, candidateId, submissionId) {
    return this.watchState(
      refreshBurdenedCost(opportunityId, candidateId)
    ).then(() => {
      OpportunityStore.load(opportunityId);
      EditSubmissionStore.load(submissionId, opportunityId, candidateId, true);
    });
  }

  save(saveData) {
    const {
      proposedHourlyCompensation,
      proposedMarginTarget,
      proposedHourlyRate,
      displayBillingRate,
      opportunityId,
      candidateId,
    } = saveData;

    const compensationType = CandidateOpportunityUtils.getCompensationType(
      saveData,
      saveData.position
    );

    return this.watchState(
      updateOpportunityCandidate({
        proposedHourlyCompensation,
        proposedMarginTarget,
        proposedHourlyRate,
        displayBillingRate,
        compensationType,
        opportunityId,
        candidateId,
      })
    ).then(() => {
      OpportunityStore.load(saveData.opportunityId);
    });
  }
}

export const EditSubmissionRateStore = new EditSubmissionRateStoreClass();
