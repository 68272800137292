import React from 'react';
import get from 'lodash/get';
import { bool, shape, func, string, oneOfType, arrayOf } from 'prop-types';

import { StateComponentUtil } from '../../utils/state-component-util';
import LoadingGradient from '../LoadingGradient/LoadingGradient';
import { ErrorBlurb } from '../ErrorBlurb/ErrorBlurb';
import { ComponentShape } from '../../utils/prop-type-util';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { formatDataTestId } from '../../utils/dataTest';
import { GenericStoreStructure } from '../../classes/generic-store';

import { StoreStateTreatmentWrapper } from './StoreStateTreatmentStyles';

const StoreStateTreatment = ({
  storeState,
  renderLoadedView,
  renderNonLoadedView,
  initialLoader,
  refetchLoader,
  showInitialLoader,
  showRefetchLoader,
  blockingErrorComponent,
  dataId,
  dataIdName,
  isModalState,
  name,
  allowOverflow,
  stretched,
}) => {
  const states = Array.isArray(storeState) ? storeState : [storeState];

  if (states.length > 1 && dataId) {
    throw new Error(
      'You cannot watch for multiple states and declare a dataId'
    );
  }
  /**
   * Need this console log, else it'll get swallowed.
   */
  if (
    StateComponentUtil.shouldShowBlockingError(states) ||
    StateComponentUtil.shouldShowNonBlockingError(states)
  ) {
    states.forEach(({ errors }) => {
      if (
        !(
          Array.isArray(errors) &&
          errors.some(error => Object.keys(error).length > 0)
        )
      ) {
        // eslint-disable-next-line no-console
        console.warn(errors);
      }
    });
  }

  let componentOwnsState = true;
  if (dataIdName && get(storeState, `data.${dataIdName}`) !== dataId) {
    componentOwnsState = false;
  }

  /**
   * We always want to do fullscreen loaders if a modal because of weird position: relatives
   * places throughout the app on the parent components containing the StoreStateTreatment component
   */
  if (!initialLoader) {
    initialLoader = isModalState ? (
      <LoadingCurtain isFullscreen />
    ) : (
      <LoadingGradient lines={3} />
    );
  }

  if (!refetchLoader) {
    refetchLoader = isModalState ? (
      <LoadingCurtain isFullscreen />
    ) : (
      <LoadingCurtain />
    );
  }

  /**
   * Non Blocking errors end up in app toast so we're just gonna do null here
   */
  return (
    <StoreStateTreatmentWrapper
      data-test={formatDataTestId(name)}
      data-ready={!StateComponentUtil.isLoading(states)}
      allowOverflow={allowOverflow}
      stretched={stretched}
    >
      {componentOwnsState && (
        <>
          {StateComponentUtil.shouldShowBlockingError(states) &&
            blockingErrorComponent}
          {StateComponentUtil.shouldShowNonBlockingError(states) && null}
          {StateComponentUtil.shouldShowInitialLoader(states) &&
            showInitialLoader &&
            initialLoader}
          {StateComponentUtil.shouldShowBlockingLoader(states) &&
            showRefetchLoader &&
            refetchLoader}
          {StateComponentUtil.shouldShowComponent(states) &&
            renderLoadedView({
              data:
                states.length === 1
                  ? states[0].data
                  : states.map(state => state.data),
              meta: storeState.meta,
            })}
        </>
      )}
      {(!componentOwnsState ||
        (!StateComponentUtil.isLoading(states) &&
          !StateComponentUtil.hasLoaded(states))) &&
        renderNonLoadedView()}
    </StoreStateTreatmentWrapper>
  );
};

/**
 * dataId - Useful if you have a StoreStateTreatment for each item in a map that hooks up to the same store
 * This will tell the component to only renderLoadedView if the dataId is what's in the data object for the write store
 * dataIdName lets you change to a diff prop like candidateId if you don't have an id prop
 */

/*
 * allowOverflow [bool]
 * There are situations where the default (false), overflow:hidden, will clip things like a dropdown or context menus
 * Please be careful when setting this to (true) this as it can have dire consequences that are hard to debug.
 * The reason overflow is set to hidden in the first place is that the grid and side drawer depend on this being the
 * default value.
 */

StoreStateTreatment.propTypes = {
  storeState: oneOfType([
    arrayOf(shape(GenericStoreStructure).isRequired),
    shape(GenericStoreStructure).isRequired,
  ]),
  name: string,
  renderLoadedView: func,
  renderNonLoadedView: func,
  initialLoader: ComponentShape,
  refetchLoader: ComponentShape,
  blockingErrorComponent: ComponentShape,
  showInitialLoader: bool,
  showRefetchLoader: bool,
  dataId: string,
  dataIdName: string,
  isModalState: bool,
  allowOverflow: bool,
  stretched: bool,
};

StoreStateTreatment.defaultProps = {
  storeState: {},

  renderLoadedView: ({ data, meta, errors }) => null,
  renderNonLoadedView: () => null,
  initialLoader: null,
  refetchLoader: null,
  blockingErrorComponent: <ErrorBlurb />,
  showInitialLoader: true,
  showRefetchLoader: true,
  isModalState: false,
  name: 'StoreStateTreatment',
  dataId: null,
  dataIdName: null,
  allowOverflow: false,
  stretched: false,
};

export default StoreStateTreatment;
