import GenericStore from './generic-store';

export default class FormStore extends GenericStore {
  validate() {
    throw new Error(
      'Make sure to add the function validate() in your store class'
    );
  }

  cancel() {
    return this.clearState();
  }
}
