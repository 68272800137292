import moment from 'moment';
import { oneOfType, string, instanceOf } from 'prop-types';

const monthOptions = moment.months().map((name, index) => ({
  label: name,
  value: String(index), // react-select doesn't like numeric 0
}));

export const DatePropType = oneOfType([
  string,
  instanceOf(Date),
  instanceOf(moment),
]);

export const DateUtil = {
  castAsMoment(date) {
    if (date instanceof moment) {
      return date;
    }
    if (date instanceof Date) {
      return moment(date);
    }

    if (typeof date === 'string') {
      if (/^\d\d\d\d-\d\d-\d\d$/.test(date)) {
        return moment(date, 'YYYY-MM-DD');
      }
      if (/^\d\d\d\d-\d\d-\d\dT.+$/.test(date)) {
        return moment(date);
      }
    }

    return moment(date);
  },
  getDaysSinceNow(pastDate) {
    return moment().diff(DateUtil.castAsMoment(pastDate), 'days'); // returns integer
  },
  getDaysFromNow(futureDate) {
    return DateUtil.castAsMoment(futureDate).diff(
      moment().startOf('day'),
      'days'
    ); // returns integer
  },
  addDaysToNow(daysToAdd) {
    return moment().startOf('day').add(daysToAdd, 'd').format('YYYY-MM-DD'); // 2019-02-12
  },
  getMonthOptions() {
    return monthOptions;
  },
  getYearOptionsByRange(startYear, endYear) {
    if (!Number.isInteger(startYear) || !Number.isInteger(endYear)) {
      throw new Error('Start and End years must be numbers');
    }

    const isPastToFuture = startYear < endYear;

    return Array.from({ length: Math.abs(startYear - endYear) + 1 })
      .fill(null)
      .map((obj, index) => {
        const year = isPastToFuture ? startYear + index : startYear - index;

        return {
          label: year,
          value: year,
        };
      });
  },
  /**
   * Returns the following structure and order
   * [
   *  { label: "2019", value: "2019"}
   *  { label: "2020", value: "2020"}
   *  { label: "2021", value: "2021"}
   * ]
   */
  getYearOptionsByAmount(
    allowCurrentYear = false,
    yearsIntoFuture = 0,
    yearsIntoPast = 0
  ) {
    const yearOptions = [];
    const currentYear = moment().year();

    if (yearsIntoPast > 0) {
      yearsIntoPast *= -1;
    }

    for (let i = yearsIntoPast; i <= yearsIntoFuture; i++) {
      const yearOption = currentYear + i;

      if (
        yearOption !== currentYear ||
        (yearOption === currentYear && allowCurrentYear)
      ) {
        yearOptions.push({
          label: yearOption,
          value: yearOption,
        });
      }
    }

    return yearOptions;
  },
  formatAsDate(date) {
    return date ? DateUtil.castAsMoment(date).format('YYYY-MM-DD') : null; // 2019-02-12
  },
  formatAsTimestamp(date) {
    return date ? DateUtil.castAsMoment(date).toISOString() : null; // 2013-02-04T22:44:30.652Z
  },
  formatAsYear(date) {
    return date ? DateUtil.castAsMoment(date).format('YYYY') : null; // 2020
  },
  displayLongMonth(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM') : null; // January
  },
  displayShortDate(date) {
    return date ? DateUtil.castAsMoment(date).format('l') : null; // 2/2/22
  },
  displayDate(date) {
    return date ? DateUtil.castAsMoment(date).format('MM/DD/YY') : null; // 02/12/20
  },
  displayMonthYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MM/YYYY') : null; // 02/2019
  },
  displayLongMonthYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM YYYY') : null; // July 2019
  },
  displayLongMonthDayYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM D, YYYY') : null; // July 7, 2019
  },
  displayTimestamp(date) {
    return date ? DateUtil.castAsMoment(date).format('ddd. L LT') : null; // Fri. 07/12/2019 10:22 AM
  },
  displayDateTimestamp(date) {
    return date ? this.castAsMoment(date).format('MM/DD/YY - LT') : null; // 07/12/2019 10:22 AM
  },
};
