import { TokenConst } from '@axiom/const';
import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { enumValidatorCreator } from './general';

export const TokenSchema = z.object({
  createdAt: SchemaTimestamp.nullable(),
  expiresAt: SchemaTimestamp.nullable(),
  id: z.string().uuid(),
  policy: z.string().nullable(),
  updatedAt: SchemaTimestamp.nullable(),
  userId: z.string().uuid().nullable(),
});

const {
  ViewBioNoPasswordLogin,
  ViewBenchNoPasswordLogin,
  BenchUrlNoPasswordLogin,
} = TokenConst.statementActions;
const actionTypeWhitelist = [
  ViewBioNoPasswordLogin,
  ViewBenchNoPasswordLogin,
  BenchUrlNoPasswordLogin,
];

export const tokenActionValidator = enumValidatorCreator(actionTypeWhitelist);
