import memoize from 'lodash/memoize';

const toTree = memoize(list => {
  const arrayToTree = (nodes, parentId) => {
    const arrayToReturn = [];

    nodes.forEach(node => {
      if (node.parentId === parentId) {
        arrayToReturn.push({
          id: node.id,
          name: node.name,
          type: node.type,
          children: arrayToTree(nodes, node.id),
          parentId: node.parentId,
        });
      }
    });

    return arrayToReturn;
  };

  return arrayToTree(list, null);
});

const returnShape = (data, asDropdownOptions) => {
  if (asDropdownOptions) {
    return {
      label: data.name,
      value: data.id,
      type: data.type,
    };
  }

  return {
    id: data.id,
    name: data.name,
    type: data.type,
  };
};

export const toThreeLayerDropdownTree = list => {
  // return flat array identifying branch and trunk
  const toReturn = [];

  toTree(list).forEach(trunk => {
    trunk.children.forEach(branch => {
      toReturn.push(
        ...branch.children.map(item => ({
          id: item.id,
          name: item.name,
          type: item.type,
          trunk: trunk.name,
          branch: branch.name,
          branchId: branch.id,
        }))
      );
    });
  });

  return toReturn;
};

export const toTwoLayerDropdownTree = list => {
  const toReturn = [];

  toTree(list).forEach(parent => {
    toReturn.push(
      {
        branch: null,
        branchId: parent.id,
        id: parent.id,
        name: parent.name,
        trunk: null,
        type: parent.type,
      },
      ...parent.children.map(item => ({
        branch: parent.name,
        branchId: parent.id,
        id: item.id,
        name: item.name,
        trunk: null,
        type: item.type,
      }))
    );
  });

  return toReturn;
};

export const getTreeTrunk = (list, asDropdownOptions = false) => {
  return list
    .filter(data => !data.parentId)
    .map(data => returnShape(data, asDropdownOptions));
};

export const getTreeBranch = (
  list,
  parentId = '',
  asDropdownOptions = false
) => {
  return list
    .filter(data => data.parentId === parentId)
    .map(data => returnShape(data, asDropdownOptions));
};
