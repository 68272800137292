import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export const ContextMenuHeader = ({
  children,
  className,
  name,
}: {
  children: React.ReactNode;
  className?: string;
  name?: string;
}) => {
  return (
    <Dropdown.Header className={className} data-test={name}>
      {children}
    </Dropdown.Header>
  );
};
