import { arrayOf, number, shape, string } from 'prop-types';
import isEqual from 'lodash/fp/isEqual';

import {
  SavedFilterDefault,
  SavedFilterShapeStructure,
} from '../models/saved-filters';
import { WindowUtil } from '../utils/window-util';

import GenericStore from './generic-store';

export default class AbstractTableStore extends GenericStore {
  constructor({ filterShape, dataShape }) {
    super();

    this.filterShape = filterShape;
    this.dataShape = dataShape;

    /**
     * DO NOT REPLICATE THIS PATTERN
     * There is a wonky race condition this is a work around for
     * Thanks Redux!
     * */
    this.dataRequestCount = {
      called: 0,
      received: 0,
    };
  }

  formatMetaForRequest(meta) {
    throw new Error('Must override this function');
  }

  getResults(sendMeta) {
    throw new Error('Must override this function');
  }

  load(filters = {}, loadData) {
    return this._sendRequest(this._formatMeta(filters), loadData);
  }

  loadByConfig(data) {
    this.changeFilters(data.meta.searchConfig.filters);
    return this._sendRequest(data);
  }

  getFiltersFromState(state) {
    const meta = state?.data?.meta;

    if (meta) {
      const { searchConfig, sort } = meta;

      return {
        ...searchConfig.filters,
        ...(sort ? { sort } : {}),
      };
    }
  }

  shouldUpdateQueryParams(filters, defaultSort) {
    const defaultFilters = {
      ...this.getDefaultFilters(),
      ...(defaultSort ? { sort: defaultSort } : {}),
    };

    return filters && !isEqual(filters, defaultFilters);
  }

  loadByState(state, defaultSort) {
    const filters = this.getFiltersFromState(state);

    if (this.shouldUpdateQueryParams(filters, defaultSort)) {
      this.overrideFilters(filters, true);
    } else {
      this._sendRequest(this._formatMeta(this.getFilters()));
    }
  }

  _formatMeta(filters = {}) {
    return Object.keys(filters).reduce(
      (acc, propName) => {
        if (propName === 'sort') {
          acc.meta.sort = filters[propName];
        } else {
          acc.meta.searchConfig.filters[propName] = filters[propName];
        }

        return acc;
      },
      {
        meta: { searchConfig: SavedFilterDefault() },
      }
    );
  }

  _sendRequest(storeData, loadData) {
    if (!storeData.meta.searchConfig) {
      storeData.meta.searchConfig = SavedFilterDefault();
    }
    if (!storeData.meta.serverMeta) {
      storeData.meta.serverMeta = {};
    }

    const sendMeta = this.formatMetaForRequest(storeData.meta);

    this.dataRequestCount.called += 1;

    return this.setState(
      this.getResults(sendMeta, loadData).then(({ meta, data }) => {
        this.dataRequestCount.received += 1;

        return {
          meta: {
            ...storeData.meta,
            serverMeta: meta,
          },
          results: [...(storeData.results || []), ...(data || [])],
        };
      })
    );
  }

  getDataRequestCount() {
    return this.dataRequestCount;
  }

  loadNextPage(storeData) {
    if (
      storeData.meta.serverMeta.currentPage <
      storeData.meta.serverMeta.pageCount
    ) {
      const { sort, ...searchFilters } = this.getFilters();
      const { meta } = storeData;
      meta.searchConfig.filters = searchFilters;
      meta.serverMeta = {
        currentPage: storeData.meta.serverMeta.currentPage + 1,
      };
      this._sendRequest({
        meta,
        results: storeData.results,
      });
    }
  }

  getDefaultFilters() {
    return {};
  }

  getFilters() {
    return Object.keys(WindowUtil.getUrlQuery()).length > 0
      ? WindowUtil.getUrlQuery()
      : this.getDefaultFilters();
  }

  changeFilters(newFilters) {
    WindowUtil.setUrlQuery({ ...this.getFilters(), ...newFilters });
  }

  overrideFilters(filters, replace) {
    WindowUtil.setUrlQuery(filters, replace);
  }

  resetFilters() {
    this.overrideFilters(this.getDefaultFilters());
  }

  changeSort(sort) {
    this.changeFilters({ sort });
  }

  getSort() {
    return this.getFilters().sort || null;
  }

  changeOrder(order) {
    this.changeFilters({ order });
  }

  getTotalResults(data) {
    return data?.meta?.serverMeta?.resultCount || 0;
  }

  getMetaShape() {
    return shape({
      sort: string,
      order: string,
      searchConfig: this.getMetaConfigShape(),
      serverMeta: shape({
        currentPage: number,
        pageCount: number,
        resultCount: number,
      }),
    });
  }

  getMetaConfigShape() {
    return shape(SavedFilterShapeStructure(this.filterShape));
  }

  getDataShape() {
    return shape({
      meta: this.getMetaShape(),
      results: arrayOf(this.dataShape),
    });
  }
}
