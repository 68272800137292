import React, { ForwardedRef } from 'react';

type Props = {
  children: JSX.Element;
  className?: string;
  style?: { [key: string]: string };
};

// This component is only here to add in the data-test
const DayColumn = (
  { className, style, children }: Props,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <div className={className} style={style} ref={ref} data-test="DAY_COLUMN">
      {children}
    </div>
  );
};

export const CalendarDayColumn = React.forwardRef<HTMLDivElement, Props>(
  DayColumn
);
