import { AreasOfStudyCategoriesConst } from '@axiom/const';
import { z } from 'zod';

const { Categories } = AreasOfStudyCategoriesConst;

export const AreaOfStudySchema = z.object({
  id: z.string().uuid(),
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must contain at least 3 characters' })
    .max(50, { message: 'Name must be no more than 50 characters' }),
  category: z.enum(Object.keys(Categories) as NonEmptyArray<string>),
  isStem: z.boolean(),
});
