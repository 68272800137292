import React from 'react';

import { RawTextarea } from './RawTextarea';

type ReadonlyTextareaProps = {
  className?: string;
  name?: string;
  value?: string;
};

export const ReadonlyTextarea = ({
  className,
  name,
  value,
}: ReadonlyTextareaProps) => {
  return (
    <RawTextarea
      className={className}
      name={name}
      value={value}
      readonly
      stretched
    />
  );
};
