import Ajv from 'ajv';
import { z } from 'zod';
import {
  LanguageConst,
  LANGUAGE_PROFICIENCIES_KEYS,
  LANGUAGE_SKILLS_KEYS,
} from '@axiom/const';

import { axiomValidationOptions } from './options';
import { arrayValidatorCreator } from './general';
import { LanguageSchema } from './language';

const ProficiencyValues = Object.values(
  LANGUAGE_PROFICIENCIES_KEYS
) as NonEmptyArray<string>;

const SkillsValues = Object.values(
  LANGUAGE_SKILLS_KEYS
) as NonEmptyArray<string>;

const CEFRValues = Object.values(
  LanguageConst.LANGUAGE_CEFR_LEVELS
) as NonEmptyArray<string>;

export const CandidateLanguageSchema = z.object({
  id: z.string().uuid(),
  candidateId: z.string().uuid().nullable(),
  languageId: z.string().uuid().nullable(),
  language: LanguageSchema.nullish(),
  languageCEFR: z.enum(CEFRValues).nullish(),
  languageProficiency: z.enum(ProficiencyValues).nullable(),
  languageSkill: z.enum(SkillsValues).nullable(),
  name: z.string().nullable(),
});

export const CandidateLanguageFormSchema = CandidateLanguageSchema.pick({
  id: true,
  languageCEFR: true,
}).extend({
  languageProficiency: z.enum(ProficiencyValues), // make this required
  languageSkills: z
    .array(z.enum(SkillsValues))
    .nonempty({ message: 'Required' }),
});

export const CandidateLanguageUpdateSchema = CandidateLanguageSchema.pick({
  languageCEFR: true,
}).extend({
  languageId: z.string().uuid(),
  languageProficiency: z.enum(ProficiencyValues),
  languageSkill: z.enum(SkillsValues),
});

const ajv = new Ajv(axiomValidationOptions());

const candidateLanguageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    languageId: {
      type: 'string',
      format: 'uuid',
    },
    languageSkill: {
      type: ['string', 'null'],
    },
    languageProficiency: {
      type: 'string',
    },
    languageCEFR: {
      type: ['string', 'null'],
      enum: [...CEFRValues, null],
    },
  },
  required: ['languageProficiency', 'languageId'],
};

export const candidateLanguageValidator = ajv.compile(
  candidateLanguageValidation
);

export const candidateLanguageArrayValidator = arrayValidatorCreator(
  candidateLanguageValidation
);
