import { z } from 'zod';
import { TaxonomyConst } from '@axiom/const';

export const CandidateLegalTechSkillSchema = z.object({
  id: z.string().uuid(),
  legalTechCategory: z.string(),
  legalTechSkill: z.string(),
  proficiency: z.enum([
    TaxonomyConst.LegalTechProficiencies.HighProficiency,
    TaxonomyConst.LegalTechProficiencies.SomeFamiliarity,
    TaxonomyConst.LegalTechProficiencies.None,
  ]),
});
