import moment from 'moment';
import groupBy from 'lodash/groupBy';
import {
  ProfileCompletionConst,
  CandidateProfileIssueConst,
  CandidateOpportunitiesConst,
  PositionsConst,
  CandidatesConst,
} from '@axiom/const';
import {
  Candidate,
  CandidateOpportunity,
  CandidateProfileIssue,
  Experience,
} from '@axiom/validation';

export const CandidateProfileIssuesUtils = {
  hasPracticeStartYearIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .PracticeStartYear &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasValidNameIssues(issues: CandidateProfileIssue[] = []) {
    return this.getNameErrors(issues).length > 0;
  },

  getNameErrors(issues: CandidateProfileIssue[] = []) {
    const errors = [];

    // Check if there are too many capital letters in the name
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.AllCaps
      ) ??
      false
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.allCaps);
    }

    // Check for specified qualifiers, 3 or more consecutive capital letters are included
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Qualifications
      ) ??
      false
    ) {
      errors.push(
        ProfileCompletionConst.ProfileCompletionNameBanner.qualifications
      );
    }

    // Check if there are any titles in the name (title check is in beginning)
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Titles
      ) ??
      false
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.titles);
    }

    return errors;
  },

  hasSummaryOfAnyLengthIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasSummaryOfSufficientLengthIssues(issues: CandidateProfileIssue[] = []) {
    return (
      (issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.LengthShort
      ) &&
        !this.hasSummaryOfAnyLengthIssues(issues)) ??
      false
    );
  },

  hasNonAxiomExperienceIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.context ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .NonAxiomMissing
      ) ?? false
    );
  },

  hasExperienceWithMissingIndustryIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.context ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .MissingIndustry
      ) ?? false
    );
  },

  hasExperienceWithMissingIndustryIssue(
    issues: CandidateProfileIssue[] = [],
    experienceId: string
  ) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.context ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .MissingIndustry &&
          issue.entityId === (experienceId ?? '')
      ) ?? false
    );
  },

  hasAxiomExperienceWithBlankDescriptionIssues(
    issues: CandidateProfileIssue[] = []
  ) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Content &&
          issue.context ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .AxiomBlankDescription
      ) ?? false
    );
  },

  hasLegalTechSkillsIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .LegalTechSkills &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasDegreesIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Education &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasCertificationsIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .Accomplishments &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasNativeLanguageIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Languages &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasPreferencesIssues(issues: CandidateProfileIssue[] = []) {
    return this.getPreferencesIssues(issues).length > 0;
  },

  getPreferencesIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.filter(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Preferences &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Outdated
      ) ?? []
    );
  },

  filterExperienceSpecificIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.filter(
        ({ category, entityId }) =>
          category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          !!entityId
      ) ?? []
    );
  },

  hasExperienceSpecificIssues(issues: CandidateProfileIssue[] = []) {
    return this.filterExperienceSpecificIssues(issues).length > 0;
  },

  getExperienceIssues(
    issues: CandidateProfileIssue[] = [],
    experiences: Experience[] = []
  ) {
    const experiencesById = experiences.reduce(
      (acc, exp) => {
        acc[exp.id] = exp;
        return acc;
      },
      {} as Record<Experience['id'], Experience>
    );

    const experienceIssues = this.filterExperienceSpecificIssues(issues);
    const groupedByEntityId = groupBy(experienceIssues, 'entityId');

    return Object.keys(groupedByEntityId).map(id => {
      return {
        id,
        calculatedDisplayName: experiencesById[id].calculatedDisplayName,
        issues: groupedByEntityId[id],
      };
    });
  },

  hasProfileIssues(issues: CandidateProfileIssue[] = []) {
    return (
      this.hasValidNameIssues(issues) ||
      this.hasSummaryOfAnyLengthIssues(issues) ||
      this.hasSummaryOfSufficientLengthIssues(issues) ||
      this.hasExperienceSpecificIssues(issues) ||
      this.hasNonAxiomExperienceIssues(issues) ||
      this.hasLegalTechSkillsIssues(issues) ||
      this.hasDegreesIssues(issues) ||
      this.hasNativeLanguageIssues(issues) ||
      this.hasPracticeStartYearIssues(issues) ||
      this.hasPreferencesIssues(issues)
    );
  },

  shouldShowProfileCompletionSection(
    candidate: Candidate,
    candidateOpportunities: CandidateOpportunity[],
    issues: CandidateProfileIssue[]
  ) {
    const excludedByProfileStatus = [
      CandidatesConst.ProfileStatuses.NewLead,
      CandidatesConst.ProfileStatuses.Certifying,
      CandidatesConst.ProfileStatuses.Reservoir,
    ].includes(candidate.profileStatus);
    const hasRollOffWithinSixWeeks = candidateOpportunities.some(
      ({ candidateStatus, position }) =>
        candidateStatus ===
          CandidateOpportunitiesConst.CandidateStatuses.Engaged &&
        position.endDateStatus === PositionsConst.EndDateStatuses.Confirmed &&
        moment(position.endDate)
          .startOf('day')
          .isSameOrBefore(moment().add(6, 'weeks').startOf('day'))
    );
    return !!(
      !excludedByProfileStatus &&
      this.hasProfileIssues(issues) &&
      (candidate.weeklyAvailability ||
        hasRollOffWithinSixWeeks ||
        candidate.isAccessibleToWorkFeed)
    );
  },
};
