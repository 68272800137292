import { shape, string, arrayOf } from 'prop-types';
import { Opportunity, maxFiltersErrorMessage } from '@axiom/const';
import { identity, isEmpty, omitBy, pickBy } from 'lodash';

import { getOpportunities } from '../api/opportunities-legacy-api';
import { opportunityShape } from '../models/opportunities';
import { sanitizedElasticSearchQuery, countFilters } from '../utils/search';
import AbstractTableStore from '../classes/abstract-table-store';
import { WindowUtil } from '../utils/window-util';
import { EnvUtil } from '../utils/env-util';
import { encodeApiObjects } from '../api/APIHelper';

import { PreloadedAppErrorsStore } from './preloaded-app-errors-store';

export const OpportunitiesStoreMetaConst = {
  sort: {
    relevance: 'relevance',
    opportunityName: 'jobName', // TODO: Until the api allows for this to be opportunityName
    accountName: 'accountName',
    clientName: 'clientName',
    stageCode: 'stageCode',
    salesCloseDate: 'salesCloseDate',
    positions: 'positions',
    salesLead: 'salesLead',
  },
};

const ensureArrayContains = value => (value && value.length > 0 ? value : null);

class OpportunitiesStoreClass extends AbstractTableStore {
  getDefaultFilters() {
    return {
      stageCode: [
        Opportunity.STAGE_CODE_MAP[Opportunity.DEVELOP],
        Opportunity.STAGE_CODE_MAP[Opportunity.CRAFT],
        Opportunity.STAGE_CODE_MAP[Opportunity.COMPETE],
        Opportunity.STAGE_CODE_MAP[Opportunity.CONTRACT],
      ],
      sort: OpportunitiesStoreMetaConst.sort.relevance,
    };
  }

  getResults(sendMeta) {
    return getOpportunities(sendMeta);
  }

  changeFilters(filters) {
    const newLength = countFilters({ ...this.getFilters(), ...filters });
    if (newLength > 30) {
      PreloadedAppErrorsStore.show(maxFiltersErrorMessage);
    } else {
      super.changeFilters(filters);
    }
  }

  formatMetaForRequest(meta) {
    const searchFilters = meta.searchConfig.filters || {};
    const query = {
      page: meta.serverMeta.currentPage || 1,
      search: sanitizedElasticSearchQuery(searchFilters.search),
      sort: meta.sort,
      order: ensureArrayContains(searchFilters.order),
      filters: {
        stageCode: ensureArrayContains(searchFilters.stageCode),
        businessTeam: ensureArrayContains(searchFilters.businessTeam),
        ownerUserId: ensureArrayContains(searchFilters.ownerUserId),
        salesLeadId: ensureArrayContains(searchFilters.salesLeadId),
        practiceAreaId: ensureArrayContains(searchFilters.practiceAreaId),
        'positions.tags': ensureArrayContains(searchFilters.skills),
        salesCloseDate: (() => {
          if (
            !searchFilters.salesCloseDateStart &&
            !searchFilters.salesCloseDateEnd
          ) {
            return null;
          }

          const salesDateFilter = {};
          if (searchFilters.salesCloseDateStart) {
            salesDateFilter.start = searchFilters.salesCloseDateStart;
          }
          if (searchFilters.salesCloseDateEnd) {
            salesDateFilter.end = searchFilters.salesCloseDateEnd;
          }

          return salesDateFilter;
        })(),
      },
    };

    return pickBy(
      {
        ...query,
        filters: {
          ...pickBy(query.filters, identity),
        },
      },
      identity
    );
  }

  download(query) {
    const { filters, ...queryFields } = query;
    const requestObject = {
      request: { ...queryFields, filters: omitBy(filters, o => isEmpty(o)) },
    };

    WindowUtil.open(
      `${EnvUtil.apiEnvoyUrl}/downloads/opportunities?${encodeApiObjects(
        requestObject
      )}`
    );
  }

  downloadSelectedOpportunities(storeMeta, ids) {
    this.download({ filters: { ids } });
  }

  downloadAllOpportunities(storeMeta) {
    const query = {
      ...this.formatMetaForRequest(storeMeta),
    };

    this.download({
      sort: query.sort,
      search: query.search,
      filters: query.filters,
    });
  }
}

export const OpportunitiesStore = new OpportunitiesStoreClass({
  filterShape: shape({
    search: string,
    stageCode: arrayOf(string),
    businessTeam: arrayOf(string),
    ownerUserId: arrayOf(string),
    practiceAreaId: arrayOf(string),
    salesCloseDateStart: string,
    salesCloseDateEnd: string,
    skills: arrayOf(string),
  }),
  dataShape: opportunityShape,
});
