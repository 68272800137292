/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, func, string } from 'prop-types';

import { PreloadedSkillsStore } from '../../stores/preloaded-skills-store';
import DropdownTree from '../DropdownTree/DropdownTree';

const SkillsDropdown = props => {
  const filteredValue = props.excludePredicate(
    props.exclude,
    props.selectedValues
  );
  const normalisedValue = Array.isArray(props.selectedValues)
    ? props.selectedValues
    : [];

  return (
    <DropdownTree
      {...props}
      selectedValues={filteredValue || normalisedValue}
      options={props.options || normalisedValue}
    />
  );
};

SkillsDropdown.defaultProps = {
  exclude: [],
  excludePredicate: () => false,
  selectedValues: [],
};

SkillsDropdown.propTypes = {
  ...DropdownTree.propTypes,
  exclude: arrayOf(string),
  excludePredicate: func,
  selectedValues: arrayOf(string),
};

export default connect(state => ({
  options: PreloadedSkillsStore.select(state),
}))(SkillsDropdown);
