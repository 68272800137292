import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import styled, { ThemeProvider } from 'styled-components';
import {
  Blocker,
  DesignSysEnvUtil,
  ErrorBoundary,
  I18nProvider,
  LightTheme,
  LoggerUtil,
  Suspense,
  Toasts,
  SaplingUtil,
  ConsumeWarningsUtil,
  DataGridLicenseContextProvider,
  DataGridLicenceKey,
} from '@axiom/ui';
import { connect, Provider } from 'react-redux';
import { UserConst } from '@axiom/const';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';

import themes from './themes';
import initializeStore from './redux/store';
import withReduxSaga from './redux/reduxSagaHoc';
import LoadingTreatment from './components/LoadingTreatment/LoadingTreatment';
import CurrentAvailabilityModal from './components/CurrentAvailabilityModal/CurrentAvailabilityModal';
import AppEventItemModal from './components/AppEventItemModal/AppEventItemModal';
import AppFindAccountForContactModal from './components/AppFindAccountForContactModal/AppFindAccountForContactModal';
import AppFindCandidateForOppModal from './components/AppFindCandidateForOppModal/AppFindCandidateForOppModal';
import AppFindContactForAccountModal from './components/AppFindContactForAccountModal/AppFindContactForAccountModal';
import AppFindOppForCandidateModal from './components/AppFindOppForCandidateModal/AppFindOppForCandidateModal';
import JiraInitializer from './components/JiraInitializer/JiraInitializer';
import enLocale from './locales/en/messages';
import deLocale from './locales/de/messages';
import laLocale from './locales/la/messages';
import { EnvUtil } from './utils/env-util';
import { PendoInitializer } from './components/PendoInitializer/PendoInitializer';
import { Router } from './Router';
import { CookieUtil } from './utils/auth/cookie-util';
import { PreloadedUserStore } from './stores/preloaded-user-store';
import { getInitialAppData } from './redux/actions/app';
import { TalentWarningModals } from './components/TalentWarningModals/TalentWarningModals';
import { WindowUtil } from './utils/window-util';
import { hasInitialDataSelector } from './redux/selectors/app';
import { ErrorBoundaryLayout } from './layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';
import { isLPUser } from './utils/roles';
import { SessionExpiryHandler } from './components/SessionExpiry/SessionExpiryHandler';
import { SaplingApi } from './api/sapling';
import { useScreenResolutionToast } from './hooks/useScreenResolutionToast';

import 'react-dates/initialize';
import '@progress/kendo-theme-default/dist/all.css';
import '@axiom/ui/src/public/static/kendo-overrides.css';
import './public/css/envoy-kendo-overrides.css';
// eslint-disable-next-line import/no-unresolved
import 'semantic-ui-less/semantic.less';
import '@axiom/ui/src/public/static/main.css';
import '@axiom/ui/src/public/static/main-internal.css';
import '@axiom/ui/src/public/static/css-helpers.css';

/**
 * Temp disable until we can get to this ticket
 * APCORE-2484
 */
if (process.env.NODE_ENV !== 'production') {
  ConsumeWarningsUtil.consumePropWarnings();
}

// The k-animation-container-shown rule is a fix for the popup positioning of
// the Kendo MultiSelect component when it's used at the bottom of a scrolling
// modal. The second class is generated from on the form field name;
// see components/MultiSelect/MultiSelect.jsx.
const IndexWrapper = styled.div`
  *,
  * > *,
  * * {
    box-sizing: border-box;
  }

  .k-animation-container-shown.multiselect-collaborators {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 200px;
  }

  .k-dropdown,
  .k-dropdowntree {
    width: 100% !important;
  }
`;

DesignSysEnvUtil.initialize({
  COOKIE_DOMAIN: EnvUtil.cookieDomain,
  GOOGLE_MAPS_API_URL: EnvUtil.googleMapsApiUrl,
  CLIENT_API_BASE: EnvUtil.clientApiBase,
  S3_PUBLIC_BASE_URL: EnvUtil.publicBaseUrlS3,
  S3_PUBLIC_FILE_BUCKET: EnvUtil.publicS3Bucket,
  ENABLE_SUGGESTIONS: EnvUtil.enableSuggestions,
  ENABLE_CALENDAR_EVENTS_TODAY: EnvUtil.enableCalendarEventsToday,
});

if (EnvUtil.enableLogging) {
  LoggerUtil.initialize(
    EnvUtil.logglyClientId,
    'envoy',
    EnvUtil.environmentName
  );
}

const store = initializeStore();
LicenseManager.setLicenseKey(DataGridLicenceKey);

// eslint-disable-next-line react/prop-types
const BootstrapInnards = ({ dispatch, hasInitialData }) => {
  const [showForbidden, setShowForbidden] = useState({
    forbidden: false,
    user: null,
  });

  useEffect(() => {
    if (CookieUtil.isUserExpired()) {
      WindowUtil.toLogin();
    } else {
      const cookie = CookieUtil.decodeUserCookie();
      PreloadedUserStore.load(cookie.activeDirectorySecurityGroup).then(
        user => {
          if (
            user?.roles?.some(role =>
              [
                UserConst.Roles.EnvoySales,
                UserConst.Roles.EnvoyUser,
                UserConst.Roles.EnvoyAdmin,
              ].includes(role)
            )
          ) {
            dispatch(getInitialAppData(cookie));

            SaplingApi.createToken().then(({ token }) => {
              SaplingUtil.initialize(token, user.id);
            });
          } else {
            setShowForbidden({ forbidden: true, user });
          }
        }
      );
    }
  }, []);

  if (showForbidden.forbidden) {
    if (isLPUser(showForbidden.user)) {
      window.location.replace(EnvUtil.portalUrl);
      return <LoadingTreatment />;
    }

    return <div className="text-align-center">You are unauthorized!</div>;
  }

  if (!hasInitialData) {
    return <LoadingTreatment />;
  }

  return (
    <I18nProvider
      locales={{
        en: enLocale,
        de: deLocale,
        la: laLocale,
      }}
    >
      <ThemeProvider theme={themes.main}>
        <DataGridLicenseContextProvider>
          <BrowserRouter future={{ v7_startTransition: true }}>
            <ErrorBoundary errorLayout={<ErrorBoundaryLayout />}>
              <Blocker>
                <SessionExpiryHandler>
                  <Toasts />
                  <Suspense>
                    <PendoInitializer />

                    <LightTheme backgroundName="none">
                      <ThemeProvider theme={themes.main}>
                        <Router />
                        <CurrentAvailabilityModal />
                        <AppEventItemModal />
                        <AppFindAccountForContactModal />
                        <AppFindCandidateForOppModal />
                        <AppFindContactForAccountModal />
                        <AppFindOppForCandidateModal />
                        <TalentWarningModals />
                      </ThemeProvider>
                    </LightTheme>

                    <JiraInitializer />
                  </Suspense>
                </SessionExpiryHandler>
              </Blocker>
            </ErrorBoundary>
          </BrowserRouter>
        </DataGridLicenseContextProvider>
      </ThemeProvider>
    </I18nProvider>
  );
};

const connectedWithSaga = withReduxSaga({ async: true })(BootstrapInnards);
const ConnectedWithDispatch = connect(hasInitialDataSelector)(
  connectedWithSaga
);

const Bootstrap = () => {
  useScreenResolutionToast();
  return (
    <IndexWrapper>
      <I18nProvider
        locales={{
          en: enLocale,
          de: deLocale,
        }}
      >
        <Provider store={store}>
          <ConnectedWithDispatch />
        </Provider>
      </I18nProvider>
    </IndexWrapper>
  );
};

const root = createRoot(document.querySelector('#root'));
root.render(<Bootstrap />);
