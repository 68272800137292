import Ajv from 'ajv';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());
const TYPE_ENUM_VALS = ['candidate', 'opportunity'];

const teamSearchValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      enum: TYPE_ENUM_VALS,
    },
  },
};
export const teamSearchValidator = ajv.compile(teamSearchValidation);
