import { CandidateImageConst, ImageSizeKeysTypes } from '@axiom/const';
import { Candidate, SubmissionCandidate } from '@axiom/validation';

import { DesignSysEnvUtil } from './design-sys-env-util';

const {
  IMAGE_RESIZE_DIRECTORY,
  ImageSizesByWidth,
  ImageSizesByHeight,
  PROFILE_IMAGE_DIRECTORY,
} = CandidateImageConst;

export const CandidateProfileUtil = {
  getProfileImageUri(
    candidate: Candidate | SubmissionCandidate,
    size: ImageSizeKeysTypes
  ) {
    const { profileImageKey } = candidate;

    if (
      !profileImageKey ||
      !(ImageSizesByWidth[size] || ImageSizesByHeight[size]) ||
      !DesignSysEnvUtil.publicS3Bucket
    ) {
      return null;
    }

    const [idSlug] = profileImageKey
      .replace(`${PROFILE_IMAGE_DIRECTORY}/`, '')
      .split('/');
    const { width, height } =
      ImageSizesByWidth[size] || ImageSizesByHeight[size];

    return `${DesignSysEnvUtil.publicBaseUrlS3}/${DesignSysEnvUtil.publicS3Bucket}/${IMAGE_RESIZE_DIRECTORY}/${idSlug}/${width}x${height}`;
  },
};
