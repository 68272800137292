import React from 'react';
import { FormFieldProps } from 'semantic-ui-react';

import { FormField } from '../FormField/FormField';

import { CalendarProps, RawCalendar } from './RawCalendar';

type CalendarFormProps = Omit<CalendarProps, 'events'>;

export const Calendar = ({
  permissions,
  startMode,
  weekMode,
  dayMode,
  backgroundEvents = [],
  scrollToTime,
  timezone,
  height,
  displayDate,
  name,
}: CalendarFormProps) => {
  return (
    <FormField
      name={name}
      renderField={({ setFieldValue, value }: FormFieldProps) => {
        return (
          <RawCalendar
            name={name}
            permissions={permissions}
            events={value}
            startMode={startMode}
            weekMode={weekMode}
            dayMode={dayMode}
            backgroundEvents={backgroundEvents}
            scrollToTime={scrollToTime}
            timezone={timezone}
            height={height}
            onChange={data => setFieldValue(name, data)}
            displayDate={displayDate}
          />
        );
      }}
    />
  );
};
