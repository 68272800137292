import qs from 'qs';
import crypto from 'crypto-js/sha256';

import { EnvUtil } from './env-util';

let navigateCallback;
export const WindowUtil = {
  setNavigateCallback(callBack) {
    navigateCallback = callBack;
  },
  toLogin() {
    window.location.href = `${EnvUtil.loginUrl}?RelayState=${window.location.host}${window.location.pathname}${window.location.search}${window.location.hash}`;
  },
  exists() {
    /**
     * try/catch because next.js will throw error if trying to access window on the server >.<
     */
    try {
      return !!window;
    } catch {
      return false;
    }
  },
  title(newTitle) {
    document.title = newTitle;
  },
  open(url, query) {
    const queryString = qs.stringify(query);
    window.location.href = queryString ? `${url}?${queryString}` : url;
  },
  appendQueryParams(url, extraParams = {}) {
    const urlParams = new URLSearchParams(window.location.search);
    Object.entries(extraParams).forEach(([key, value]) => {
      urlParams.append(key, value);
    });
    return `${url}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
  },
  getUrlQuery() {
    return qs.parse(window.location.search.slice(1));
  },
  setUrlQuery(paramsObj, replace = false) {
    const historySlice = {
      pathname: `${window.location.pathname}`,
    };

    if (qs.stringify(paramsObj)) {
      historySlice.search = `?${qs.stringify(paramsObj)}`;
    }

    if (typeof navigateCallback === 'function') {
      navigateCallback(historySlice, replace);
    }
  },
  initializePendo({ id, roles, email, firstName, lastName } = {}) {
    /**
     * Pendo doesnt init every reload without the delay
     */
    setTimeout(() => {
      const payload = {
        visitor: {
          id: crypto(id).toString(),
          role: roles && roles.length > 0 ? roles[0] : null,
          firstName,
          lastName,
          email,
          apUserId: id,
        },
        account: {
          id: 'AxiomLaw',
        },
      };
      if (window?.pendo) {
        // eslint-disable-next-line no-console
        console.log('Initing Pendo As:', payload.visitor);
        window.pendo.initialize(payload);
      }
    }, 1000);
  },
};
