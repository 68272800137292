import styled from 'styled-components';

import { Draggable } from '../Draggable/Draggable';
import { getThemeProp } from '../../themes';

export const OpportunityTalentWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 8rem;
`;

export const OpportunityTalentCandidateWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutrals.malignant20};
  overflow-x: hidden;
`;

export const OTDraggableCardWrapper = styled(Draggable)`
  margin: 1rem 1.5rem 0 1.5rem;
`;

export const OTDropzonePlaceholder = styled.div`
  margin: 1rem 1.5rem 1.5rem 1.5rem;
`;

export const OTTalentSubmissionNote = styled.span`
  white-space: pre-line;
`;

export const OTDropzonePlaceholderButton = styled.button`
  width: 100%;
  background: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  border: 0.0625rem solid ${getThemeProp('textMuted')};
  height: 7.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${getThemeProp('textMuted')};
  font-weight: bold;
  line-height: 1.25rem;
`;

export const OTCandidateName = styled.div`
  width: 100%;
`;

export const OTFlex = styled.div<{ fullHeight?: boolean }>`
  flex: 1;

  > * {
    height: ${({ fullHeight }) => (fullHeight ? `100%` : 'unset')};
  }
`;
