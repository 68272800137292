import moment from 'moment';

import GenericStore from '../classes/generic-store';

const dateFields = {
  createdAt: true,
  scheduledDate: true,
  updatedAt: true,
};
const searchableDateFormats = [
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MM-DD-YY',
  'MM/DD/YY',
  'M/D/YY',
  'M-D-YY',
  'M/D/YYYY',
  'M-D-YYYY',
];
const keysToSkip = {
  id: true,
  attachmentKey: true,
};

class ActivityLogsSearchStoreClass extends GenericStore {
  load(events, usersById, opportunities = [], positions = []) {
    if (!events || !Array.isArray(events)) return this;

    const searchDataItems =
      events.map(evnt => ({
        id: evnt.id,
        string: Object.keys(evnt)
          .reduce((ary, key) => {
            const data = evnt[key];
            let value = data;

            if (!value || keysToSkip[key]) return ary;

            switch (key) {
              case 'opportunityId': {
                if (value && opportunities?.length > 0) {
                  const opp = opportunities.find(op => op.id === value);

                  value = opp?.jobName;
                } else {
                  // we don't want to keep the opportunityId
                  value = null;
                }
                break;
              }
              case 'candidateId': {
                if (value && positions?.length > 0) {
                  const candidate = positions.reduce((talent, position) => {
                    const found = position?.candidates.find(
                      c => c.id === value
                    );
                    return found || talent;
                  }, {});

                  value = candidate?.calculatedDisplayName;
                } else {
                  // we don't want to keep the candidateId
                  value = null;
                }
                break;
              }
              case 'lastAuthoredBy':
              case 'lastUpdatedBy': {
                // can 'lastAuthoredBy' and or 'lastUpdatedBy' be a candidate?
                value = usersById[data] ?? null;
                break;
              }
              default: {
                if (dateFields[key]) {
                  value = [];
                  // standardizing date formats, there are many in the original data
                  const mDate = moment(data, 'YYYY-MM-DD');
                  searchableDateFormats.forEach(format => {
                    value.push(mDate.format(format));
                  });
                }
              }
            }

            if (Array.isArray(value)) {
              // adding and flattening
              ary.push(...value);
            } else if (value) ary.push(value); // removing falsies

            return ary.reduce((deDupped, item) => {
              // de-dupping and returning the results
              if (!deDupped.includes(item)) deDupped.push(item.toLowerCase());
              return deDupped;
            }, []);
          }, [])
          .join('|'),
      })) || [];

    return this.setState(searchDataItems);
  }

  search(term, searchableData = []) {
    const txt = term.trim().toLowerCase();

    if (!txt) return null;

    const response = searchableData
      .filter(sd => sd.string.includes(txt))
      .map(sd => sd.id);

    return response;
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }

  unload() {
    this.clearState(null);
  }
}

export const ActivityLogsSearchStore = new ActivityLogsSearchStoreClass();
