const propertyCheck = ['link', 'date'];
const formatNotificationBody = data =>
  data.map(item => {
    item = { ...item };
    try {
      const body = JSON.parse(item.body);
      const keys = Object.keys(body);
      let valid = false;

      if (keys.length === propertyCheck.length) {
        valid = !propertyCheck.some(prop => !body[prop]);
      }

      if (!valid) {
        throw new Error('INVALID_OBJECT');
      }

      item.body = body;
      // eslint-disable-next-line no-empty
    } catch {}

    return item;
  });

export default formatNotificationBody;
