import Ajv from 'ajv';
import ajvKeywords from 'ajv-keywords';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const OrganizationSchema = z.object({
  id: z.string().uuid(),
  name: z.string().trim().max(255),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

ajvKeywords(ajv, 'transform');

const organizationValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      minLength: 3,
      transform: ['trim'],
    },
  },
  required: ['name'],
};

export const organizationSettingsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      minLength: 1,
    },
    value: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['name', 'value'],
};

export const organizationValidator = ajv.compile(organizationValidation);
export const organizationSettingsValidator = ajv.compile(
  organizationSettingsValidation
);
