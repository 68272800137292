import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { BaseUserSchema, RolesValues } from './base-user';
import { AccountSchema } from './account';
import { ContactSchema } from './contacts';
import { requireAtLeastOneDefined } from './general';
import { axiomValidationOptions } from './options';

export const UserSettingsSchema = z.object({
  id: z.string().uuid(),
  appId: z.string().uuid().nullable(),
  env: z.string().nullable(),
  name: z.string(),
  value: z.string().nullable(),
  createdAt: SchemaTimestamp,
  updatedAt: SchemaTimestamp,
});

// BaseUserSchema had to be moved to its own file to avoid a circular dependency issue
// from adding the ContactAccountInfo expansion schema
export const UserSchema = BaseUserSchema.extend({
  contact: ContactSchema.extend({
    accounts: z.array(AccountSchema).nullish(),
  }).nullable(),
  userSettings: z.array(UserSettingsSchema).nullish(),
});

export const PostUserSettingsSchema = UserSettingsSchema.partial()
  .pick({ appId: true, env: true, name: true, value: true })
  .strict()
  .refine(requireAtLeastOneDefined);

export const OtherContactUserSchema = BaseUserSchema.extend({
  contactId: z.string().uuid().nullable(),
});
const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

// eslint-disable-next-line @typescript-eslint/no-require-imports,unicorn/prefer-module
require('ajv-keywords')(ajv, 'transform');

const userValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    lastName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    email: {
      type: 'string',
      format: 'email',
      transform: ['trim', 'toLowerCase'],
    },
    roles: {
      type: 'array',
      items: {
        type: 'string',
        minLength: 1,
        transform: ['trim'],
      },
    },
    isActive: {
      type: 'boolean',
    },
    candidateId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    contactId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    password: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['firstName', 'lastName', 'email', 'roles'],
};

const userUpdateValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    lastName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    salesforceId: {
      type: ['string', 'null'],
    },
    integrationId: {
      type: ['string', 'null'],
    },
    homeOffice: {
      type: ['string', 'null'],
    },
    email: {
      type: 'string',
      format: 'email',
      transform: ['trim', 'toLowerCase'],
    },
    password: {
      type: 'string',
      minLength: 1,
    },
    oldPassword: {
      type: 'string',
      minLength: 1,
    },
    candidateId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    token: {
      type: 'string',
      format: 'uuid',
    },
    roles: {
      type: 'array',
      items: {
        type: 'string',
        minLength: 1,
        enum: RolesValues,
        transform: ['trim'],
      },
    },
    isActive: {
      type: 'boolean',
    },
    termsAndConditionsAcceptedAt: {
      type: 'string',
      format: 'date-time',
    },
    contactId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
  },
};

export const userSettingsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    appId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    env: {
      type: 'string',
      minLength: 1,
    },
    name: {
      type: 'string',
      minLength: 1,
    },
    value: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['name', 'value'],
};

export const userDefaultSettingsValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    appId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    env: {
      type: 'string',
      minLength: 1,
    },
    name: {
      type: 'string',
      minLength: 1,
    },
    value: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['appId', 'name', 'value'],
};

export const userUpsertValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    lastName: {
      type: 'string',
      minLength: 1,
      transform: ['trim'],
    },
    email: {
      type: 'string',
      format: 'email',
      transform: ['trim', 'toLowerCase'],
    },
    salesforceId: {
      type: ['string', 'null'],
    },
    bullhornId: {
      type: ['string', 'null'],
    },
    homeOffice: {
      type: ['string', 'null'],
    },
    isActive: {
      type: 'boolean',
    },
    integrationId: {
      type: ['string', 'null'],
    },
  },
};

export const userUpsertValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...userUpsertValidation,
    ...additionalOpts,
  });

export const userValidator = ajv.compile(userValidation);
export const userUpdateValidator = ajv.compile(userUpdateValidation);
export const userUpsertValidator = userUpsertValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const userUpsertAdditionalPropertiesValidator =
  userUpsertValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const userSettingsValidator = ajv.compile(userSettingsValidation);
export const userDefaultSettingsValidator = ajv.compile(
  userDefaultSettingsValidation
);
