import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const AccordionShadows = {
  default: 'default',
  elevated: 'elevated',
} as const;

export type AccordionShadow =
  (typeof AccordionShadows)[keyof typeof AccordionShadows];

export type AccordionsProps = {
  children: React.ReactNode;
  className?: string;
  minimal?: boolean;
  shadow?: AccordionShadow;
  name?: string;
};

export const Accordions = ({
  children,
  className,
  minimal,
  shadow,
  name,
}: AccordionsProps) => {
  return (
    <div
      data-test={name}
      className={AttrsHelper.formatClassname(
        'accordions',
        minimal && 'minimal-accordion',
        shadow ? `shadow-${shadow}` : 'flat-accordion',
        className
      )}
    >
      {children}
    </div>
  );
};
