export const WeekdayConst = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const WeekdayToName = day =>
  Object.keys(WeekdayConst).find(d => WeekdayConst[d] === day);
