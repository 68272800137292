import Ajv from 'ajv';
import { TalkConst } from '@axiom/const';

import { axiomValidationOptions } from './options';

const { hoursPerWeekArr, expectedDurationArr } = TalkConst;

const ajv = new Ajv(axiomValidationOptions());

const talkCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    title: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    accountId: {
      type: 'string',
      format: 'uuid',
    },
    requests: {
      type: 'array',
      items: {
        type: 'string',
        format: 'date-time',
      },
    },
    contactTz: {
      type: 'string',
    },
    startDate: {
      type: ['string', 'null'],
      format: 'date-time',
    },
    hoursPerWeek: {
      type: ['string', 'null'],
      enum: [...hoursPerWeekArr, null],
    },
    expectedDuration: {
      type: ['string', 'null'],
      enum: [...expectedDurationArr, null],
    },
  },
};

// POST /talks/request
export const talkRequestClientValidator = ajv.compile({
  ...talkCommonValidation,
  required: [
    'candidateId',
    'accountId',
    'requests',
    'title',
    'description',
    'contactTz',
  ],
});
