/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { arrayOf, string, func, bool } from 'prop-types';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import { FormFeedback } from 'reactstrap';
import { filterBy } from '@progress/kendo-data-query';
import debounce from 'lodash/debounce';

import { formatDataTestId } from '../../utils/dataTest';
import { DropdownOptionsShape } from '../../models/dropdown-options';

import {
  KendoErrorWrapper,
  MultiSelectWrapper,
  LabelWrapper,
} from './MultiSelectStyles';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [...props.options],
      loading: false,
      value: props.value
        .map(v => props.options.find(d => d.value === v))
        .filter(Boolean),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value.length !== state.value.length) {
      return {
        ...state,
        value: props.value
          .map(v => props.options.find(d => d.value === v))
          .filter(Boolean),
      };
    }

    return null;
  }

  componentDidMount() {
    this.debouncedFilterChange = debounce(this.filterChangeDone, 500);
  }

  componentDidUpdate(prevProps) {
    const { options, value } = this.props;
    if (options.length !== prevProps.options.length) {
      this.setState({
        options: [...options],
        value: value.map(v => options.find(d => d.value === v)),
      });
    }
  }

  componentWillUnmount() {
    this.debouncedFilterChange.cancel();
  }

  handleChange = event => {
    /**
     * Some options return arrays of values
     * get values, flatten and make unique
     * */
    const result = event.target.value
      .flatMap(item => item.value)
      .filter((item, i, ary) => ary.indexOf(item) === i);
    this.setState({ value: [...event.target.value] }, () => {
      this.props.onChange(result);
      this.props.onAfterChange(result);
    });
  };

  filterChange = event => {
    this.setState({
      loading: true,
    });

    this.debouncedFilterChange(event.filter);
  };

  filterChangeDone = filter => {
    const trimmedFilter = { ...filter, value: filter.value.trim() };

    this.setState({
      options: filterBy([...this.props.options], trimmedFilter),
      loading: false,
    });
  };

  getDataValue = () =>
    this.state.value.map(option => option.value || '').join(',');

  itemRender = (li, itemProps) => {
    const itemChildren = (
      /**
       * using data-value here for selection/assertion on e2e tests
       * since using the data-test layer will require a layer to also
       * be used in e2e tests
       * */
      <LabelWrapper data-value={itemProps.dataItem.value}>
        {li.props.children}
      </LabelWrapper>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  tagRender = (tagData, li) => {
    return React.cloneElement(
      li,
      { ...li.props, 'data-test': formatDataTestId('multiselecttag') },
      li.props.children
    );
  };

  render() {
    const {
      props: {
        dataItemKey,
        id,
        textField,
        disabled,
        name,
        placeholder,
        filterable,
        ...inputProps
      },
      state: { options, loading, value },
    } = this;

    // The className included in the popupSettings prop passed to
    // KendoMultiSelect is targeted by global CSS in pages/_document.jsx
    // to fix the popup positioning when this component is used at
    // the bottom of a scrollable modal.
    return (
      <KendoErrorWrapper
        invalid={inputProps.invalid}
        data-test={formatDataTestId(id || name)}
      >
        <MultiSelectWrapper
          className={inputProps.invalid ? 'form-control is-invalid' : ''}
          invalid={inputProps.invalid}
          data-test={formatDataTestId('multiselect')}
          data-value={this.getDataValue()}
          id={id}
        >
          <KendoMultiSelect
            {...this.inputProps}
            data={options}
            dataItemKey={dataItemKey}
            disabled={disabled}
            filterable={filterable}
            itemRender={this.itemRender}
            loading={loading}
            name={name}
            onChange={this.handleChange}
            onFilterChange={this.filterChange}
            placeholder={placeholder}
            tagRender={this.tagRender}
            textField={textField}
            value={value}
            popupSettings={{ animate: false, className: `multiselect-${name}` }}
          />
        </MultiSelectWrapper>
        <FormFeedback invalid={inputProps.invalid}>
          {inputProps.error}
        </FormFeedback>
      </KendoErrorWrapper>
    );
  }
}

MultiSelect.defaultProps = {
  dataItemKey: 'value',
  disabled: false,
  filterable: true,
  id: null,
  name: undefined,
  onChange: () => {},
  onAfterChange: () => {},
  placeholder: '',
  textField: 'label',
  value: [],
};

MultiSelect.propTypes = {
  onAfterChange: func,
  options: DropdownOptionsShape.isRequired,
  dataItemKey: string,
  disabled: bool,
  filterable: bool,
  id: string,
  name: string,
  onChange: func,
  placeholder: string,
  textField: string,
  value: arrayOf(string),
};

export default MultiSelect;
