import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CandidateOpportunitiesConst, CandidatesConst } from '@axiom/const';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Paragraph,
} from '@axiom/ui';
import { bool } from 'prop-types';

import {
  getSFDCLinkForEdit,
  getSFDCLinkForClose,
} from '../../utils/salesforce-utils';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import FulfillmentConfirmationModal from '../OpportunityFulfillmentConfirmationModal/OpportunityFulfillmentConfirmationModal';
import { EditOpportunityFulfillmentStore } from '../../stores/edit-opportunity-fulfillment-store';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { OpportunityStore } from '../../stores/opportunity-store';
import { OpportunityUtils } from '../../utils/opportunity-utils';

const OpportunitiesContextMenuComponent = ({
  opportunityPositions,
  opportunity,
  fulfillmentState,
  showAnchorButton,
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const isMenuSelectionDisabled = () =>
    opportunityPositions.length === 0 ||
    !opportunity.isFulfillmentActive ||
    OpportunityUtils.isClosed(opportunity);

  const positionCount = opportunityPositions?.length || 0;
  let candidatesNotSelected = false;
  let candidateStatusInvalid = false;
  if (opportunityPositions.length > 0 && opportunity.isFulfillmentActive) {
    const [selectedCount, validCount] = opportunityPositions.reduce(
      ([sCount, vCount], position) => {
        if (position.candidates?.length) {
          const selected = position.candidates.filter(
            candidate =>
              candidate.candidateStatus ===
              CandidateOpportunitiesConst.CandidateStatuses.Selected
          );

          const validSelected = position.candidates.filter(
            candidate =>
              candidate.candidateStatus ===
                CandidateOpportunitiesConst.CandidateStatuses.Selected &&
              CandidatesConst.ValidFulfillmentCompletionStatuses.includes(
                candidate.profileStatus
              )
          );
          return [sCount + selected.length, vCount + validSelected.length];
        }
        return [sCount, vCount];
      },
      [0, 0]
    );
    candidatesNotSelected = positionCount !== selectedCount;
    candidateStatusInvalid =
      selectedCount !== validCount && !candidatesNotSelected;
  }

  return (
    <>
      <ContextMenu
        name="OPPORTUNITY_CONTEXT_MENU"
        direction={showAnchorButton ? 'right' : 'left'}
        anchor={
          showAnchorButton ? (
            <Button icon="arrow-down" iconPosition="right" pattern="secondary">
              Submission
            </Button>
          ) : null
        }
      >
        <ContextMenuItem
          name="SALESFORCE_LINK_CONTEXT_MENU_ITEM"
          onClick={() =>
            window.open(
              getSFDCLinkForEdit(opportunity.salesforceQuoteId),
              'SFDC'
            )
          }
        >
          View in Salesforce
        </ContextMenuItem>
        <ContextMenuItem
          name="SUBMISSION_MODAL_CONTEXT_MENU_ITEM"
          onClick={() =>
            EditSubmissionStore.load(opportunity.submissionId, opportunity.id)
          }
        >
          {OpportunityUtils.isClosed(opportunity)
            ? 'View Submission'
            : 'Manage Submission'}
        </ContextMenuItem>
        <ContextMenuItem
          name="CLOSE_LOST_CONTEXT_MENU_ITEM"
          disabled={isMenuSelectionDisabled()}
          onClick={() => setShowModal(true)}
        >
          Close Lost in Salesforce
        </ContextMenuItem>
        <ContextMenuItem
          name="COMPLETE_FULFILLMENT_MENU_ITEM"
          disabled={
            candidatesNotSelected ||
            candidateStatusInvalid ||
            !opportunity.isFulfillmentActive
          }
          onClick={() => EditOpportunityFulfillmentStore.load(opportunity.id)}
        >
          Complete Fulfillment
          {candidateStatusInvalid &&
            ` (${CandidatesConst.StatusInvalidForCompletionMessage})`}
        </ContextMenuItem>
      </ContextMenu>
      <StoreStateTreatment
        storeState={fulfillmentState}
        renderLoadedView={({ data }) => (
          <FulfillmentConfirmationModal opportunityId={data.opportunityId} />
        )}
      />
      {showModal && (
        <Modal title="Close Lost in SFDC" name="CLOSE_LOST_MODAL">
          <ModalHeader onClose={closeModal}>Close Lost in SFDC</ModalHeader>
          <ModalSection>
            <Paragraph>
              Closing this opportunity will cool all talent that was warmed but
              not selected. If you want to customize cooling messages, cool the
              talent before closing this opportunity.
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button variation="outline" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                closeModal();
                window.open(
                  getSFDCLinkForClose(opportunity.salesforceId),
                  'SFDC'
                );
              }}
            >
              Close Opportunity
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

OpportunitiesContextMenuComponent.defaultProps = {
  showAnchorButton: false,
};

OpportunitiesContextMenuComponent.propTypes = {
  opportunity: OpportunityStore.getDataShape().isRequired,
  opportunityPositions: OpportunityPositionsStore.getDataShape().isRequired,
  fulfillmentState: EditOpportunityFulfillmentStore.getStateShape().isRequired,
  showAnchorButton: bool,
};

export const OpportunitiesContextMenu = connect(state => ({
  fulfillmentState: EditOpportunityFulfillmentStore.select(state),
}))(OpportunitiesContextMenuComponent);
