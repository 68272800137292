import React, { useEffect } from 'react';
import { TalentBios, useApi } from '@axiom/ui';
import { User } from '@axiom/validation';
import { useLocation, useParams } from 'react-router-dom';

import { LegacyTalentApi } from '../../api/talent';
import { PracticeAreasApi } from '../../api/practiceAreas';
import { CandidateApi } from '../../api/candidate/candidate-api';
import { EnvUtil } from '../../utils/env-util';
import { WindowUtil } from '../../utils/window-util';

export const CandidateBios = ({ user }: { user: User }) => {
  const urlParams = useParams();
  const location = useLocation();
  const { candidateId, hideHighlights } = urlParams;
  const isAnon = location.pathname.includes('/anonymous');
  const download = location.pathname.includes('/download');

  const [{ data: candidate }, { data: practiceAreas }] = useApi(
    LegacyTalentApi.readCandidate(candidateId),
    PracticeAreasApi.readPracticeAreas()
  );
  const [{ data: groupedExperiences }] = useApi(
    candidate.id &&
      CandidateApi.readCandidateGroupedExperiences(candidate.id, {
        isAnonymous: isAnon,
      })
  );
  const baseUrl = `/talent/${candidate.id}`;

  useEffect(() => {
    WindowUtil.title(candidate.calculatedDisplayName);
  }, [candidateId]);

  const handleOnNavigate = (hide = false) => {
    const href = `${baseUrl}/download/bio${`/${
      hide ? 'hideHighlights' : 'showHighlights'
    }`}${isAnon ? '/anonymous' : ''}`;

    window.open(href, 'download_bio');
  };

  return (
    <TalentBios
      user={user}
      hideHighlights={hideHighlights === 'hideHighlights'}
      candidate={candidate}
      groupedExperiences={groupedExperiences}
      practiceAreas={practiceAreas}
      onNavigate={handleOnNavigate}
      isAnonymous={!!isAnon}
      download={!!download}
      clientUrl={EnvUtil.clientUrl}
    />
  );
};
