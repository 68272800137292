import moment from 'moment';

export const TimeCopyUtil = {
  getDaysCopy: (days: number = 0) => {
    return `${Math.max(days, 0)} ${days === 1 ? 'Day' : 'Days'}`;
  },
  getHoursCopy: (hours: number = 0) => {
    return `${Math.max(hours, 0)} ${hours === 1 ? 'Hour' : 'Hours'}`;
  },
  getHoursOrDaysCopyFromNumberOfHours: (hours: number) => {
    if (hours === 1) {
      return `${hours} Hr`;
    }
    if (hours >= 0 && hours < 24) {
      return `${hours} Hrs`;
    }
    if (hours >= 24 && hours < 48) {
      return '1 Day';
    }
    return `${Math.floor(hours / 24)} Days`;
  },
  getHoursDifference: (startTime: string, endTime: string) => {
    return Math.floor(
      moment.duration(moment(endTime).diff(moment(startTime))).as('hours')
    );
  },
  getHoursDifferenceExcludingWeekends: (startTime: string, endTime: string) => {
    let weekendHours = 0;
    const hoursOpen = TimeCopyUtil.getHoursDifference(
      startTime,
      moment(endTime).format()
    );

    for (let x = hoursOpen; x >= 0; x--) {
      if (
        moment.utc(startTime).add(x, 'hours').format('dddd') === 'Saturday' ||
        moment.utc(startTime).add(x, 'hours').format('dddd') === 'Sunday'
      ) {
        weekendHours += 1;
      }
    }

    return hoursOpen - weekendHours;
  },
};
