import React from 'react';
import {
  Gutter,
  Header3,
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  ContextMenu,
  ContextMenuItem,
  Banner,
  Flashy,
} from '@axiom/ui';

import { EnvUtil } from '../../utils/env-util';
import { DateUtil } from '../../utils/date-util';

import {
  DivSubmissionDetailsHeaderWrapper,
  SubmissionModalLastUpdatedText,
  SubmissionModalUrlBox,
} from './SubmissionModalStyles';

interface SubmissionModalURLProps {
  submissionId: string;
  lastUpdated?: string;
  readOnly?: boolean;
  accountId: string;
  submissionPublishedAt?: string;
}

export const SubmissionModalUrl = ({
  lastUpdated = null,
  submissionId,
  readOnly,
  accountId,
  submissionPublishedAt = null,
}: SubmissionModalURLProps) => {
  const submissionTitle = 'Axiom for Clients Submission Link';

  const AFCLink = `${EnvUtil.clientUrl}/projects/account/${accountId}/submission/${submissionId}/matches`;
  const LegacyLink = `${EnvUtil.portalUrl}/digital-submissions/${submissionId}`;
  const updatedText =
    lastUpdated && `Last Updated: ${DateUtil.displayTimestamp(lastUpdated)}`;
  return (
    <DivSubmissionDetailsHeaderWrapper>
      {submissionPublishedAt && (
        <>
          <Banner name="SUBMISSION_URL_BANNER">
            <Flashy color="controlSecondary" bold>
              It’s live!{' '}
            </Flashy>
            The AFC submission link has been published and is visible in AFC.
            You can now send the submission email to clients.
          </Banner>
          <Gutter bottom="8px" />
        </>
      )}
      {readOnly ? (
        <div data-test="READONLY_HEADER">Opportunity closed.</div>
      ) : (
        <Gutter bottom="0.5rem" name="SUBMISSION_URL_HEADER_BLOCK">
          <Header3 name="SUBMISSION_URL_HEADER_TITLE">
            {submissionPublishedAt ? submissionTitle : 'Submissions URL'}
          </Header3>
        </Gutter>
      )}
      {!readOnly && (
        <Layout position="space-between middle" name="SUBMISSION_URL_BODY">
          {submissionPublishedAt ? (
            <>
              <SubmissionModalUrlBox data-test="SUBMISSION_URL_BOX">
                {AFCLink}
              </SubmissionModalUrlBox>
              <Layout inline position="space-between middle">
                <LayoutItem
                  borderRight="1px solid black"
                  horizontalGutter="16px"
                >
                  <IconButton
                    name="SUBMISSION_ICON_OPEN_TAB"
                    icon="popout"
                    pattern="secondary"
                    variation="minimal"
                    toTab={AFCLink}
                    title={`Open "Submission" in new tab`}
                  />
                </LayoutItem>
                <LayoutItem horizontalGutter="16px">
                  <ContextMenu
                    direction="left"
                    name="SUBMISSION_URL_CONTEXT_MENU"
                  >
                    <ContextMenuItem
                      name="COPY_AFC_LINK"
                      onClick={() => navigator.clipboard.writeText(AFCLink)}
                    >
                      Copy AFC submission link
                    </ContextMenuItem>
                    <ContextMenuItem
                      name="DOWNLOAD_INDIVIDUAL_PDF_LINK"
                      onClick={() => {
                        window.open(
                          `${EnvUtil.clientUrl}/my-axiom/${accountId}/${submissionId}/pdf/split`,
                          'download_pdf'
                        );
                      }}
                    >
                      Individual talent submission PDFs
                    </ContextMenuItem>
                    <ContextMenuItem
                      name="DOWNLOAD_PDF_LINK"
                      onClick={() => {
                        window.open(
                          `${EnvUtil.clientUrl}/projects/${accountId}/${submissionId}/pdf/download`,
                          'download_pdf'
                        );
                      }}
                    >
                      Submission PDF
                    </ContextMenuItem>
                    <ContextMenuItem
                      name="LEGACY_SUBMISSION_LINK"
                      onClick={() => {
                        window.open(LegacyLink);
                      }}
                    >
                      Open legacy submission link
                    </ContextMenuItem>
                  </ContextMenu>
                </LayoutItem>
              </Layout>
            </>
          ) : (
            <Paragraph name="SUBMISSION_URL_UNPUBLISHED_BOX">
              Submissions URL will be visible once the submission has been
              published. Publishing a submission makes it visible in AFC.{' '}
            </Paragraph>
          )}
        </Layout>
      )}
      <SubmissionModalLastUpdatedText data-test="UPDATED_ON">
        {updatedText}
      </SubmissionModalLastUpdatedText>
    </DivSubmissionDetailsHeaderWrapper>
  );
};
