import React from 'react';

import { CondensedLarge } from '../../content/CondensedLarge/CondensedLarge';
import { Gutter } from '../../layout/Gutter/Gutter';
import { IconButton } from '../../element/Button/IconButton';
import { Layout } from '../../layout/Layout/Layout';

export interface RawDateInputPopperHeaderProps {
  navigatePrevious: () => void;
  navigateNext: () => void;
  title: string | number;
}

export const RawDateInputPopperHeader = ({
  navigatePrevious,
  navigateNext,
  title,
}: RawDateInputPopperHeaderProps) => (
  <Gutter bottom="8px" name="RDI_POPPER_HEADER">
    <Layout position="space-between middle">
      <IconButton
        icon="arrow-left"
        onClick={navigatePrevious}
        pattern="secondary"
        variation="minimal"
        name="RDI_POPPER_HEADER_PREVIOUS_BUTTON"
      />
      <CondensedLarge name="RDI_POPPER_HEADER_TITLE">{title}</CondensedLarge>
      <IconButton
        icon="arrow-right"
        onClick={navigateNext}
        pattern="secondary"
        variation="minimal"
        name="RDI_POPPER_HEADER_NEXT_BUTTON"
      />
    </Layout>
  </Gutter>
);
