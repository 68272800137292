const Categories = {
  AppliedScience: 'Applied Science',
  BusinessGovernmentEducationAndMedia:
    'Business, Government, Education & Media',
  FormalScience: 'Formal Science',
  Humanities: 'Humanities',
  Languages: 'Languages',
  Law: 'Law',
  NaturalScience: 'Natural Science',
  SocialScience: 'Social Science',
};

export const AreasOfStudyCategoriesConst = {
  Categories,
};
