import React, { forwardRef, MutableRefObject, useEffect } from 'react';
import { PDFExport as KendoPDFExport } from '@progress/kendo-react-pdf';

interface Props {
  children: JSX.Element | JSX.Element[];
  fileName: string;
  pageTemplate?: (props: {
    pageNum: number;
    totalPages: number;
  }) => JSX.Element;
  onSave?: () => void;
  scale: number;
  margin: string | { top: string; right: string; bottom: string; left: string };
}

const PDFComponent = (
  {
    children,
    fileName,
    onSave,
    scale = 0.8,
    margin = '0.3in',
    pageTemplate,
  }: Props,
  ref: MutableRefObject<KendoPDFExport>
) => {
  useEffect(() => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('download') === 'true') {
      url.searchParams.delete('download');
      window.history.replaceState(null, '', url.href);
      if (ref.current) ref.current.save(onSave);
    }
  }, [ref]);

  const Exporter = React.createElement(
    KendoPDFExport,
    {
      pageTemplate,
      fileName,
      forcePageBreak: '.pdf-page-break',
      keepTogether: '.pdf-no-break',
      margin,
      paperSize: 'A4',
      ref,
      scale,
      proxyURL: '/downloading',
    },
    children
  );

  return Exporter;
};

export const PDFExport = forwardRef(PDFComponent);
