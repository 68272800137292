import moment from 'moment';
import {
  CalendarEventType,
  CalendarComponentEvent,
  TimeZone,
} from '@axiom/validation';

import { calendarPermissionsType } from '../../../utils/calendar-permissions-util';

import { EventStates } from './CalendarEventsConst';

type CleanEventType = Omit<CalendarEventType, 'state'>;

export type CleanEvent = Omit<CalendarEventType, 'state'>;
type CalendarOnChangeType = {
  newEvents: CleanEvent[];
  modifiedEvents: CleanEvent[];
  deleteIds: string[];
  staticEvents: CleanEvent[];
};

export const CalendarEventsUtil = {
  canAddEvent: (permissions: calendarPermissionsType, startDate: string) => {
    return (
      permissions.eventBoundary.canCreate &&
      moment(startDate).isAfter(
        moment(),
        CalendarEventsUtil.dateCheckGranularity(permissions)
      )
    );
  },
  dateCheckGranularity: (permissions: calendarPermissionsType) => {
    return permissions.allEvents.canSameDay ? 'hour' : 'day';
  },
  isInThePast: (
    date: CalendarEventType['endTime'] | Date,
    permissions: calendarPermissionsType
  ) => {
    return moment(date).isSameOrBefore(
      moment(),
      CalendarEventsUtil.dateCheckGranularity(permissions)
    );
  },
  translateFromStartTimeEndTimeProps: (
    events: CalendarEventType[]
  ): CalendarComponentEvent[] => {
    return events.reduce(
      (
        data: CalendarComponentEvent[],
        event: CalendarEventType
      ): CalendarComponentEvent[] => {
        const { endTime, startTime, ...rest } = event;
        const item: CalendarComponentEvent = {
          ...rest,
        };

        if (endTime) {
          item.end = moment(endTime).toDate();
        }
        if (startTime) {
          item.start = moment(startTime).toDate();
        }

        data.push(item);
        return data;
      },
      []
    );
  },
  translateToStartTimeEndTimeProps: (
    events: CalendarComponentEvent[]
  ): CalendarEventType[] => {
    return events.reduce(
      (
        data: CalendarEventType[],
        event: CalendarComponentEvent
      ): CalendarEventType[] => {
        const { end, start, ...rest } = event;
        const item: CalendarEventType = {
          ...rest,
        };

        if (end) {
          item.endTime = moment(end).toISOString();
        }

        if (start) {
          item.startTime = moment(start).toISOString();
        }

        data.push(item);
        return data;
      },
      []
    );
  },
  sortEvents: (events: CalendarComponentEvent[]) => {
    if (events.length >= 2) {
      events.sort((a, b) => {
        const aStart = moment(a.start);
        const bStart = moment(b.start);
        const aEnd = moment(a.end);
        const bEnd = moment(b.end);

        if (aStart.isBefore(bStart) || aEnd.isBefore(bEnd)) return -1;

        return 1;
      });
    }
    return events;
  },
  getDeletedEventIds: (
    permissions: calendarPermissionsType,
    events: CalendarEventType[],
    formEvents?: CalendarEventType[]
  ) => {
    const formIds = new Set(formEvents?.map(e => e.id) || []);

    return (
      events
        ?.filter(event => {
          return !CalendarEventsUtil.isInThePast(event.endTime, permissions);
        })
        ?.map(e => e.id) || []
    ).reduce(
      (crnt, id) => {
        if (!formIds.has(id)) {
          crnt.push(id);
        }
        return crnt;
      },
      [] as CalendarOnChangeType['deleteIds']
    );
  },
  clearAndOrganiseEvents: (
    permissions: calendarPermissionsType,
    initData: CalendarEventType[],
    changedEvents?: CalendarEventType[]
  ): CalendarOnChangeType => {
    const baseDataset: CalendarOnChangeType = {
      newEvents: [],
      modifiedEvents: [],
      deleteIds: [],
      staticEvents: [],
    };

    const cleanEvents = changedEvents?.length
      ? changedEvents.reduce(
          (data, event: CalendarComponentEvent) => {
            const tmpEvent: CalendarComponentEvent = {
              ...event,
              id: event.state === EventStates.NEW ? null : event.id,
              isBackgroundEvent: null,
            };

            const cleanEvent = (toCleanEvent: CalendarEventType) => {
              return Object.entries({
                ...toCleanEvent,
                state: null,
              }).reduce((crnt, [key, value]) => {
                if (value !== null) Object.assign(crnt, { [key]: value });

                return crnt;
              }, {} as CleanEventType);
            };

            if (tmpEvent.state === EventStates.NEW) {
              data[tmpEvent.busy ? 'staticEvents' : 'newEvents'].push(
                cleanEvent(tmpEvent)
              );
            } else if (tmpEvent.state === EventStates.MODIFIED) {
              data.modifiedEvents.push(cleanEvent(tmpEvent));
            }

            return data;
          },
          { ...baseDataset } as CalendarOnChangeType
        )
      : baseDataset;

    cleanEvents.deleteIds = CalendarEventsUtil.getDeletedEventIds(
      permissions,
      initData,
      changedEvents
    );

    return cleanEvents;
  },
  setEventError: (
    allEvents: CalendarComponentEvent[],
    permissions: calendarPermissionsType
  ) => {
    const { freetime, interviews } = allEvents.reduce(
      (crnt, event) => {
        if (event.busy) {
          crnt.interviews.push(event);
        } else {
          crnt.freetime.push(event);
        }
        return crnt;
      },
      { freetime: [], interviews: [] }
    );

    const checkStaticEvent = (event: CalendarComponentEvent) => {
      return (
        event.state !== EventStates.SAVED &&
        !freetime.some(fte => {
          // is staticEvent in valid time slot
          return (
            moment(event.start).isBetween(
              fte.start,
              fte.end,
              undefined,
              '[]'
            ) &&
            moment(event.end).isBetween(fte.start, fte.end, undefined, '[]')
          );
        })
      );
    };

    interviews.forEach((event: CalendarComponentEvent) => {
      if (checkStaticEvent(event)) {
        event.error = 'Unavailable';
      }
    });

    freetime.forEach((event: CalendarComponentEvent) => {
      if (
        moment(event.end).isSameOrBefore(
          moment(),
          CalendarEventsUtil.dateCheckGranularity(permissions)
        ) ||
        moment(event.start).isSameOrBefore(
          moment(),
          CalendarEventsUtil.dateCheckGranularity(permissions)
        )
      ) {
        event.error = 'Unavailable';
      }
    });

    return allEvents;
  },
  getFormError: (
    formEvents?: CalendarEventType[],
    timeZoneId?: TimeZone['id'],
    noGoodTimes?: boolean
  ): null | string => {
    if (!noGoodTimes) {
      if (formEvents?.some(event => !!event.error)) {
        return 'Please select at least one available time to continue.';
      }

      if (!timeZoneId && !formEvents) {
        return 'Please make at least one edit to continue.';
      }
    }

    return null;
  },
};
