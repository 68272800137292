const removeAdditional = process.env.API_REMOVE_ADDITIONAL_VALIDATION === 'yes';

export const axiomValidationOptions = opts => {
  const ajvOptions = {
    allErrors: true,
    format: 'full',
    removeAdditional,
  };

  return {
    ...ajvOptions,
    ...opts,
  };
};

export default axiomValidationOptions;
