import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';
import { UserSchema } from './user';

export const CandidateWhiteboardUserSchema = UserSchema.pick({
  fullName: true,
  id: true,
  firstName: true,
  lastName: true,
  email: true,
});

export const CandidateWhiteboardSchema = z.object({
  body: z.string().nullable(),
  candidateId: z.string().uuid(),
  id: z.string().uuid(),
  updatedAt: z.date().nullable(),
  updatedByUser: CandidateWhiteboardUserSchema,
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const validateCandidateWhiteboard = ajv.compile({
  type: 'object',
  properties: {
    body: {
      type: 'string',
      maxLength: 10000,
    },
  },
  required: ['body'],
});
