import React, { useEffect, useState } from 'react';
import { ReplaySubject } from 'rxjs';

import { CondensedMedium } from '../../content/CondensedMedium/CondensedMedium';
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { useFormContext } from '../Form/useFormContext';

export const FormGroupLabel = ({
  description,
  label,
  name,
  propInitializer,
  Tooltip,
}: {
  description?: React.ReactNode;
  label?: React.ReactNode;
  name?: string;
  propInitializer: ReplaySubject<unknown>;
  Tooltip?: React.ReactNode;
}) => {
  const [isRequired, setIsRequired] = useState(false);
  const formContext = useFormContext();

  useEffect(() => {
    /**
     * See explanation in FormGroup of why we're using rxjs here.
     */
    const subscription = propInitializer.subscribe((propName: string) => {
      if (propName) {
        const schemaProperty = formContext.getSchemaProperty(propName);
        if (!schemaProperty.constraints.optional && !isRequired) {
          setIsRequired(true);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [propInitializer]);

  return (
    <>
      <FormFieldLabel
        name={name}
        label={label}
        required={isRequired}
        Tooltip={Tooltip}
      />
      {description && (
        <CondensedMedium name="form-description" className="description">
          {description}
        </CondensedMedium>
      )}
    </>
  );
};
