import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Gutter, Suspense, Tab, Tabs } from '@axiom/ui';
import { maxFiltersErrorMessage } from '@axiom/const';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { SavedSearches } from '../SavedSearches/SavedSearches';
import { UserCandidateFiltersStore } from '../../stores/user-candidate-filters-store';
import { EditSavedCandidateFilterStore } from '../../stores/edit-saved-candidate-filter-store';
import { WindowUtil } from '../../utils/window-util';
import { countFilters } from '../../utils/search';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { PreloadedCandidateListFilters } from '../../stores/preloaded-candidate-list-filters';

import { CandidateFiltersForm } from './CandidateFiltersForm';

export const CandidateFilters = () => {
  const user = useSelector(state => PreloadedUserStore.select(state));
  const userCandidateFiltersState = useSelector(state =>
    UserCandidateFiltersStore.select(state)
  );
  const [selectFilterId, setSelectFilterId] = React.useState(null);
  useEffect(() => {
    UserCandidateFiltersStore.load(user.id);
  }, []);

  const filters = useSelector(state =>
    PreloadedCandidateListFilters.select(state)
  );

  const handleFilterChanges = newFilter => {
    const urlFilters = { ...newFilter };
    if (newFilter.id) {
      setSelectFilterId(newFilter.id);
      delete urlFilters.id;
    }

    const newLength = countFilters({ ...urlFilters });
    if (newLength > 30) {
      PreloadedAppErrorsStore.show(maxFiltersErrorMessage);
    } else {
      PreloadedCandidateListFilters.setState({ ...urlFilters });
      WindowUtil.setUrlQuery(
        {
          ...Object.keys(urlFilters).reduce((acc, key) => {
            acc[key] = urlFilters[key];
            if (acc[key] === null || acc[key] === '') {
              acc[key] = undefined;
            }

            return acc;
          }, {}),
        },
        true
      );
    }
  };
  const handlerRenderFilter = userFilter => (
    <CandidateFiltersForm
      isEditable={false}
      filters={userFilter?.filters || {}}
    />
  );

  return (
    <StoreStateTreatment
      name="TalentFiltersTreatment"
      allowOverflow
      storeState={userCandidateFiltersState}
      showRefetchLoader={false}
      renderLoadedView={({ data: userCandidateFilters }) => {
        return (
          <Gutter top="16px">
            <Tabs fluid>
              <Tab label="New" name="New">
                <Suspense>
                  <CandidateFiltersForm
                    filters={filters}
                    isEditable
                    handleSaveFilters={newFilters => {
                      EditSavedCandidateFilterStore.submit(user.id, {
                        ...newFilters,
                        id: selectFilterId,
                      }).then(filterId => {
                        setSelectFilterId(filterId);
                      });
                    }}
                    handleFilterChange={handleFilterChanges}
                  />
                </Suspense>
              </Tab>
              <Tab label="Saved" name="Saved">
                <SavedSearches
                  userFilters={userCandidateFilters}
                  handleFilterLoad={newFilters => {
                    setSelectFilterId(newFilters.id);
                    handleFilterChanges(newFilters.filters);
                  }}
                  handleRenderFilter={handlerRenderFilter}
                />
              </Tab>
            </Tabs>
          </Gutter>
        );
      }}
    />
  );
};
