import Ajv from 'ajv';
import {
  HUBSPOT_ACTIONS,
  HUBSPOT_PARAMS,
  HUBSPOT_TYPE,
  CandidateOpportunitiesConst,
} from '@axiom/const';

import { axiomValidationOptions } from './options';

const ajv = new Ajv({
  ...axiomValidationOptions(),
  allErrors: false,
  coerceTypes: true,
  additionalProperties: false,
});

const hubspotEmailValidation = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: '#schemas/hubspot-email',
  oneOf: [
    { $ref: '#/definitions/baseEmail' },
    { $ref: '#/definitions/candidateOffWaitlist' },
    { $ref: '#/definitions/callToAction' },
    { $ref: '#/definitions/callToActionPassing' },
    { $ref: '#/definitions/candidateOpportunityInterestedRejection' },
    { $ref: '#/definitions/candidateOpportunityInterested' },
    { $ref: '#/definitions/contactSignUpAdminNotif' },
    { $ref: '#/definitions/contactSignUpExistingUser' },
    { $ref: '#/definitions/envoyCandidateOpportunityInterested' },
    { $ref: '#/definitions/exTalentAvailabilityIncrease' },
    { $ref: '#/definitions/exTalentPendingBeach' },
    { $ref: '#/definitions/initialReviewProfile' },
    { $ref: '#/definitions/forgotPassword' },
    { $ref: '#/definitions/jobActivity' },
    { $ref: '#/definitions/magicLink' },
    { $ref: '#/definitions/newTalentEngaged' },
    { $ref: '#/definitions/newUserSetPassword' },
    { $ref: '#/definitions/resetPassword' },
    { $ref: '#/definitions/scheduledOpportunityFeedsEmail' },
    { $ref: '#/definitions/sendAvailabilityUpdate' },
    { $ref: '#/definitions/setPassword' },
    { $ref: '#/definitions/shareProject' },
    { $ref: '#/definitions/soonestEngagementRollOffDate' },
    { $ref: '#/definitions/talentActivity' },
    { $ref: '#/definitions/talentJobActivity' },
    { $ref: '#/definitions/talkSubmissionConfirm' },
    { $ref: '#/definitions/welcomePart3' },
    { $ref: '#/definitions/talentDirectExpressedEmail' },
  ],
  definitions: {
    // For Hubspot emails with NO template/params
    baseEmail: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: {
          enum: [
            HUBSPOT_ACTIONS.blockedSignupDomain,
            HUBSPOT_ACTIONS.contactSignUpNewUser,
            HUBSPOT_ACTIONS.unknownUser,
          ],
        },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        from: {
          type: 'string',
        },
        cc: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        bcc: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
      },
      required: ['action', 'type', 'emails'],
    },
    candidateOffWaitlist: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.candidateOffWaitlist },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            formattedSendDate: {
              type: 'string',
            },
            largeTitleText: {
              type: 'string',
            },
            messageBody: {
              type: 'string',
            },
            ctaText: {
              type: 'string',
            },
            ctaLink: {
              type: 'string',
            },
          },
          required: [
            'formattedSendDate',
            'largeTitleText',
            'messageBody',
            'ctaText',
            'ctaLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    envoyCandidateOpportunityInterested: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.envoyCandidateOpportunityInterested },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            displayName: {
              type: 'string',
            },
            firstName: {
              type: 'string',
            },
            lastName: {
              type: 'string',
            },
            opportunityName: {
              type: 'string',
            },
            opportunityId: {
              type: 'string',
            },
            createdAt: {
              type: 'string',
            },
            opportunityLink: {
              type: 'string',
            },
            talentLink: {
              type: 'string',
            },
          },
          required: [
            'displayName',
            'firstName',
            'lastName',
            'opportunityName',
            'opportunityId',
            'createdAt',
            'opportunityLink',
            'talentLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    callToAction: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.callToAction },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            companyName: {
              type: 'string',
            },
            requestType: {
              type: 'string',
            },
            message: {
              type: 'string',
            },
            opportunityName: {
              type: 'string',
            },
            activityDate: {
              type: 'string',
            },
            salesforceId: {
              type: 'string',
            },
            opportunityLink: {
              type: 'string',
            },
          },
          required: [
            'companyName',
            'requestType',
            'message',
            'opportunityName',
            'activityDate',
            'salesforceId',
            'opportunityLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    callToActionPassing: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.callToActionPassing },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            firstName: {
              type: 'string',
            },
            talentName: {
              type: 'string',
            },
            passReason: {
              type: 'string',
            },
            clientFeedback: {
              type: 'string',
            },
            opportunityName: {
              type: 'string',
            },
            activityDate: {
              type: 'string',
            },
            salesforceId: {
              type: 'string',
            },
            opportunityLink: {
              type: 'string',
            },
          },
          required: [
            'firstName',
            'talentName',
            'passReason',
            'clientFeedback',
            'opportunityName',
            'activityDate',
            'salesforceId',
            'opportunityLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    candidateOpportunityInterestedRejection: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: {
          const: HUBSPOT_ACTIONS.candidateOpportunityInterestedRejection,
        },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            firstName: {
              type: 'string',
            },
            opportunityName: {
              type: 'string',
            },
            companyName: {
              type: 'string',
            },
            lossCode: {
              type: 'string',
              enum: Object.values(
                CandidateOpportunitiesConst.CandidateOpportunityLossReasonMessages
              ),
            },
            ctaText: {
              type: 'string',
            },
            ctaLink: {
              type: 'string',
            },
          },
          required: [
            'firstName',
            'opportunityName',
            'companyName',
            'lossCode',
            'ctaText',
            'ctaLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    candidateOpportunityInterested: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.candidateOpportunityInterested },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            talentFirstName: {
              type: 'string',
            },
            opportunityName: {
              type: 'string',
            },
            viewOpportunityFeedLink: {
              type: 'string',
            },
          },
          required: [
            'talentFirstName',
            'opportunityName',
            'viewOpportunityFeedLink',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    contactSignUpAdminNotif: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.contactSignUpAdminNotif },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            firstName: {
              type: 'string',
            },
            lastName: {
              type: 'string',
            },
            contactEmail: {
              type: 'string',
            },
            companyName: {
              type: 'string',
            },
          },
          required: ['firstName', 'lastName', 'contactEmail', 'companyName'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    contactSignUpExistingUser: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.contactSignUpExistingUser },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            loginUrl: { type: 'string' },
          },
          required: ['loginUrl'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    exTalentAvailabilityIncrease: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.exTalentAvailabilityIncrease },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            profilePhoto: {
              type: ['string', 'null'],
            },
            firstName: {
              type: 'string',
            },
            lastName: {
              type: 'string',
            },
            position: {
              type: 'null',
            },
            displayName: {
              type: 'string',
            },
            profile_summary: {
              type: 'string',
            },
            viewBio: {
              type: 'string',
            },
            updateNotificationPreferences: {
              type: 'string',
            },
            // TODO
            requestRate: {
              type: 'string',
            },
            hours: {
              type: 'number',
            },
          },
          required: [
            'profilePhoto',
            'firstName',
            'lastName',
            'position', // default to null until removed
            'displayName',
            'profile_summary',
            'viewBio',
            'updateNotificationPreferences',
            'requestRate',
            'hours',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    exTalentPendingBeach: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.exTalentPendingBeach },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            profilePhoto: {
              type: ['string', 'null'],
            },
            firstName: {
              type: 'string',
            },
            lastName: {
              type: 'string',
            },
            position: {
              type: 'null',
            },
            displayName: {
              type: 'string',
            },
            profile_summary: {
              type: 'string',
            },
            viewBio: {
              type: 'string',
            },
            updateNotificationPreferences: {
              type: 'string',
            },
            requestInfo: {
              type: 'string',
            },
          },
          required: [
            'profilePhoto',
            'firstName',
            'lastName',
            'position', // default to null until removed
            'displayName',
            'profile_summary',
            'viewBio',
            'updateNotificationPreferences',
            'requestInfo',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    forgotPassword: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.forgotPassword },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            passwordLink: {
              type: 'string',
            },
            magicLink: {
              type: 'string',
            },
          },
          required: ['passwordLink', 'magicLink'],
        },
      },
      required: ['action', 'type', 'emails', 'userId', HUBSPOT_PARAMS],
    },
    jobActivity: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.jobActivity },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            // TODO
            activityDate: {
              type: 'string',
            },
            activityOriginator: {
              type: 'string',
              format: 'uuid',
            },
            description: {
              type: 'string',
            },
            eventAction: {
              type: 'string',
            },
            purpose: {
              type: 'string',
            },
            subjectLine: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
            opportunityActivityLink: {
              type: 'string',
            },
            jobName: {
              type: 'string',
            },
            salesforceId: {
              type: 'string',
              format: 'uuid',
            },
          },
          required: [
            'activityDate',
            'activityOriginator',
            'description',
            'eventAction',
            'purpose',
            'subjectLine',
            'type',
            'opportunityActivityLink',
            'jobName',
            'salesforceId',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    initialReviewProfile: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.initialReviewProfile },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        from: {
          type: 'string',
        },
        cc: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            firstName: {
              type: 'string',
            },
            signInLink: {
              type: 'string',
            },
          },
          required: ['firstName', 'signInLink'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    magicLink: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.magicLink },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            magicLink: {
              type: 'string',
            },
          },
          required: ['magicLink'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    newTalentEngaged: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.newTalentEngaged },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            profilePhoto: {
              type: ['string', 'null'],
            },
            firstName: {
              type: 'string',
            },
            lastName: {
              type: 'string',
            },
            position: {
              type: 'null',
            },
            profile_summary: {
              type: 'string',
            },
            client: {
              type: 'string',
            },
            viewBio: {
              type: 'string',
            },
            updateNotificationPreferences: {
              type: 'string',
            },
            viewBench: {
              type: 'string',
            },
          },
          required: [
            'profilePhoto',
            'firstName',
            'lastName',
            'position', // default to null until removed
            'profile_summary',
            'client',
            'viewBio',
            'updateNotificationPreferences',
            'viewBench',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    newUserSetPassword: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.newUserSetPassword },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            displayName: {
              type: 'string',
            },
          },
          required: ['displayName'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS, 'userId'],
    },
    resetPassword: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.resetPassword },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['action', 'type', 'emails', 'userId'],
    },
    scheduledOpportunityFeedsEmail: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.scheduledOpportunityFeedsEmail },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            oppCards: {
              type: 'string',
            },
            manageAlertsUrl: {
              type: 'string',
            },
            notificationFrequency: {
              type: 'string',
            },
          },
          required: ['oppCards', 'manageAlertsUrl', 'notificationFrequency'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    sendAvailabilityUpdate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.sendAvailabilityUpdate },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            desiredWeeklyMaxHours: {
              type: 'string',
            },
            engagedHours: {
              type: 'string',
            },
            confirmUrl: {
              type: 'string',
            },
            updateUrl: {
              type: 'string',
            },
          },
          required: [
            'desiredWeeklyMaxHours',
            'engagedHours',
            'confirmUrl',
            'updateUrl',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    setPassword: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.setPassword },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            passwordLink: {
              type: 'string',
            },
          },
          required: ['passwordLink'],
        },
      },
      required: ['action', 'type', 'emails', 'userId', HUBSPOT_PARAMS],
    },
    shareProject: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.shareProject },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            name: {
              type: 'string',
            },
            projectName: {
              type: 'string',
            },
            message: {
              type: 'string',
            },
            projectUrl: {
              type: 'string',
            },
          },
          required: ['name', 'projectName', 'message', 'projectUrl'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    soonestEngagementRollOffDate: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.soonestEngagementRollOffDate },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            viewOpportunityFeedLink: {
              type: 'string',
            },
          },
          required: ['viewOpportunityFeedLink'],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    talentActivity: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.talentActivity },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            activityDate: {
              type: 'string',
            },
            activityOriginator: {
              type: 'string',
              format: 'uuid',
            },
            description: {
              type: 'string',
            },
            eventAction: {
              type: 'string',
            },
            purpose: {
              type: 'string',
            },
            subjectLine: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
            talentActivityLink: {
              type: 'string',
            },
            talentName: {
              type: 'string',
            },
          },
          required: [
            'activityDate',
            'activityOriginator',
            'description',
            'eventAction',
            'purpose',
            'subjectLine',
            'type',
            // talent-specific activity fields
            'talentActivityLink',
            'talentName',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    talentJobActivity: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.talentJobActivity },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            activityDate: {
              type: 'string',
            },
            activityOriginator: {
              type: 'string',
              format: 'uuid',
            },
            description: {
              type: 'string',
            },
            eventAction: {
              type: 'string',
            },
            purpose: {
              type: 'string',
            },
            subjectLine: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
            talentActivityLink: {
              type: 'string',
            },
            talentName: {
              type: 'string',
            },
            opportunityActivityLink: {
              type: 'string',
            },
            jobName: {
              type: 'string',
            },
            salesforceId: {
              type: 'string',
              format: 'uuid',
            },
          },
          required: [
            'activityDate',
            'description',
            'eventAction',
            'purpose',
            'subjectLine',
            'type',
            // talent-specific activity fields
            'talentActivityLink',
            'talentName',
            // job-specific activity fields
            'opportunityActivityLink',
            'jobName',
            'salesforceId',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    // TODO: rename to 'talkRequestClient'
    talkSubmissionConfirm: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.talkSubmissionConfirm },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        userId: {
          type: 'string',
          format: 'uuid',
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            lawyerFirstName: {
              type: 'string',
            },
            lawyerLastName: {
              type: 'string',
            },
            requestTitle: {
              type: 'string',
            },
            requestDescription: {
              type: 'string',
            },
          },
          required: [
            'lawyerFirstName',
            'lawyerLastName',
            'requestTitle',
            'requestDescription',
          ],
        },
      },
      required: ['action', 'type', 'emails', HUBSPOT_PARAMS],
    },
    welcomePart3: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.welcomePart3 },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        from: {
          type: 'string',
        },
        cc: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            experienceReqs: {
              type: 'string',
            },
            link: {
              type: 'string',
            },
            manager: {
              type: 'string',
            },
            managerEmail: {
              type: 'string',
            },
            digitalLink: {
              type: 'string',
            },
          },
          required: [
            'experienceReqs',
            'link',
            'manager',
            'managerEmail',
            'digitalLink',
          ],
        },
      },
      required: ['type', 'action', 'emails', 'from', 'cc', HUBSPOT_PARAMS],
    },
    talentDirectExpressedEmail: {
      type: 'object',
      additionalProperties: false,
      properties: {
        type: { const: HUBSPOT_TYPE },
        action: { const: HUBSPOT_ACTIONS.talentDirectExpressedEmail },
        emails: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        bcc: {
          type: 'array',
          items: {
            type: 'string',
            minLength: 1,
          },
        },
        [HUBSPOT_PARAMS]: {
          type: 'object',
          additionalProperties: false,
          properties: {
            talentFirstName: {
              type: 'string',
            },
            talentFullName: {
              type: 'string',
            },
            imageUrl: {
              type: 'string',
            },
            talentRole: {
              type: 'string',
            },
            talentExperience: {
              type: 'string',
            },
            talentRate: {
              type: 'string',
            },
            talentEducation: {
              type: 'string',
            },
            talentAdmissions: {
              type: 'string',
            },
            viewProfileLink: {
              type: 'string',
            },
            talentNote: {
              type: 'string',
            },
            talentDescription: {
              type: 'string',
            },
            oppName: {
              type: 'string',
            },
          },
          required: [
            'talentFirstName',
            'talentFullName',
            'talentRole',
            'talentExperience',
            'talentRate',
            'talentEducation',
            'viewProfileLink',
            'talentNote',
            'oppName',
          ],
        },
      },
      required: ['type', 'action', 'emails', HUBSPOT_PARAMS],
    },
  },
};

export const sendHubspotEmailValidator = ajv.compile(hubspotEmailValidation);
