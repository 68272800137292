import { isNumber, round } from 'lodash';
import { CandidateOpportunitiesConst } from '@axiom/const';

const { CompensationTypes } = CandidateOpportunitiesConst;
const nullishTransform = (value, defaultValue) =>
  value === null || value === undefined ? defaultValue : value;

export const CandidateOpportunityUtils = {
  baseHourlyUnavailable: candidateOpportunity =>
    !candidateOpportunity ||
    Number.isNaN(candidateOpportunity.baseHourlyCompensation) ||
    candidateOpportunity.baseHourlyCompensation <= 0,
  hasSubmittedRate: opportunityCandidate =>
    !!(opportunityCandidate && opportunityCandidate.displayBillingRate),
  isWithinMargin: candidate => {
    if (!candidate) return false;
    const { marginApprovalFloor, proposedMarginTarget } = candidate;
    if (!marginApprovalFloor || !proposedMarginTarget) return false;
    return proposedMarginTarget >= marginApprovalFloor;
  },
  isOverMargin: candidate => {
    if (!candidate) return false;
    const { marginApprovalCeiling, proposedMarginTarget } = candidate;
    if (!marginApprovalCeiling || !proposedMarginTarget) return false;
    return proposedMarginTarget > marginApprovalCeiling;
  },
  // Get Hourly Compensation based on Annual Salary and Work Schedule and Premium and Exchange Rate
  calculateBaseHourlyCompensation: ({
    candidateBaseAnnualSalary,
    candidateWorkscheduleAnnualHours,
    productPremiumRateMultiple,
    exchangeRate,
  } = {}) =>
    nullishTransform(candidateBaseAnnualSalary, false) !== false &&
    candidateWorkscheduleAnnualHours && // Must not be div 0 or undefined or null
    nullishTransform(productPremiumRateMultiple, false) !== false &&
    nullishTransform(exchangeRate, false) !== false
      ? round(
          (candidateBaseAnnualSalary / candidateWorkscheduleAnnualHours) *
            productPremiumRateMultiple *
            exchangeRate,
          2
        )
      : null,
  // NOTE: also update dark-roast/api-portal/.../axiom/services/CandidatesOpportunityService.java when logic changes
  // Get Proposed Margin based on Proposed Hourly Rate and Compensation and Talent Burden and Bonus and Premium
  calculateProposedMarginTarget: ({
    proposedHourlyRate,
    proposedHourlyCompensation,
    candidateBonusPercentage,
    productBurdenRateMultiple,
  } = {}) =>
    proposedHourlyRate && // Must not be div 0 or undefined or null
    nullishTransform(proposedHourlyCompensation, false) !== false &&
    nullishTransform(candidateBonusPercentage, false) !== false &&
    nullishTransform(productBurdenRateMultiple, false) !== false
      ? round(
          ((proposedHourlyRate -
            proposedHourlyCompensation *
              productBurdenRateMultiple *
              (candidateBonusPercentage / 100 + 1)) *
            100) /
            proposedHourlyRate,
          2
        )
      : null,
  calculateProposedHourlyComp: ({
    proposedHourlyRate,
    proposedMarginTarget,
    productBurdenRateMultiple,
    candidateBonusPercentage,
  }) => {
    if (
      proposedHourlyRate &&
      nullishTransform(proposedMarginTarget, false) !== false &&
      nullishTransform(productBurdenRateMultiple, false) !== false &&
      nullishTransform(candidateBonusPercentage, false) !== false
    ) {
      const margin = proposedMarginTarget / 100;

      return margin !== 1
        ? round(
            (proposedHourlyRate * (1 - margin)) /
              (productBurdenRateMultiple *
                (candidateBonusPercentage / 100 + 1)),
            2
          )
        : null;
    }
    return null;
  },
  // NOTE: also update dark-roast/api-portal/.../axiom/services/CandidatesOpportunityService.java when logic changes
  calculateProposedHourlyRate: ({
    proposedMarginTarget,
    proposedHourlyCompensation,
    productBurdenRateMultiple,
    candidateBonusPercentage,
  }) => {
    if (
      !isNumber(proposedMarginTarget) ||
      !isNumber(proposedHourlyCompensation) ||
      !isNumber(productBurdenRateMultiple) ||
      !isNumber(candidateBonusPercentage)
    ) {
      return null;
    }

    const margin = proposedMarginTarget / 100;
    return margin !== 1
      ? round(
          (proposedHourlyCompensation *
            productBurdenRateMultiple *
            (candidateBonusPercentage / 100 + 1)) /
            (1 - margin),
          2
        )
      : null;
  },
  // NOTE: also update dark-roast/api-portal/.../axiom/services/CandidatesOpportunityService.java when logic changes
  calculateProposedCostTarget: ({
    proposedHourlyCompensation,
    candidateBonusPercentage,
    productBurdenRateMultiple,
  } = {}) =>
    nullishTransform(proposedHourlyCompensation, false) !== false &&
    nullishTransform(candidateBonusPercentage, false) !== false &&
    nullishTransform(productBurdenRateMultiple, false) !== false
      ? round(
          proposedHourlyCompensation *
            (candidateBonusPercentage / 100 + 1) *
            productBurdenRateMultiple,
          2
        )
      : null,
  // NOTE: also update dark-roast/api-portal/.../axiom/services/CandidatesOpportunityService.java when logic changes
  getCompensationType: (candidateOpportunity, position) => {
    if (
      !candidateOpportunity ||
      !position ||
      CandidateOpportunityUtils.baseHourlyUnavailable(candidateOpportunity)
    ) {
      return 'Awaiting Information';
    }

    const { baseHourlyCompensation, proposedHourlyCompensation } =
      candidateOpportunity;

    if (baseHourlyCompensation !== proposedHourlyCompensation) {
      return CompensationTypes.EngagementSpecific;
    }

    return position.isPremium
      ? CompensationTypes.Premium
      : CompensationTypes.Standard;
  },
  getDefaultProposedFromPosition: candidate => {
    const {
      baseHourlyCompensation,
      position,
      productBurdenRateMultiple,
      candidateBonusPercentage,
      marginTargetPct,
    } = candidate;
    if (
      !position ||
      Number.isNaN(parseFloat(position.baselineMargin)) ||
      Number.isNaN(parseFloat(baseHourlyCompensation)) ||
      Number.isNaN(parseFloat(productBurdenRateMultiple)) ||
      Number.isNaN(parseFloat(candidateBonusPercentage))
    ) {
      return {
        proposedMarginTarget: 0,
        proposedHourlyCompensation: 0,
        proposedHourlyRate: 0,
        productBurdenRateMultiple: 0,
        candidateBonusPercentage: 0,
      };
    }

    const candidateDefaults = {
      proposedHourlyCompensation: parseFloat(baseHourlyCompensation),
      proposedMarginTarget: parseFloat(marginTargetPct) || 0,
      productBurdenRateMultiple: parseFloat(productBurdenRateMultiple),
      candidateBonusPercentage: parseFloat(candidateBonusPercentage),
    };

    candidateDefaults.proposedHourlyRate =
      CandidateOpportunityUtils.calculateProposedHourlyRate(candidateDefaults);

    return candidateDefaults;
  },
};
