import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

export const LanguageSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255),
});

const ajv = new Ajv(axiomValidationOptions());

const languageValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
  },
  required: ['name'],
};

export const languageValidator = ajv.compile(languageValidation);
