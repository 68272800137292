import React, { Component } from 'react';
import { func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Header3, WarningIcon } from '@axiom/ui';

import { getBannerMessage } from '../../redux/actions/bannerMessages';
import { selectBannerMessage } from '../../redux/selectors/bannerMessages';
import { window } from '../../utils/global';

import { BannerIconWrapper, StyledBanner, StyledMessage } from './BannerStyles';

class Banner extends Component {
  componentDidMount() {
    const { getBannerMessage: fireGetBannerMessage } = this.props;
    this.fetchInterval = window.setInterval(() => {
      fireGetBannerMessage('envoy');
    }, 60000); // 60 Seconds
  }

  componentWillUnmount() {
    window.clearInterval(this.fetchInterval);
  }

  render() {
    const { bannerMessage } = this.props;

    if (!bannerMessage || !bannerMessage.isActive) {
      return null;
    }

    /**
     * If DB entry doesn't come in with a #
     */
    const backgroundColor = bannerMessage.backgroundColor
      ? `#${bannerMessage.backgroundColor.replace('#', '')}`
      : null;

    const textColor = bannerMessage.textColor
      ? `#${bannerMessage.textColor.replace('#', '')}`
      : null;

    return (
      <StyledBanner backgroundColor={backgroundColor} textColor={textColor}>
        <BannerIconWrapper>
          <WarningIcon
            backgroundColor={textColor}
            color={backgroundColor}
            height={24}
            width={28}
          />
        </BannerIconWrapper>
        <Header3>
          <StyledMessage
            dangerouslySetInnerHTML={{
              __html: bannerMessage.html,
            }}
          />
        </Header3>
      </StyledBanner>
    );
  }
}

Banner.defaultProps = {
  bannerMessage: null,
};

Banner.propTypes = {
  bannerMessage: shape({}),
  getBannerMessage: func.isRequired,
};

const mapStateToProps = state => {
  const bannerMessage = selectBannerMessage(state);
  return {
    bannerMessage,
  };
};

export default connect(mapStateToProps, {
  getBannerMessage,
})(Banner);
