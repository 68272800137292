import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { IconButton } from '../Button/IconButton';
import { Layout } from '../../layout/Layout/Layout';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const ContextMenu = ({
  anchor,
  children,
  className,
  direction = 'right',
  fixed, // Use only if a parent element is chopping off the menu
  fullscreenOn,
  name,
}: {
  anchor?: React.ReactElement;
  children: React.ReactNode;
  className?: string;
  direction?: 'left' | 'right';
  fixed?: boolean;
  fullscreenOn?: 'smallScreen' | 'tablet' | 'mobile';
  name?: string;
}) => {
  return (
    <span
      data-test={name ? `${name}-context-menu` : 'context-menu'}
      className={AttrsHelper.formatClassname(
        'context-menu',
        anchor && 'custom-anchor',
        fixed && 'fixed-menu',
        fullscreenOn && `fullscreen-${fullscreenOn}`
      )}
    >
      <Dropdown
        data-test={name}
        direction={direction}
        floating
        inline={false}
        icon={null}
        className={className}
        trigger={
          <Layout position="middle" name="context-menu-anchor">
            {anchor || (
              <IconButton
                pattern="secondary"
                variation="minimal"
                icon="vertical-ellipsis"
              />
            )}
          </Layout>
        }
        upward={false}
      >
        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    </span>
  );
};
