import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import { FORM_ERROR } from 'final-form';

import { DateUtil } from './date-util';

const ajv = new Ajv({
  allErrors: true,
  coerceTypes: 'string',
  $data: true,
  format: 'full',
  jsonPointers: true,
});

ajv.addKeyword('dateIsAfter', {
  modifying: true,
  schema: true,
  errors: true,
  // using named function for adding custom error message
  validate: function customValidation(
    schema,
    data,
    parentSchema,
    currentDataPath,
    parentDataObject,
    propertyNameInParentObject,
    rootData
  ) {
    const startDate = DateUtil.castAsMoment(rootData[schema.beforeDate]);
    const endDate = DateUtil.castAsMoment(data);

    // custom error message
    customValidation.errors = [
      {
        keyword: 'dateIsAfter',
        message: schema.errorMessage || 'Something is wrong',
        params: { keyword: 'dateIsAfter' },
      },
    ];

    return endDate.isAfter(startDate);
  },
});

ajv.addKeyword('cannotBeInFuture', {
  type: 'string',
  errors: true,
  validate: function customValidation(schema, data) {
    const date = DateUtil.castAsMoment(data);
    const today = DateUtil.castAsMoment(new Date());

    customValidation.errors = [
      {
        keyword: 'cannotBeInFuture',
        message: 'This date cannot be in the future',
        params: { keyword: 'cannotBeInFuture' },
      },
    ];

    return date.isBefore(today);
  },
});

ajv.addKeyword('isNotEmpty', {
  type: 'string',
  errors: true,
  validate: function customValidation(schema, data) {
    customValidation.errors = [
      {
        keyword: 'isNotEmpty',
        message: schema.errorMessage || 'Required',
        params: { keyword: 'isNotEmpty' },
      },
    ];

    return typeof data === 'string' && data.trim() !== '';
  },
});

AjvErrors(ajv);

const translateMessages = error => {
  const { message } = error;

  switch (message) {
    case 'should NOT be shorter than 1 characters': {
      return 'Required';
    }
    case 'should NOT be longer than 1000 characters': {
      return 'Message cannot be over 1000 characters';
    }
    default: {
      return message;
    }
  }
};

export const AjvUtil = {
  compileSchema(ajvSchema) {
    return ajv.compile(ajvSchema);
  },
  isValid: (compiledAjvSchema, data) =>
    AjvUtil.formatValidationForFinalForm(compiledAjvSchema, data) === undefined,
  /**
   * Need to deal with following error messages with array items
   *
   *   // let vObj = {
    //   name: "Required",
    //   testers: [ ce
    //     undefined,
    //     {
    //       name: 'Jip'
    //     }
    //   ],
    // };
   // return vObj;
   */
  formatValidationForFinalForm: (compiledAjvSchema, data) => {
    /**
     * React Final Form is expecting undefined if form is valid
     */
    if (!compiledAjvSchema || compiledAjvSchema(data)) {
      return;
    }

    return AjvUtil.formatValidationErrorsForFinalForm(compiledAjvSchema.errors);
  },
  formatValidationErrorsForFinalForm: errors =>
    errors.reduce((returnObj, error) => {
      let dataPath = error.dataPath.replace(/^[./]/, '');
      if (error.keyword === 'required') {
        const path =
          dataPath.length > 0
            ? `${dataPath}.${error.params.missingProperty}`
            : error.params.missingProperty;
        returnObj[path] = 'Required';
      } else {
        if (dataPath.length === 0) {
          dataPath = FORM_ERROR;
        }
        returnObj[dataPath] = translateMessages(error);
      }
      return returnObj;
    }, {}),
};
