import { getFormValues } from 'redux-form';
import uniqBy from 'lodash/uniqBy';
import { call, put, takeEvery, select, all } from 'redux-saga/effects';

import { DOWNLOAD_USER_TALENT_LIST } from '../constants';
import { getTalentByUserIdWithFilters } from '../../api/user-talent-api';
import { downloadError } from '../actions/download';
import {
  talentByUserIdLoaded,
  talentByUserIdError,
} from '../actions/userTalent';
import { addApiError } from '../actions/app';
import { BENCH_FORM, TALENT_FILTER_OPTIONS, TEAM } from '../../api/constants';
import { downloadTalentList } from '../../api/talent';
import { groupMembersByUserIdSelector } from '../selectors/groups';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { EnvUtil } from '../../utils/env-util';
import { encodeApiObjects } from '../../api/APIHelper';

const intialFilterKeys = new Set(TALENT_FILTER_OPTIONS.map(opt => opt.value));

export function* getTalentByGroupMembersSaga(membersIds = [], query) {
  try {
    const responses = yield all(
      membersIds.map(id =>
        call(getTalentByUserIdWithFilters, {
          ownerUserId: id,
          sort: query.sort,
          filter: 'owner',
        })
      )
    );
    yield all(responses.map(response => put(talentByUserIdLoaded(response))));
  } catch (e) {
    yield put(addApiError(e.applicationError));
  }
}

export function* getUserTalentWithFiltersSaga() {
  try {
    const search = yield select(getFormValues(BENCH_FORM));

    if (!intialFilterKeys.has(search.filter)) {
      const talent = yield call(getTalentByUserIdWithFilters, {
        sort: search.sort,
        ownerUserId: search.filter,
        filter: 'owner',
      });
      yield put(talentByUserIdLoaded(talent));
    } else if (search.filter === TEAM) {
      const userId = yield select(state => PreloadedUserStore.select(state).id);
      const groupMembersByUserId = yield select(groupMembersByUserIdSelector);
      const groupMembers = groupMembersByUserId[userId];
      yield getTalentByGroupMembersSaga(
        groupMembers.map(member => member.id),
        search
      );
    } else {
      const userId = yield select(state => PreloadedUserStore.select(state).id);
      const talent = yield call(getTalentByUserIdWithFilters, {
        ...search,
        ownerUserId: userId,
      });

      // TODO: remove uniqBy once type=collaborator no longer returns duplicates
      talent.data = uniqBy(talent.data, 'id');
      yield put(talentByUserIdLoaded(talent));
    }
  } catch (e) {
    yield put(addApiError(e.applicationError));
    yield put(talentByUserIdError(e));
  }
}

export function* downloadUserTalentSaga(action) {
  const {
    payload: { selectedIds },
  } = action;

  try {
    const search = {
      request: {
        filters: {
          ids: [...selectedIds],
        },
      },
    };

    yield call(downloadTalentList, search);

    const uri = `${EnvUtil.apiEnvoyUrl}/downloads/candidates?${encodeApiObjects(
      search
    )}`;
    window.location.href = uri;
  } catch (e) {
    yield put(downloadError(e));
    yield put(addApiError(e.applicationError));
  }
}

export default function* userTalentSaga() {
  yield takeEvery(DOWNLOAD_USER_TALENT_LIST, downloadUserTalentSaga);
}
