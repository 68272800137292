import React from 'react';

import { HeaderLinkBlock } from './HeaderBarStyles';

export const HeaderBarLink = ({
  children,
  name,
  selected,
}: {
  children: React.ReactNode;
  name?: string;
  selected?: boolean;
}) => (
  <HeaderLinkBlock data-test={name} selected={selected}>
    {children}
  </HeaderLinkBlock>
);
