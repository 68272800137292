import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import {
  ToastUtil,
  CondensedHeader,
  CondensedLarge,
  ModalHeader,
  ModalSection,
  Paragraph,
  ModalFooter,
  Modal,
  Button,
  Gutter,
  Flashy,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { TextCenter } from '../../styled';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { EditSubmissionRateStore } from '../../stores/edit-submission-rate-store';
import { OpportunityCandidatesStore } from '../../stores/opportunity-candidates-store';
import { opportunityShape } from '../../models/opportunities';
import { postSendSubmissionEmail } from '../../api/submissions-legacy-api';
import { OpportunityApi } from '../../api/opportunities-api';

import { SendSubmissionEmailModal } from './SendSubmissionEmailModal';
import SubmissionModalCandidateList from './SubmissionModalCandidateList';
import SubmissionModalSelectedCandidate from './SubmissionModalSelectedCandidate';
import {
  DivSubmissionDetailsBodyWrapper,
  DivTalentListWrapper,
  DivSelectedTalentWrapper,
} from './SubmissionModalStyles';
import { SubmissionModalUrl } from './SubmissionModalUrl';
import { SubmissionModalUnsavedChanges } from './SubmissionModalUnsavedChanges';

class SubmissionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      showUnsavedChangesModal: false,
      linkOption: '',
      isSendEmailDisabled: false,
      showSendEmail: false,
    };
  }

  componentDidMount() {
    const { showConfirm } = this.state;
    if (showConfirm) {
      this.setState({ showConfirm: false });
    }
  }

  handleSendEmailClick = async (submissionId, contactIds) => {
    const { data: usersEmailsSentTo } = await postSendSubmissionEmail(
      submissionId,
      { contactIds }
    );

    this.setState({ isSendEmailDisabled: true });
    this.closeSendEmailModal();
    ToastUtil.add({
      name: `SentSubmissionEmail`,
      type: 'info',
      children: (
        <>
          <CondensedHeader>Submission email sent</CondensedHeader>
          <CondensedLarge>
            The submission email has been sent to
            <ul>
              {usersEmailsSentTo.map(data => (
                <li key={data.calculatedName}>{data.calculatedName}</li>
              ))}
            </ul>
          </CondensedLarge>
        </>
      ),
    });
  };

  selectCandidate = (submission, selectedCandidateId, candidateObj) => {
    EditSubmissionRateStore.load(submission, candidateObj).then(() =>
      EditSubmissionStore.setState({ selectedCandidateId })
    );
  };

  showConfirm = () => {
    this.setState({ showConfirm: true });
  };

  showSendEmailModal = () => {
    this.setState({ showSendEmail: true });
  };

  saveSubmissionCloseConfirm = async editSubmission => {
    this.setState(
      {
        showConfirm: false,
        linkOption: editSubmission.linkOption,
      },
      () => {
        EditSubmissionStore.save(editSubmission);
      }
    );
  };

  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  closeSendEmailModal = () => {
    this.setState({ showSendEmail: false });
  };

  closeSubmissionsModal = (editSubmission, abandonChanges = false) => {
    if (this.isSubmitDisabled(editSubmission) || abandonChanges) {
      EditSubmissionStore.clearState();
      this.setState({ isSendEmailDisabled: false });
    } else {
      this.setState({ showUnsavedChangesModal: true });
    }
  };

  closeUnsavedChangesModal = () => {
    this.setState({ showUnsavedChangesModal: false });
  };

  isSubmitDisabled = (editSubmission, isPublishButton) => {
    if (editSubmission.newSubmissionType !== null && isPublishButton) {
      return false;
    }

    if (!editSubmission) {
      return true;
    }

    return (
      JSON.stringify(
        EditSubmissionStore.transformSubmissionForPatch(
          editSubmission.initialSubmission
        )
      ) ===
      JSON.stringify(
        EditSubmissionStore.transformSubmissionForPatch(editSubmission)
      )
    );
  };

  renderConfirm(editSubmission) {
    const { showConfirm } = this.state;
    if (showConfirm) {
      return (
        <Modal name="PENDO_MANAGE_SUBMISSIONS_MODAL">
          <ModalHeader onClose={this.closeConfirm}>
            {editSubmission.submissionPublishedAt ? 'Update' : 'Save'} Changes
          </ModalHeader>
          <ModalSection>
            <Paragraph>
              {editSubmission.submissionPublishedAt
                ? 'We’ll update the submission with the changes you made.'
                : 'We’ll save the changes you made to the submission.'}
            </Paragraph>
            <Gutter bottom="16px" />
            <Paragraph>
              <Flashy bold>Note: </Flashy>If the submission has already been
              published, these changes will immediately update in the live
              submission that the client has access to.
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button variation="outline" onClick={this.closeConfirm}>
              Cancel
            </Button>
            <Button
              onClick={() => this.saveSubmissionCloseConfirm(editSubmission)}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return null;
  }

  getSelectedCandidate(editSubmission, oppCandidates) {
    return oppCandidates.find(c => c.id === editSubmission.selectedCandidateId);
  }

  renderModalBody(editSubmission, opportunityCandidates) {
    const { readOnly, opportunity } = this.props;
    const { isSendEmailDisabled } = this.state;
    const currentCandidate = this.getSelectedCandidate(
      editSubmission,
      editSubmission.candidates
    );

    const serverCandidate = this.getSelectedCandidate(
      editSubmission,
      opportunityCandidates
    );

    const emptySubmission =
      editSubmission.candidates.length === 0 ||
      !currentCandidate ||
      !serverCandidate;

    return (
      <Modal size="large" name="MANAGE_SUBMISSIONS_MODAL">
        <ModalHeader onClose={() => this.closeSubmissionsModal(editSubmission)}>
          {readOnly ? 'View Submission - Read Only' : 'Manage Submissions'}
        </ModalHeader>
        <ModalSection>
          {emptySubmission ? (
            <TextCenter data-test="NO_CANDIDATES">
              No available talent to submit.
            </TextCenter>
          ) : (
            <>
              <SubmissionModalUrl
                accountId={editSubmission.accountId}
                newSubmissionType={editSubmission.newSubmissionType}
                submissionId={editSubmission.submissionId}
                lastUpdated={editSubmission.submissionLastUpdatedAt}
                readOnly={readOnly}
                submissionPublishedAt={editSubmission.submissionPublishedAt}
              />
              <DivSubmissionDetailsBodyWrapper>
                <DivTalentListWrapper>
                  <SubmissionModalCandidateList
                    submission={editSubmission}
                    selectedCandidateId={currentCandidate.id}
                    onSelectedCandidateChange={candidateId =>
                      this.selectCandidate(
                        editSubmission,
                        candidateId,
                        opportunityCandidates.find(c => c.id === candidateId)
                      )
                    }
                    readOnly={readOnly}
                    opportunity={opportunity}
                  />
                </DivTalentListWrapper>
                <DivSelectedTalentWrapper>
                  {currentCandidate && serverCandidate && (
                    <SubmissionModalSelectedCandidate
                      initialCandidate={serverCandidate}
                      candidate={currentCandidate}
                      submission={editSubmission}
                      shouldOpenWithSetRateTab={editSubmission.showRateTab}
                      opportunity={opportunity}
                      readOnly={readOnly}
                    />
                  )}
                </DivSelectedTalentWrapper>
              </DivSubmissionDetailsBodyWrapper>
            </>
          )}
        </ModalSection>

        <ModalFooter>
          <Button
            onClick={() => this.closeSubmissionsModal(editSubmission)}
            name="CLOSESUBMISSIONMODAL"
            variation="outline"
          >
            {this.isSubmitDisabled(editSubmission) ? 'Close' : 'Cancel'}
          </Button>

          {!emptySubmission && !readOnly ? (
            <Button
              variation="outline"
              disabled={this.isSubmitDisabled(editSubmission)}
              onClick={this.showConfirm}
              name="UPDATESUBMISSIONMODAL"
            >
              Save
            </Button>
          ) : null}
          {!editSubmission.submissionPublishedAt && (
            <Button
              disabled={this.isSubmitDisabled(editSubmission, true)}
              onClick={async () => {
                await OpportunityApi.publishOpportunity(
                  editSubmission.opportunityId
                );
                this.saveSubmissionCloseConfirm(editSubmission);
                EditSubmissionStore.save(editSubmission);
              }}
            >
              Publish
            </Button>
          )}
          {editSubmission.newSubmissionType !== null &&
            editSubmission.submissionPublishedAt && (
              <Button
                disabled={isSendEmailDisabled}
                onClick={() => this.showSendEmailModal()}
                name="SEND_SUBMISSION_EMAIL"
              >
                Send Submission Email
              </Button>
            )}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { editSubmissionState, opportunityCandidatesState, opportunity } =
      this.props;
    const { showSendEmail, linkOption, showUnsavedChangesModal } = this.state;
    return (
      <StoreStateTreatment
        name="submission_modal_state"
        isModalState
        storeState={[editSubmissionState, opportunityCandidatesState]}
        renderLoadedView={({
          data: [editSubmission, opportunityCandidates],
        }) => {
          return (
            <>
              {showSendEmail && (
                <SendSubmissionEmailModal
                  contactsOpportunities={editSubmission.contactsOpportunities}
                  onEmailSubmit={this.handleSendEmailClick}
                  salesforceId={opportunity.salesforceId}
                  onClose={this.closeSendEmailModal}
                  submissionId={editSubmission.submissionId}
                />
              )}
              {this.renderConfirm(editSubmission)}
              {this.renderModalBody(
                editSubmission,
                opportunityCandidates,
                linkOption
              )}
              {showUnsavedChangesModal && (
                <SubmissionModalUnsavedChanges
                  onCancel={this.closeUnsavedChangesModal}
                  onConfirm={() => {
                    this.setState({ showUnsavedChangesModal: false });
                    this.closeSubmissionsModal(editSubmission, true);
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}

SubmissionModal.propTypes = {
  readOnly: bool.isRequired,
  editSubmissionState: EditSubmissionStore.getStateShape().isRequired,
  opportunityCandidatesState:
    OpportunityCandidatesStore.getStateShape().isRequired,
  opportunity: opportunityShape.isRequired,
};

export default connect(state => ({
  editSubmissionState: EditSubmissionStore.select(state),
  opportunityCandidatesState: OpportunityCandidatesStore.select(state),
}))(SubmissionModal);
