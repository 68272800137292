import isPlainObject from 'lodash/isPlainObject';

export const sortStrings = (a, b) => {
  const aString = (typeof a === 'string' ? a : '').trim().toLowerCase();
  const bString = (typeof b === 'string' ? b : '').trim().toLowerCase();
  return aString.localeCompare(bString);
};

export const makeSortBy = (key, asStrings) => {
  if (typeof key !== 'string' || key.trim().length === 0) return null;
  return (a, b) => {
    if (!isPlainObject(a) || !isPlainObject(b)) return 0;
    if (asStrings) return sortStrings(a[key], b[key]);
    if (a === b) return 0;
    return (a || 0) < (b || 0) ? -1 : 1;
  };
};

export const sortByFirstName = makeSortBy('firstName', true);
export const sortByLabel = makeSortBy('label', true);
export const sortByLastName = makeSortBy('lastName', true);
export const sortByName = makeSortBy('name', true);

export const sortUsers = (a, b) =>
  sortByLastName(a, b) || sortByFirstName(a, b);
