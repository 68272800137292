import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';

export const BannerMessageSchema = z.object({
  appName: z.enum(['envoy', 'portal']),
  createdAt: SchemaTimestamp,
  isActive: z.boolean().nullable(),
  html: z.string().nullable(),
  backgroundColor: z.string().max(255).nullable(),
  textColor: z.string().max(255).nullable(),
  updatedAt: SchemaTimestamp,
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

const bannerMessageCommonValidation = {
  additionalProperties: false,
  type: 'object',
  properties: {
    appName: {
      type: ['string'],
      enum: ['envoy', 'portal'],
    },
    isActive: {
      type: ['boolean'],
    },
    html: {
      type: ['string'],
    },
    backgroundColor: {
      type: ['string'],
      maxLength: 6,
    },
    textColor: {
      type: ['string'],
      maxLength: 6,
    },
  },
};

export const createBannerValidator = ajv.compile({
  ...bannerMessageCommonValidation,
  required: ['appName', 'isActive', 'html', 'backgroundColor', 'textColor'],
});

export const updateBannerValidator = ajv.compile({
  ...bannerMessageCommonValidation,
  anyOf: Object.keys(bannerMessageCommonValidation.properties).map(key => ({
    required: [key],
  })),
});
