import {
  DECORATED_OPPORTUNITY_LOADED,
  USER_OPPORTUNITIES_LOAD,
  USER_OPPORTUNITIES_LOADED,
  USER_OPPORTUNITIES_LOAD_ERROR,
} from '../constants';

export const INITIAL_STATE = {
  byUserId: {},
  loading: false,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USER_OPPORTUNITIES_LOAD: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_OPPORTUNITIES_LOADED: {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [payload.id]: payload.data.map(opportunity => opportunity.id),
        },
        loading: false,
      };
    }

    case USER_OPPORTUNITIES_LOAD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case DECORATED_OPPORTUNITY_LOADED: {
      const opportunity = payload.opportunity.data;
      const opportunityId = opportunity.id;
      const ownerId = opportunity.ownerUserId;
      const collabs = payload.collaborators.data;

      const byUserId = Object.keys(state.byUserId).reduce((t, userId) => {
        const opportunityIds = state.byUserId[userId];
        const owned =
          userId === ownerId ||
          collabs.find(collabers => collabers.id === userId);
        if (owned && !opportunityIds.includes(opportunityId)) {
          // opportunity owned by user, but wasn't before
          t[userId] = [...opportunityIds, opportunityId];
        } else if (!owned && opportunityIds.includes(opportunityId)) {
          // opportunity not owned by user, but was before
          t[userId] = opportunityIds.filter(id => id !== opportunityId);
        } else {
          // no change
          t[userId] = opportunityIds;
        }
        return t;
      }, {});

      if (!byUserId[ownerId]) {
        byUserId[ownerId] = [opportunityId];
      }
      collabs.forEach(collaborator => {
        if (!byUserId[collaborator.id]) {
          byUserId[collaborator.id] = [opportunityId];
        }
      });

      return {
        ...state,
        byUserId,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
