import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_POLL_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_ALL_NOTIFICATIONS_SUCCESS,
  UPDATE_ALL_NOTIFICATIONS_ERROR,
} from '../constants';
import formatNotificationBody from '../../components/Notifications/NotificationsUtils';

const INIT_STATE = {
  hasUnread: false,
  list: null,
};

const reducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS:
    case GET_NOTIFICATIONS_POLL_SUCCESS: {
      const {
        notifications: { data, _meta },
        userId,
      } = payload;

      return {
        list: formatNotificationBody(data || []),
        hasUnread: !!_meta.totalUnreadCount,
        userId,
        userAction: false,
      };
    }
    case UPDATE_NOTIFICATION_SUCCESS: {
      const list = payload.notifications.data;

      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === list.id) {
            item = {
              ...item,
              ...list,
            };
          }

          return item;
        }),
      };
    }
    case UPDATE_ALL_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        list: state.list.map(item => {
          item.isRead = true;
          return item;
        }),
        hasUnread: false,
        userAction: false,
      };
    }
    case GET_NOTIFICATIONS_ERROR:
    case UPDATE_NOTIFICATION_ERROR:
    case UPDATE_ALL_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
