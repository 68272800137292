import { CandidatesConst } from '@axiom/const';

import { CandidateUtil } from '../../utils/candidate-util';

const { EmployeeTypes, WorkPreferences } = CandidatesConst;

export const talentProfileStatusSelectItems =
  CandidateUtil.getProfileStatusSelectItems();

const formatOptions = options => options.map(o => ({ value: o, label: o }));

export const jobProfileOptions = formatOptions([
  'Lawyer',
  'Legal Support',
  'Lawyer-Flex',
]);

export const employeeTypeOptions = formatOptions(Object.values(EmployeeTypes));

export const workPreferencesOptions = Object.entries(WorkPreferences).map(
  ([value, label]) => ({
    label,
    value,
  })
);
