import isObjectLike from 'lodash/isObjectLike';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

export const ObjectUtil = {
  clone(obj) {
    if (!obj) {
      return obj;
    }
    return structuredClone(obj);
  },
  getStructureChanges(initialData, finalData) {
    /**
     * For any non object data, we're just gonna send back out.
     * Arrays especially, we usually dont deal with changed arrays on the server (like index added/deleted)
     * so when we send arrays, the server usually assumes its the complete change set
     * and will add/remove/delete rows as needed
     */
    if (!initialData || !isObjectLike(initialData)) {
      return finalData;
    }
    if (!isObjectLike(finalData)) {
      return finalData;
    }

    const allKeys = uniq([
      ...Object.keys(initialData),
      ...Object.keys(finalData),
    ]);
    return allKeys.reduce((acc, propName) => {
      const initPropValue = get(initialData, propName, null);
      const dataPropValue = get(finalData, propName, null);

      if (initPropValue !== dataPropValue) {
        acc[propName] = dataPropValue;
      }

      return acc;
    }, {});
  },
};
