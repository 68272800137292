import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

export const GroupSchema = z.object({
  id: z.string().uuid(),
  name: z.string().max(255),
  parentId: z.string().uuid(),
  path: z.string().uuid(),
});

export const createGroupValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    parentId: { type: 'string', format: 'uuid' },
    name: { type: 'string', minLength: 1 },
  },
  required: ['name'],
};

export const updateGroupValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
      minLength: 1,
    },
  },
  anyOf: [{ required: ['name'] }],
};

export const createGroupValidator = ajv.compile(createGroupValidation);
export const updateGroupValidator = ajv.compile(updateGroupValidation);
