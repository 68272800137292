import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, bool, number } from 'prop-types';
import { CandidateOpportunitiesConst, EventsConst } from '@axiom/const';
import {
  CaretIcon,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  ContextMenuItem,
  DataTest,
  Header4,
  IconButton,
} from '@axiom/ui';
import {
  DEFAULT_ALLOWED_TRANSITIONS,
  candidateOpportunityStatusUtils,
  CandidateOpportunityUtils,
} from '@axiom/utils';

import { Draggable } from '../Draggable/Draggable';
import { Droppable } from '../Droppable/Droppable';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { AutoCoolingModal } from '../AutoCoolingModal/AutoCoolingModal';
import { RejectionModal } from '../RejectionModal/RejectionModal';
import { window } from '../../utils/global';
import { opportunityCandidatesEdit } from '../../redux/actions/opportunities';
import { PositionUtils } from '../../utils/position-utils';
import { positionShape } from '../../models/position';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';
import { EditAutoWarmingModalStore } from '../../stores/edit-auto-warming-modal-store';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EventSubjectConst } from '../../stores/events-store';
import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import { PreloadedAppErrorsStore } from '../../stores/preloaded-app-errors-store';
import { FormAutoCoolingModalStore } from '../../stores/form-auto-cooling-modal-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

import { isCandidateDraggable } from './OpportunityTalentUtils';
import { positionContextMenuStatusLabels } from './OpportunityTalentConstants';
import OpportunityTalentCard from './OpportunityTalentCard';
import {
  OTDraggableCardWrapper,
  OTDropzonePlaceholder,
  OTDropzonePlaceholderButton,
} from './OpportunityTalentStyles';
import {
  OTPositionWrapper,
  OTPositionHeader,
  OTPositionHeaderLeft,
  OTPositionHeaderLeftCheckmark,
  OTPositionHeaderRight,
  OTPositionBody,
  OTPositionTalentDrawer,
} from './OpportunityTalentPositionStyles';

const { CandidateStatuses } = CandidateOpportunitiesConst;

export const PREVIEW_TRANSITIONS = {
  [CandidateStatuses.Cooled]: DEFAULT_ALLOWED_TRANSITIONS.Cooled,
};

class OpportunityTalentPosition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: !!props.position.candidates?.length,
      autoCoolingCandidateId: null,
      isRejectionModalOpen: false,
      rejectCandidateId: null,
      rejectOpportunityId: null,
      rejectCandidateStatusOption: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { isHovered } = this.props;
    if (isHovered !== prevProps.isHovered) {
      if (isHovered) {
        this.hoverDelayOpenTimeout = window.setTimeout(() => {
          this.setState({ isExpanded: true });
        }, 1000);
      } else {
        window.clearInterval(this.hoverDelayOpenTimeout);
      }
    }
  }

  toggleIsExpanded = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  toggleRejectionModal = (
    isRejectionModalOpen,
    rejectCandidateId = null,
    rejectOpportunityId = null,
    rejectCandidateStatusOption = null
  ) => {
    this.setState({
      isRejectionModalOpen,
      rejectCandidateId,
      rejectOpportunityId,
      rejectCandidateStatusOption,
    });
  };

  renderTalentCard = ({
    candidate,
    position,
    opportunity,
    candidates,
    roleFitDescriptions,
    user,
  }) => {
    // Although WarmedUnsure is a valid transition from Warmed, it is not a selectable transition in AHQ
    const candidateStatusOptions = candidateOpportunityStatusUtils
      .getAllowedTransitions({
        currentCandidateStatus: candidate.candidateStatus,
        opportunity,
        position,
      })
      .filter(statusOption => statusOption !== CandidateStatuses.WarmedUnsure);

    // While another candidate is selected for the position, show possible status transitions available once the other
    // candidate is moved out of selected
    const previewStatusOptions =
      PREVIEW_TRANSITIONS[candidate.candidateStatus] || [];

    const isAnotherCandidateSelected =
      candidate.candidateStatus !== CandidateStatuses.Selected &&
      candidates.some(
        ({ candidateStatus }) => candidateStatus === CandidateStatuses.Selected
      );

    return (
      <OpportunityTalentCard
        user={user}
        candidate={candidate}
        opportunity={opportunity}
        position={position}
        opportunityCandidates={candidates}
        roleFitDescriptions={roleFitDescriptions}
        renderContextMenu={() => (
          <ContextMenu
            direction="left"
            anchor={
              <IconButton
                icon="vertical-ellipsis"
                inverse
                variation="minimal"
                pattern="secondary"
              />
            }
          >
            {isAnotherCandidateSelected &&
              opportunity.isFulfillmentActive &&
              previewStatusOptions.length > 0 && (
                <>
                  <ContextMenuHeader>Update Status</ContextMenuHeader>
                  {previewStatusOptions.map(statusOption => (
                    <ContextMenuItem
                      key={`update-status${statusOption}`}
                      disabled
                    >
                      {positionContextMenuStatusLabels[statusOption]}
                    </ContextMenuItem>
                  ))}
                  <ContextMenuDivider />
                </>
              )}
            {(candidateStatusOptions.length > 0 ||
              candidate.candidateStatus === CandidateStatuses.WarmedYes) && (
              <>
                <ContextMenuHeader>Update Status</ContextMenuHeader>
                {candidate.candidateStatus === CandidateStatuses.WarmedYes && (
                  <ContextMenuItem
                    onClick={() => {
                      EditSubmissionStore.load(
                        opportunity.submissionId,
                        opportunity.id,
                        candidate.id,
                        false
                      );
                    }}
                  >
                    Submit
                  </ContextMenuItem>
                )}
                {candidateStatusOptions.map(statusOption => (
                  <ContextMenuItem
                    onClick={() => {
                      switch (statusOption) {
                        case CandidateStatuses.Cooled: {
                          FormAutoCoolingModalStore.load({
                            candidateId: candidate.id,
                            opportunityId: opportunity.id,
                            candidateStatus: CandidateStatuses.Cooled,
                          });
                          this.setState({
                            autoCoolingCandidateId: candidate.id,
                          });

                          break;
                        }
                        case CandidateStatuses.Warmed: {
                          EditAutoWarmingModalStore.load({
                            opportunityId: this.props.editTalent.opportunity.id,
                            candidateId: candidate.id,
                            candidateStatus: CandidateStatuses.Warmed,
                            candidateStatusMessage: `We think you'd be a great fit for an opportunity we're working on. Please review the information below and indicate if you'd like us to share your profile with the client.`,
                          });

                          break;
                        }
                        case CandidateStatuses.InterestedRejected: {
                          this.toggleRejectionModal(
                            true,
                            candidate.id,
                            this.props.editTalent.opportunity.id,
                            statusOption
                          );

                          break;
                        }
                        default: {
                          if (statusOption === CandidateStatuses.Interviewing) {
                            if (!opportunity.isQualificationComplete) {
                              PreloadedAppErrorsStore.show(
                                'Please qualify this opportunity before setting talent status to Interview.'
                              );

                              return;
                            }

                            AppEventItemModalStore.openModal(
                              EventSubjectConst.Opportunity,
                              {
                                candidateId: candidate.id,
                                opportunityId: opportunity.id,
                                type: EventsConst.Types.Interview,
                                purpose:
                                  EventsConst.Purposes.OpportunityInterview,
                              }
                            );
                          }

                          EditOpportunityTalentTabStore.saveOpportunityCandidate(
                            {
                              candidateStatus: statusOption,
                              candidateId: candidate.id,
                              opportunityId: opportunity.id,
                            }
                          );
                        }
                      }
                    }}
                    key={`update-status${statusOption}`}
                    disabled={
                      statusOption === CandidateStatuses.Selected &&
                      (!CandidateOpportunityUtils.hasSubmittedRate(candidate) ||
                        CandidateOpportunityUtils.baseHourlyUnavailable(
                          candidate
                        ))
                    }
                  >
                    {positionContextMenuStatusLabels[statusOption]}
                  </ContextMenuItem>
                ))}
                <ContextMenuDivider />
              </>
            )}
            <ContextMenuItem
              onClick={() => {
                EditSubmissionStore.load(
                  opportunity.submissionId,
                  opportunity.id,
                  candidate.id,
                  true
                );
              }}
              disabled={
                !EditSubmissionStore.isRateSettableForCandidate(
                  candidate,
                  opportunity
                )
              }
            >
              Set Rate
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                AppEventItemModalStore.openModal(
                  EventSubjectConst.Opportunity,
                  {
                    candidateId: candidate.id,
                    opportunityId: opportunity.id,
                  }
                );
              }}
            >
              Add Activity
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                window.open(`/talent-detail/${candidate.id}`, '_blank').focus();
              }}
            >
              Go to Talent
            </ContextMenuItem>
          </ContextMenu>
        )}
      />
    );
  };

  renderPosition = ({ placeholder }) => {
    const {
      position,
      editTalent: { opportunity, positions, candidates, roleFitDescriptions },
      positionIndex,
      user,
    } = this.props;

    return (
      <OTPositionWrapper data-test="OPPORTUNITYTALENTPOSITION">
        <OTPositionHeader onClick={this.toggleIsExpanded}>
          <OTPositionHeaderLeft>
            <OTPositionHeaderLeftCheckmark
              checked={PositionUtils.isPositionFulfilled(position.candidates)}
              data-test="POSITIONCHECKMARK"
            />
            <Header4>
              Position {positionIndex + 1}:{' '}
              <DataTest value="opportunityTalentPositionName" inline>
                {position.name}
              </DataTest>{' '}
              (
              <DataTest value="opportunityTalentPositionCandidateAmount" inline>
                {position.candidates?.length}
              </DataTest>
              )
            </Header4>
          </OTPositionHeaderLeft>
          <OTPositionHeaderRight>
            <CaretIcon
              direction={this.state.isExpanded ? 'down' : 'right'}
              name="expand carrot"
            />
          </OTPositionHeaderRight>
        </OTPositionHeader>
        <OTPositionBody>
          {!this.state.isExpanded && placeholder}
          {this.state.isExpanded && (
            <OTPositionTalentDrawer>
              <Draggable
                id={`dummy-draggable-${position.id}`}
                i={0}
                isDragDisabled
                render={() => <div />}
              />
              {position.candidates?.map((candidate, i) => (
                <OTDraggableCardWrapper
                  key={candidate.id}
                  id={candidate.id}
                  i={i + 1}
                  isDragDisabled={
                    !isCandidateDraggable({
                      candidate,
                      positions,
                      opportunity,
                    })
                  }
                  candidate={candidate}
                  position={position}
                  render={() =>
                    this.renderTalentCard({
                      candidate,
                      position,
                      opportunity,
                      candidates,
                      roleFitDescriptions,
                      user,
                    })
                  }
                />
              ))}
              {placeholder}
              <OTDropzonePlaceholder>
                <OTDropzonePlaceholderButton
                  onClick={() =>
                    AppFindCandidateForOppModalStore.beginAddingToPosition(
                      opportunity.id,
                      positions,
                      position.id
                    )
                  }
                  disabled={!opportunity.isFulfillmentActive}
                  title={
                    opportunity.isFulfillmentActive
                      ? null
                      : `This opportunity's fulfillment is not active.`
                  }
                  data-test="DROPZONE"
                >
                  <div>Drag and Drop</div>
                  <div>or</div>
                  <div className="gtm-candidate-log-add-candidate">
                    Click To Add
                  </div>
                </OTDropzonePlaceholderButton>
              </OTDropzonePlaceholder>
              <FormStateTreatment
                name="COOL_MODAL_TREATMENT"
                isModalState
                dataId={this.state.autoCoolingCandidateId}
                dataIdName="candidateId"
                formStore={FormAutoCoolingModalStore}
                renderLoadedView={({ fireSubmit, fireCancel, formData }) => {
                  return (
                    <AutoCoolingModal
                      form={formData}
                      onCancel={fireCancel}
                      onSend={fireSubmit}
                    />
                  );
                }}
              />
              {this.state.isRejectionModalOpen && (
                <RejectionModal
                  candidateId={this.state.rejectCandidateId}
                  opportunityId={this.state.rejectOpportunityId}
                  candidateStatus={this.state.rejectCandidateStatusOption}
                  onClose={() => this.toggleRejectionModal(false)}
                />
              )}
            </OTPositionTalentDrawer>
          )}
        </OTPositionBody>
      </OTPositionWrapper>
    );
  };

  render() {
    const { position } = this.props;
    return <Droppable id={position.id} render={this.renderPosition} />;
  }
}

OpportunityTalentPosition.defaultProps = {
  isHovered: false,
};
OpportunityTalentPosition.propTypes = {
  position: positionShape.isRequired,
  editTalent: EditOpportunityTalentTabStore.getDataShape().isRequired,
  positionIndex: number.isRequired,
  isHovered: bool,
  positions: arrayOf(positionShape).isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};

export default connect(null, {
  opportunityCandidatesEdit,
})(OpportunityTalentPosition);
