import Ajv from 'ajv';
import { z } from 'zod';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

export const ContactAccountSchema = z.object({
  id: z.string().uuid(),
  accountId: z.string().uuid(),
  contactId: z.string().uuid(),
});

const contactAccountValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    accountId: {
      type: 'string',
      format: 'uuid',
    },
    contactId: {
      type: 'string',
      format: 'uuid',
    },
  },
};

export const contactAccountUpdateValidator = ajv.compile({
  ...contactAccountValidation,
});
