import { call, put, takeEvery } from 'redux-saga/effects';

import { EnvUtil } from '../../utils/env-util';
import { encodeApiObjects } from '../../api/APIHelper';
import { DOWNLOAD_USER_OPPORTUNITY_LIST } from '../constants';
import { downloadError } from '../actions/download';
import { addApiError } from '../actions/app';
import { downloadOpportunityList } from '../../api/opportunities-legacy-api';

export function* downloadUserOpportunitiesSaga(action) {
  const {
    payload: { selectedIds },
  } = action;

  try {
    const search = {
      request: {
        filters: {
          ids: [...selectedIds],
        },
      },
    };

    yield call(downloadOpportunityList, search);

    const uri = `${
      EnvUtil.apiEnvoyUrl
    }/downloads/opportunities?${encodeApiObjects(search)}`;
    window.location.href = uri;
  } catch (e) {
    yield put(downloadError(e));
    yield put(addApiError(e.applicationError));
  }
}

export default function* userOpportunitySaga() {
  yield takeEvery(
    DOWNLOAD_USER_OPPORTUNITY_LIST,
    downloadUserOpportunitiesSaga
  );
}
