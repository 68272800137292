import React, { useMemo, useState } from 'react';
import {
  Button,
  CalendarIcon,
  ContextMenuItem,
  DataGrid,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  useApi,
  PracticeAreaUtil,
  AsyncConnector,
  DataGridHeader,
  FlagIcon,
  CondensedHeader,
  DataGridOnCheckedRowsArg,
  Badge,
} from '@axiom/ui';
import { Candidate, User } from '@axiom/validation';
import { CandidatesConst, PositionsConst } from '@axiom/const';
import { useNavigate } from 'react-router-dom';

import {
  UserTalentApi,
  UserBenchFilterType,
  UserBenchTalentSortKeys,
  UserBenchTalentSort,
  readUserTalentResponseType,
  UserBenchTalentTypes,
} from '../../api/user-talent-api';
import { PracticeAreaApi } from '../../api/practice-area-api';
import { DateUtil } from '../../utils/date-util';
import { AxiomForTalentUtil } from '../../utils/axiom-for-talent';
import { WindowUtil } from '../../utils/window-util';
import { getAnyStyleByStatus } from '../../utils/color';
import { AppFindOppForCandidateModalStore } from '../../stores/app-find-opp-for-candidate-modal-store';
import { downloadTalentList } from '../../api/talent';
import { EnvUtil } from '../../utils/env-util';
import { encodeApiObjects } from '../../api/APIHelper';
import { NumberUtil } from '../../utils/number-util';

const { Confirmed } = PositionsConst.EndDateStatuses;
const hasValue = (
  selectedStatuses: Array<string> = [],
  valueToAdd?: string | string[]
) => {
  if (!valueToAdd) {
    return false;
  }
  if (Array.isArray(valueToAdd)) {
    return (
      valueToAdd.filter(status => !selectedStatuses.includes(status)).length ===
      0
    );
  }
  return selectedStatuses.includes(valueToAdd);
};
export const UserTalentFilterByProfileStatusList = [
  {
    label: 'All',
    value: Object.values(CandidatesConst.ProfileStatuses),
  },
  {
    label: CandidatesConst.ProfileStatusLists.allCertifiedTalent.name,
    value: CandidatesConst.ProfileStatusLists.allCertifiedTalent.value,
  },
  ...[
    CandidatesConst.ProfileStatuses.Certifying,
    CandidatesConst.ProfileStatuses.InDiligence,
    CandidatesConst.ProfileStatuses.Idle,
    CandidatesConst.ProfileStatuses.Waitlist,
    CandidatesConst.ProfileStatuses.PendingActive,
    CandidatesConst.ProfileStatuses.Active,
    CandidatesConst.ProfileStatuses.PendingBeach,
    CandidatesConst.ProfileStatuses.Beach,
    CandidatesConst.ProfileStatuses.PendingAlum,
    CandidatesConst.ProfileStatuses.PendingAlumDNR,
  ].map(profileStatus => ({
    label: profileStatus,
    value: profileStatus,
  })),
];
export const BenchViewGrid = ({
  filters,
  onFilterChanges,
  user,
}: {
  filters: UserBenchFilterType;
  onFilterChanges: (filters?: UserBenchFilterType) => void;
  user: User;
}) => {
  const [resultCount, setResultCount] = useState<number>(0);
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());
  const [checkedRows, setCheckedRows] = useState<DataGridOnCheckedRowsArg>({
    allRowsChecked: false,
    checkedRows: [],
  });

  const navigate = useNavigate();

  const GridConnector = useMemo(
    () =>
      new AsyncConnector({
        getData: args => {
          return UserTalentApi.readUserTalent(user.id, args.page, {
            ...filters,
            sort:
              Object.keys(args.sort).reduce((acc, key) => {
                if (args.sort[key] && !acc) {
                  acc = key;
                }

                return acc;
              }, null) ?? undefined,
          });
        },
        parseData: (results: readUserTalentResponseType) => {
          setResultCount(results?._meta.resultCount);
          return {
            currentPage: results._meta.currentPage,
            totalPages: results._meta.pageCount,
            data: results.data,
          };
        },
      }),
    [user, filters]
  );

  return (
    <>
      <Gutter vertical="8px">
        <CondensedHeader name="TALENT_COUNT">
          {resultCount} Talent
        </CondensedHeader>
      </Gutter>
      <DataGrid
        name="BENCH_GRID"
        displayMode="infinite"
        dataSource={GridConnector}
        onSelectionChanged={rows => {
          if (rows.length > 0) {
            navigate(WindowUtil.appendQueryParams(`/bench/${rows[0].id}`));
          }
        }}
        rowsChecked={checkedRows}
        onCheckboxGroupChanged={rows => {
          setCheckedRows(rows);
        }}
        calculateRowBadge={rowData => {
          return getAnyStyleByStatus(rowData.profileStatus).background;
        }}
      >
        <DataGridHeader
          name="pinned-flagged-talent"
          pinned="left"
          width={60}
          displayName={() => (
            <LayoutItem position="center">
              <FlagIcon filled />
            </LayoutItem>
          )}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            if (!candidateData.isFlagged) {
              return null;
            }
            return (
              <LayoutItem position="center">
                <FlagIcon filled={!!candidateData.isFlagged} />
              </LayoutItem>
            );
          }}
        />
        <DataGridHeader
          name="lastName"
          displayName="Talent"
          pinned="left"
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return candidateData.calculatedDisplayName;
          }}
        />
        <DataGridHeader
          name="profileStatus"
          displayName="Profile status"
          sortingOrder={['asc', null]}
          FiltersRender={() => (
            <>
              {UserTalentFilterByProfileStatusList.map(choice => (
                <ContextMenuItem
                  key={choice.label}
                  onClick={() => {
                    const currentFilters = new Set(filters.profileStatus || []);
                    const valueToAdd = Array.isArray(choice.value)
                      ? choice.value
                      : [choice.value];

                    if (hasValue([...currentFilters], valueToAdd)) {
                      valueToAdd.forEach(status =>
                        currentFilters.delete(status)
                      );
                    } else {
                      valueToAdd.forEach(status => currentFilters.add(status));
                    }

                    onFilterChanges({
                      profileStatus: [...currentFilters],
                    });
                  }}
                  selected={hasValue(filters.profileStatus, choice.value)}
                >
                  {choice.label}
                </ContextMenuItem>
              ))}
            </>
          )}
        />
        <DataGridHeader
          name="talent-ownership"
          displayName="Talent ownership"
          cellRender={({ data }: { data: Candidate }) => {
            if (!filters.type) {
              return data.ownerUserId === user.id
                ? UserBenchTalentTypes.owner
                : UserBenchTalentTypes.collaborator;
            }

            return UserBenchTalentTypes[filters.type];
          }}
          FiltersRender={() => (
            <>
              {Object.keys(UserBenchTalentTypes).map(ownerType => (
                <ContextMenuItem
                  key={ownerType}
                  onClick={() => {
                    onFilterChanges({
                      type: ownerType,
                    });
                  }}
                  selected={filters.type === ownerType}
                >
                  {UserBenchTalentTypes[ownerType]}
                </ContextMenuItem>
              ))}
            </>
          )}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.employmentStatus}
          displayName={
            UserBenchTalentSort[UserBenchTalentSortKeys.employmentStatus]
          }
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.practiceAreaId}
          displayName={
            UserBenchTalentSort[UserBenchTalentSortKeys.practiceAreaId]
          }
          sortingOrder={['asc', null]}
          cellRender={({ data }: { data: Candidate }) => {
            return PracticeAreaUtil.getPracticeAreaAndSpecialty(
              practiceAreas,
              data.practiceAreaId
            )?.practiceArea?.name;
          }}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.weeklyAvailability}
          displayName={
            UserBenchTalentSort[UserBenchTalentSortKeys.weeklyAvailability]
          }
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return NumberUtil.formatAsHours(candidateData.weeklyAvailability);
          }}
        />
        <DataGridHeader
          name="totalEngagedHours"
          displayName="Total engaged hrs"
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            if (
              candidateData.calculatedEngagedAndReservedHours
                ?.totalEngagedHours >= CandidatesConst.EngagedHoursWarningLimit
            ) {
              return (
                <Badge
                  name="ENGAGED_HOURS_WARNING_BADGE"
                  background="red"
                >{`${candidateData.calculatedEngagedAndReservedHours.totalEngagedHours} hrs`}</Badge>
              );
            }
            return `${
              candidateData.calculatedEngagedAndReservedHours
                ?.totalEngagedHours ?? 0
            } hrs`;
          }}
        />
        <DataGridHeader
          name="totalReservedHours"
          displayName="Reserved hrs"
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            if (
              candidateData.calculatedEngagedAndReservedHours
                ?.totalReservedHours >=
              CandidatesConst.ReservedHoursWarningLimit
            ) {
              return (
                <Badge
                  name="RESERVED_HOURS_WARNING_BADGE"
                  background="red"
                >{`${candidateData.calculatedEngagedAndReservedHours.totalReservedHours} hrs`}</Badge>
              );
            }
            return `${
              candidateData.calculatedEngagedAndReservedHours
                ?.totalReservedHours ?? 0
            } hrs`;
          }}
        />
        <DataGridHeader
          width={250}
          name={UserBenchTalentSortKeys.availabilityPreferencesUpdatedAt}
          displayName={
            UserBenchTalentSort[
              UserBenchTalentSortKeys.availabilityPreferencesUpdatedAt
            ]
          }
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            if (candidateData.availabilityPreferencesUpdatedAt) {
              const duration = DateUtil.getDaysSinceNow(
                candidateData.availabilityPreferencesUpdatedAt
              );
              return `${duration} ${duration === 1 ? 'day' : 'days'}`;
            }
            return null;
          }}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.mobilePhone}
          displayName={UserBenchTalentSort[UserBenchTalentSortKeys.mobilePhone]}
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.personalEmail}
          displayName={
            UserBenchTalentSort[UserBenchTalentSortKeys.personalEmail]
          }
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.furthestCandidacyStatus}
          displayName={
            UserBenchTalentSort[UserBenchTalentSortKeys.furthestCandidacyStatus]
          }
          sortingOrder={['asc', null]}
        />
        <DataGridHeader
          name={
            UserBenchTalentSortKeys.alphabeticallySmallestEngagementAccountName
          }
          displayName={
            UserBenchTalentSort[
              UserBenchTalentSortKeys
                .alphabeticallySmallestEngagementAccountName
            ]
          }
          sortingOrder={['asc', null]}
          cellRender={({ data }: { data: Candidate }) => {
            return filters.sort ===
              UserBenchTalentSortKeys.alphabeticallySmallestEngagementAccountName
              ? data.alphabeticallySmallestEngagementAccountName
              : data.soonestEngagementAccountName;
          }}
        />
        <DataGridHeader
          name={UserBenchTalentSortKeys.soonestEngagementEndDate}
          displayName={
            UserBenchTalentSort[
              UserBenchTalentSortKeys.soonestEngagementEndDate
            ]
          }
          sortingOrder={['asc', null]}
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            const endDate =
              filters.sort === 'alphabeticallySmallestEngagementEndDate'
                ? candidateData.alphabeticallySmallestEngagementEndDate
                : candidateData.soonestEngagementEndDate;

            return (
              <Layout position="middle" horizontalGutter="4px">
                {candidateData.soonestEngagementEndDateStatus ===
                  PositionsConst.EndDateStatuses.Confirmed && (
                  <Gutter top="8px">
                    <span title={Confirmed}>
                      <CalendarIcon withCheck />
                    </span>
                  </Gutter>
                )}
                <LayoutItem>{DateUtil.displayDate(endDate)}</LayoutItem>
              </Layout>
            );
          }}
        />
        <DataGridHeader
          name="actions"
          pinned="right"
          width={120}
          displayName={() =>
            (checkedRows.allRowsChecked ||
              checkedRows.checkedRows.length > 0) && (
              <Layout horizontalGutter="8px" position="center">
                <IconButton
                  pattern="secondary"
                  icon="plus"
                  name="ADDRELATIONSHIPBUTTON"
                  title="Add to Opportunity Log"
                  onClick={() => {
                    if (checkedRows.checkedRows.length > 0) {
                      AppFindOppForCandidateModalStore.openModal(
                        checkedRows.checkedRows.reduce((acc, row) => {
                          acc.push(row.id);
                          return acc;
                        }, [])
                      );
                    }
                  }}
                />

                {!user.roles.includes('EnvoySales') && (
                  <IconButton
                    pattern="secondary"
                    title="Export as CSV"
                    name="EXPORTASCSVBUTTON"
                    data-sourcepage="bench"
                    className="gtm-csv-download"
                    onClick={async () => {
                      const search = {
                        request: {
                          filters: {
                            ids: [
                              ...checkedRows.checkedRows.reduce((acc, row) => {
                                acc.push(row.id);
                                return acc;
                              }, []),
                            ],
                          },
                        },
                      };
                      await downloadTalentList(search);

                      const uri = `${
                        EnvUtil.apiEnvoyUrl
                      }/downloads/candidates?${encodeApiObjects(search)}`;
                      window.location.href = uri;
                    }}
                    icon="download"
                  />
                )}
              </Layout>
            )
          }
          cellRender={({ data }) => {
            const candidateData: Candidate = data;
            return (
              <Layout position="center middle" horizontalGutter="8px">
                <LayoutItem
                  borderRight={data ? '1px solid contentBreak' : null}
                >
                  <Gutter right="8px">
                    <IconButton
                      icon="popout"
                      pattern="secondary"
                      variation="minimal"
                      toTab={`/talent-detail/${candidateData.id}`}
                      title={`Open "${candidateData.calculatedDisplayName}" in new tab`}
                    />
                  </Gutter>
                </LayoutItem>
                {AxiomForTalentUtil.canViewAft(data, user) && (
                  <LayoutItem>
                    <Button
                      pattern="secondary"
                      variation="minimal"
                      onClick={() =>
                        AxiomForTalentUtil.openAftProfile(candidateData.id)
                      }
                      title={`Open A.F.T profile for "${candidateData.calculatedDisplayName}"`}
                    >
                      A.F.T
                    </Button>
                  </LayoutItem>
                )}
              </Layout>
            );
          }}
        />
      </DataGrid>
    </>
  );
};
