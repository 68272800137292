import Ajv from 'ajv';
import { z } from 'zod';
import { SchemaYear } from '@axiom/types';

import { axiomValidationOptions } from './options';

const ajv = new Ajv(axiomValidationOptions());

export const CertificationSchema = z.object({
  id: z.string().uuid(),
  award: z.string().max(255),
  candidateId: z.string().uuid(),
  institution: z.string().max(255),
  stateExamScore: z.string().max(255).nullable(),
  year: SchemaYear,
});

const certificationCommonValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    candidateId: {
      type: 'string',
      format: 'uuid',
    },
    institution: {
      type: 'string',
    },
    award: {
      type: 'string',
    },
    year: {
      type: 'number',
    },
    stateExamScore: {
      type: 'string',
    },
  },
};

const createCertificationValidator = ajv.compile({
  ...certificationCommonValidation,
  required: ['institution', 'award', 'year'],
});

const updateCertificationValidator = ajv.compile({
  ...certificationCommonValidation,
  anyOf: [
    { required: ['institution'] },
    { required: ['award'] },
    { required: ['year'] },
    { required: ['stateExamScore'] },
  ],
});

export { createCertificationValidator, updateCertificationValidator };
