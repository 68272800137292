import React from 'react';
import { bool } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { CandidateOpportunityUtils } from '@axiom/utils';
import {
  Badge,
  Header3,
  IconButton,
  Layout,
  LayoutItem,
  Tab,
  Tabs,
  WarningIcon,
} from '@axiom/ui';

import { CheckboxWithLabel } from '../CheckboxWithLabel/CheckboxWithLabel';
import { Scroller } from '../../styled/Scroller';
import { CpqMarginIndicator } from '../CpqMarginIndicator/CpqMarginIndicator';
import { InlineCol, InlineRow } from '../../themes/components';
import { EditSubmissionStore } from '../../stores/edit-submission-store';
import { talentShape } from '../../models/talent';
import { formatDataTestId } from '../../utils/dataTest';
import { opportunityShape } from '../../models/opportunities';
import { CandidateUtil } from '../../utils/candidate-util';

import {
  SMSCWrapper,
  SMSCHeader,
  SMSCHeaderLeft,
  SMSCStatus,
  SMSCHeaderRight,
  SMSCPopOutButton,
  SMSCBody,
  SMSCIncludeTalentCheckboxWrapper,
} from './SubmissionModalSelectedCandidateStyles';
import SubmissionModalCandidateExperience from './SubmissionModalCandidateExperiences';
import SubmissionModalCandidateRate from './SubmissionModalCandidateRate';

const { CandidateStatuses } = CandidateOpportunitiesConst;

const { CandidateStatusLabels, CANDIDATE_STATUS } = CandidateOpportunitiesConst;

const SUBMISSION_CANDIDATE_ALLOWED_SUBMIT_STATUSES = new Set([
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Submitted,
  CandidateStatuses.Interviewing,
  CandidateStatuses.Selected,
]);

const SUBMISSION_CANDIDATE_NEGATIVE_STATUSES = new Set([
  CandidateStatuses.Rejected,
  CandidateStatuses.Cooled,
]);

class SubmissionModalSelectedCandidate extends React.Component {
  onIncludeChecked = isActiveForSubmission => {
    if (isActiveForSubmission) {
      const { candidate, submission } = this.props;

      const candidates = [
        {
          ...candidate,
          isActiveForSubmission,
        },
        ...submission.candidates.filter(c => c.id !== candidate.id),
      ];

      EditSubmissionStore.setState({
        ...submission,
        candidates,
      });
    } else {
      this.updateCandidate({ isActiveForSubmission });
    }
  };

  updateCandidate = props => {
    const { candidate, submission } = this.props;
    const index = submission.candidates.indexOf(candidate);

    const candidates = [...submission.candidates];
    candidates.splice(index, 1, {
      ...candidate,
      ...props,
    });

    EditSubmissionStore.setState({
      ...submission,
      candidates,
    });
  };

  getAllowedToSubmitCandidateErrorText() {
    const { initialCandidate } = this.props;

    if (
      SUBMISSION_CANDIDATE_NEGATIVE_STATUSES.has(
        initialCandidate.candidateStatus
      )
    ) {
      return "Talent can't be included because their status is not appropriate.";
    }

    if (!CandidateOpportunityUtils.hasSubmittedRate(initialCandidate)) {
      return "Talent can't be included because rate isn't set.";
    }

    if (
      !SUBMISSION_CANDIDATE_ALLOWED_SUBMIT_STATUSES.has(
        initialCandidate.candidateStatus
      )
    ) {
      return "Talent can't be included because their status is not appropriate.";
    }

    return null;
  }

  render() {
    const {
      initialCandidate,
      submission,
      candidate,
      candidate: { experiences = [] },
      opportunity,
      shouldOpenWithSetRateTab,
      readOnly,
    } = this.props;

    return (
      <SMSCWrapper data-test={formatDataTestId('SELECTED_CANDIDATE')}>
        <SMSCHeader data-test={formatDataTestId('header')}>
          <SMSCHeaderLeft>
            <Header3>
              <InlineRow>
                <InlineCol data-test={formatDataTestId('NAME')}>
                  {candidate.calculatedDisplayName}
                </InlineCol>
                <InlineCol>
                  <CpqMarginIndicator
                    candidateOpportunity={initialCandidate}
                    position={candidate.position}
                  />
                </InlineCol>
              </InlineRow>
            </Header3>
            <SMSCStatus data-test={formatDataTestId('CANDIDATE_STATUS')}>
              <Layout horizontalGutter="8px">
                <LayoutItem position="left">
                  {CandidateStatusLabels[candidate[CANDIDATE_STATUS]]}
                </LayoutItem>
                {CandidateUtil.isSubmittedFromWorkFeed(
                  candidate,
                  opportunity
                ) && (
                  <LayoutItem position="right">
                    <Badge name="self-submit-badge" background="gray">
                      Self-Submit
                    </Badge>
                  </LayoutItem>
                )}
              </Layout>
            </SMSCStatus>
          </SMSCHeaderLeft>
          <SMSCHeaderRight>
            <SMSCPopOutButton>
              <IconButton
                icon="popout"
                pattern="secondary"
                variation="minimal"
                toTab={`/talent-detail/${candidate.id}`}
                title={`Open "${candidate.calculatedDisplayName}" in new tab`}
              />
            </SMSCPopOutButton>
          </SMSCHeaderRight>
        </SMSCHeader>
        <SMSCBody>
          <Scroller>
            <SMSCIncludeTalentCheckboxWrapper>
              <CheckboxWithLabel
                name="INCLUDED_ON_TALENT"
                disabled={
                  !!this.getAllowedToSubmitCandidateErrorText() || readOnly
                }
                checked={!!candidate.isActiveForSubmission}
                onValueChange={this.onIncludeChecked}
                id={`include-talent-${candidate.id}`}
                label="Include Talent In Submission"
              />
              {this.getAllowedToSubmitCandidateErrorText() && !readOnly && (
                <div>
                  <InlineRow style={{ marginTop: '0.5rem' }}>
                    <InlineCol>
                      <WarningIcon />
                    </InlineCol>
                    <InlineCol
                      data-test={formatDataTestId('ERROR_ADD_SUBMISSION_TEXT')}
                    >
                      {this.getAllowedToSubmitCandidateErrorText()}
                    </InlineCol>
                  </InlineRow>
                </div>
              )}
            </SMSCIncludeTalentCheckboxWrapper>
            <Tabs startTab={shouldOpenWithSetRateTab ? 'Rate' : 'Experience'}>
              <Tab label="Experience" name="Experience">
                <SubmissionModalCandidateExperience
                  candidate={candidate}
                  experiences={experiences}
                  onCandidateDetailsChanged={this.updateCandidate}
                  readOnly={readOnly}
                />
              </Tab>
              <Tab label="Rate" name="Rate">
                <SubmissionModalCandidateRate
                  submission={submission}
                  readOnly={readOnly}
                  candidate={candidate}
                />
              </Tab>
            </Tabs>
          </Scroller>
        </SMSCBody>
      </SMSCWrapper>
    );
  }
}

SubmissionModalSelectedCandidate.propTypes = {
  initialCandidate: talentShape.isRequired,
  candidate: talentShape.isRequired,
  submission: EditSubmissionStore.getDataShape().isRequired,
  shouldOpenWithSetRateTab: bool,
  readOnly: bool.isRequired,
  opportunity: opportunityShape.isRequired,
};

SubmissionModalSelectedCandidate.defaultProps = {
  shouldOpenWithSetRateTab: false,
};

export default SubmissionModalSelectedCandidate;
