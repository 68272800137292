/*
	Color utilities.
	References:
	- https://stackoverflow.com/questions/9733288
	- https://stackoverflow.com/questions/5623838
*/

/* eslint-disable no-bitwise */

import { GENERAL_STYLES_MAP, STATUS_STYLE_MAP } from '../themes/constants';

export const hexToRGB = hex => {
  if (Array.isArray(hex) && hex.length === 3) return hex;
  if (typeof hex !== 'string' || !/^#?[0-9a-f]{3}([0-9a-f]{3})?$/i.test(hex)) {
    return [0, 0, 0];
  }

  if (hex[0] === '#') hex = hex.slice(1);
  if (hex.length === 3) hex = hex.replaceAll(/(.)/g, '$1$1');

  const bigint = Number.parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

const adjusted = c => {
  if (c > 1) c /= 255;
  return c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
};

export const luminance = (r, g, b) =>
  0.2126 * adjusted(r) + 0.7152 * adjusted(g) + 0.0722 * adjusted(b);

export const contrast = (a, b, pretty) => {
  const la = luminance(...hexToRGB(a)) + 0.05;
  const lb = luminance(...hexToRGB(b)) + 0.05;
  const ratio = la > lb ? la / lb : lb / la;
  return pretty ? (Math.round(ratio * 100) / 100).toFixed(2) : ratio;
};

export const getBestTextColor = (background, a, b) => {
  const ca = contrast(a, background);
  const ba = contrast(b, background);
  return ca > ba ? a : b;
};

export const getEmploymentStylesByStatus = status =>
  STATUS_STYLE_MAP.EMPLOYMENT[status] || GENERAL_STYLES_MAP.FALLBACK;
export const getCandidateStylesByStatus = status =>
  STATUS_STYLE_MAP.CANDIDATE[status] || GENERAL_STYLES_MAP.FALLBACK;

export const getAnyStyleByStatus = status =>
  STATUS_STYLE_MAP.EMPLOYMENT[status] ||
  STATUS_STYLE_MAP.CANDIDATE[status] ||
  GENERAL_STYLES_MAP.FALLBACK;
