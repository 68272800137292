import { z } from 'zod';
import { SchemaDate } from '@axiom/types';

import {
  containsInvalidCharacters,
  invalidCharactersErrorMessage,
} from '../../utils/search';
import {
  TALENT_MAX_COMPENSATION_RATE,
  TALENT_MAX_WEEKLY_AVAILABILITY,
  TALENT_MAX_YEARS_OF_EXPERIENCE,
  TALENT_MIN_COMPENSATION_RATE,
  TALENT_MIN_WEEKLY_AVAILABILITY,
  TALENT_MIN_YEARS_OF_EXPERIENCE,
} from '../utils/constants';

// If it's null we want undefined instead, as otherwise it will appear
// in the filters without a value
const handleNull = (val?: string) => val || undefined;

const handleRangeWithUndefinedSide = (val: number) =>
  val || val === 0 ? Number(val) : undefined;

const weeklyAvailabilitySchema = z.preprocess(
  handleRangeWithUndefinedSide,
  z
    .number()
    .int()
    .nonnegative()
    .min(TALENT_MIN_WEEKLY_AVAILABILITY)
    .max(TALENT_MAX_WEEKLY_AVAILABILITY)
    .nullish()
);

const compensationSchema = z.preprocess(
  handleRangeWithUndefinedSide,
  z
    .number()
    .int()
    .nonnegative()
    .min(TALENT_MIN_COMPENSATION_RATE)
    .max(TALENT_MAX_COMPENSATION_RATE)
    .nullish()
);

const yearsOfExperienceSchema = z.preprocess(
  handleRangeWithUndefinedSide,
  z
    .number()
    .int()
    .nonnegative()
    .min(TALENT_MIN_YEARS_OF_EXPERIENCE)
    .max(TALENT_MAX_YEARS_OF_EXPERIENCE)
    .nullish()
);

const arrayOfStrings = z.preprocess(
  handleNull,
  z.array(z.string().nullish()).nullish()
);

const arrayOfUUID = z.preprocess(
  handleNull,
  z.array(z.string().uuid()).nullish()
);

const rangeRefinementError = 'From cannot be greater than To';
const negativeNumberError = 'Number must be greater than or equal to 0';
const decimalPlaceError = 'Only whole numbers allowed';

function checkAndAddNumericalErrors(
  ctx: z.RefinementCtx,
  field: number,
  errorPath: string
) {
  if (field) {
    if (field < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [errorPath],
        message: negativeNumberError,
      });
    } else if (field !== 0 && field % 1 !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [errorPath],
        message: decimalPlaceError,
      });
    }
  }
}

function checkAndAddRangeError(
  ctx: z.RefinementCtx,
  start: number,
  end: number,
  errorPath: string
) {
  if (start > end) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: [errorPath],
      message: rangeRefinementError,
    });
  }
}

export const CandidateFiltersSchema = z
  .object({
    search: z.preprocess(
      handleNull,
      z
        .string()
        .refine(v => !containsInvalidCharacters(v), {
          message: invalidCharactersErrorMessage,
        })
        .nullish()
    ),
    profileStatus: arrayOfStrings,
    weeklyAvailability: z
      .object({
        start: weeklyAvailabilitySchema,
        end: weeklyAvailabilitySchema,
      })
      // for some reason the error message will not display when added here
      // but the input won't be highlighted red without this .refine().
      // So adding refine to here and full schema object as a work around
      .refine(({ start, end }) => !(start > end), {
        path: ['end'],
      })
      .nullish(),
    daysToSoonestEngagementEnd: z.number().nullish(),
    compensation: z
      .object({
        start: compensationSchema,
        end: compensationSchema,
      })
      // for some reason the error message will not display when added here
      // but the input won't be highlighted red without this .refine().
      // So adding refine to here and full schema object as a work around
      .refine(({ start, end }) => !(start > end), {
        path: ['end'],
      })
      .nullish(),
    practiceAreaId: arrayOfUUID,
    industries: arrayOfStrings,
    homeOfficeId: arrayOfUUID,
    barredLocations: arrayOfUUID,
    yearsOfExperience: z
      .object({ start: yearsOfExperienceSchema, end: yearsOfExperienceSchema })
      // for some reason the error message will not display when added here
      // but the input won't be highlighted red without this .refine().
      // So adding refine to here and full schema object as a work around
      .refine(({ start, end }) => !(start > end), {
        path: ['end'],
      })
      .nullish(),
    employeeType: arrayOfStrings,
    occupationType: arrayOfStrings,
    languages: arrayOfUUID,
    skills: arrayOfUUID,
    tags: arrayOfUUID,
    ownerUserId: arrayOfUUID,
    workPreferences: arrayOfStrings,
    yearsExperienceRadio: z.string().nullish(),
    rollOffDateDropdown: z.number().nullish(),
    rollOffDatePicker: SchemaDate.nullish(),
  })
  .superRefine((schema, ctx) => {
    checkAndAddNumericalErrors(
      ctx,
      schema.weeklyAvailability?.start,
      'weeklyAvailability.start'
    );
    checkAndAddNumericalErrors(
      ctx,
      schema.weeklyAvailability?.end,
      'weeklyAvailability.end'
    );

    checkAndAddRangeError(
      ctx,
      schema.weeklyAvailability?.start,
      schema.weeklyAvailability?.end,
      'weeklyAvailability.end'
    );

    checkAndAddNumericalErrors(
      ctx,
      schema.compensation?.start,
      'compensation.start'
    );
    checkAndAddNumericalErrors(
      ctx,
      schema.compensation?.end,
      'compensation.end'
    );

    checkAndAddRangeError(
      ctx,
      schema.compensation?.start,
      schema.compensation?.end,
      'compensation.end'
    );

    checkAndAddNumericalErrors(
      ctx,
      schema.yearsOfExperience?.start,
      'yearsOfExperience.start'
    );
    checkAndAddNumericalErrors(
      ctx,
      schema.yearsOfExperience?.end,
      'yearsOfExperience.end'
    );

    checkAndAddRangeError(
      ctx,
      schema.yearsOfExperience?.start,
      schema.yearsOfExperience?.end,
      'yearsOfExperience.end'
    );
  });
export type CandidateFiltersForm = z.infer<typeof CandidateFiltersSchema>;
