/**
 * Temp enum for js files to use Industry Values
 */

export enum IndustryValue {
  AerospaceDefense = 'AerospaceDefense',
  Automotive = 'Automotive',
  Banking = 'Banking',
  Brokerage = 'Brokerage',
  BusinessServices = 'BusinessServices',
  Construction = 'Construction',
  Consulting = 'Consulting',
  ConsumerPackagedGoods = 'ConsumerPackagedGoods',
  ConsumerServices = 'ConsumerServices',
  DiversifiedFinancialServices = 'DiversifiedFinancialServices',
  Education = 'Education',
  Energy = 'Energy',
  FoodBeverages = 'FoodBeverages',
  Government = 'Government',
  HardwareElectronicsSemiconductors = 'HardwareElectronicsSemiconductors',
  Healthcare = 'Healthcare',
  HospitalityAttractions = 'HospitalityAttractions',
  Insurance = 'Insurance',
  InternetSocialMedia = 'InternetSocialMedia',
  InvestmentBanking = 'InvestmentBanking',
  LawFirms = 'LawFirms',
  Manufacturing = 'Manufacturing',
  Materials = 'Materials',
  Media = 'Media',
  MedicalDevicesDigitalHealth = 'MedicalDevicesDigitalHealth',
  NA = 'NA',
  NonProfit = 'NonProfit',
  Other = 'Other',
  PharmaBiotech = 'PharmaBiotech',
  ProfessionalServices = 'ProfessionalServices',
  RealEstate = 'RealEstate',
  Retail = 'Retail',
  Software = 'Software',
  Telecom = 'Telecom',
  Transportation = 'Transportation',
  Utilities = 'Utilities',
  VentureCapitalPrivateEquity = 'VentureCapitalPrivateEquity',
}
