import { z } from 'zod';
import { SchemaTimestamp } from '@axiom/types';
import {
  CandidateOppFeedAlertsConst,
  CountryCodesConst,
  EngagementLengthConst,
  PositionsConst,
  TimeCommitment,
} from '@axiom/const';

const { CountryCodes } = CountryCodesConst;
const { CalculatedTalentTypes, Worksites } = PositionsConst;
const { EngagementLengths } = EngagementLengthConst;
const { Frequency } = CandidateOppFeedAlertsConst;

const EngagementLengthValues = Object.values(
  EngagementLengths
) as NonEmptyArray<string>;

const CalculatedTalentTypeValues = Object.values(
  CalculatedTalentTypes
) as NonEmptyArray<string>;

const TimeCommitmentValues = Object.values(
  TimeCommitment.HoursPerWeek
) as NonEmptyArray<string>;

const WorksitesValues = Object.values(Worksites) as NonEmptyArray<string>;

const CountryCodeKeys = Object.keys(CountryCodes) as NonEmptyArray<string>;

const FrequencyValues = Object.values(Frequency) as NonEmptyArray<string>;

export const CandidateAlertWorkFeedSchema = z.object({
  alertName: z.string().max(255),
  countries: z.array(z.enum(CountryCodeKeys)).nullish(),
  createdAt: SchemaTimestamp.nullable(),
  engagementLengths: z.array(z.enum(EngagementLengthValues)).nullish(),
  frequency: z.enum(FrequencyValues),
  id: z.string().uuid().nullish(),
  industries: z.array(z.string()).nullish(),
  legalRoles: z.array(z.enum(CalculatedTalentTypeValues)).nullish(),
  practiceAreas: z.array(z.string().uuid()).nullish(),
  updatedAt: SchemaTimestamp.nullable(),
  weeklyCommitments: z.array(z.enum(TimeCommitmentValues)).nullish(),
  worksites: z.array(z.enum(WorksitesValues)).nullish(),
});
