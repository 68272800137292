import {
  Api,
  BadGatewayError,
  InternalServerError,
  LoggerUtil,
  UnprocessableEntityError,
} from '@axiom/ui';
import {
  MlRecommended,
  Opportunity,
  PostMlRecommendedBody,
  PostMlRecommendedResponseData,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import { PreloadedAppErrorsStore } from '../stores/preloaded-app-errors-store';

class OpportunityCandidateMlRecommendationApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  async createOpportunityCandidateMlRecommendations(
    { opportunityId }: { opportunityId: Opportunity['id'] },
    body: PostMlRecommendedBody
  ) {
    try {
      return await super.write<{ data: PostMlRecommendedResponseData }>({
        method: 'POST',
        endpoint: `/opportunities/${opportunityId}/mlRecommendedCandidates`,
        body,
      });
    } catch (err) {
      if (
        err instanceof BadGatewayError ||
        err instanceof UnprocessableEntityError ||
        err instanceof InternalServerError
      ) {
        PreloadedAppErrorsStore.show(
          `${err.message ?? 'A network error has occurred'}: ${
            (err.getErrors() as { message: string })?.message
          }`
        );
        LoggerUtil.fireError(err);
        return null;
      }
      throw err;
    }
  }

  updateOpportunityCandidateMlRecommendation({
    opportunityId,
    candidateId,
    mlLossCode,
  }: Partial<MlRecommended>) {
    return super.write<MlRecommended>({
      endpoint: `/opportunities/${opportunityId}/mlRecommendedCandidates/${candidateId}`,
      method: 'PATCH',
      body: { mlLossCode },
    });
  }
}

export const OpportunityCandidateMlRecommendationApi =
  new OpportunityCandidateMlRecommendationApiClass();
