import Ajv from 'ajv';

import { axiomValidationOptions } from './options';

const ajv = new Ajv({
  ...axiomValidationOptions(),
});

const samlAuthBodyValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    SAMLResponse: {
      type: 'string',
      minLength: 1,
    },
    RelayState: {
      type: 'string',
    },
  },
  required: ['SAMLResponse'],
};

export const samlAuthBodyValidator = ajv.compile(samlAuthBodyValidation);
