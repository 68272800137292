import React from 'react';
import { func, string, array, number, shape } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import {
  Button,
  DataTest,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';
import { GenericStoreStructure } from '../../classes/generic-store';
import { StateComponentUtil } from '../../utils/state-component-util';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import Input from '../Input/Input';

import { TypeaheadModalItem } from './TypeaheadModalStyles';

class TypeaheadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  waitSearch = debounce(() => this.props.onSearch(this.state.value, 1), 500, {
    leading: false,
  });

  render() {
    const {
      title,
      results,
      onCancel,
      onSearch,
      itemRender,
      totalResultsCount,
      storeState,
      page,
    } = this.props;
    const { value } = this.state;
    return (
      <Modal>
        <ModalHeader onClose={onCancel}>{title}</ModalHeader>
        <ModalSection>
          <div data-test="typeAheadModalContent">
            <Input
              name={formatDataTestId('SEARCH_TYPEAHEAD')}
              placeholder="Type to start searching..."
              showCharsRemainingLabel={false}
              value={value}
              onChange={e => {
                this.setState({ value: e.target.value || '' }, this.waitSearch);
              }}
              autoFocus
            />
            <div style={{ position: 'relative' }}>
              <StoreStateTreatment
                name="RESULTS_CONTAINER"
                storeState={storeState}
                renderLoadedView={() => (
                  <>
                    {value.length > 0 &&
                      !StateComponentUtil.isLoading([storeState]) &&
                      results.length === 0 && (
                        <DataTest value="NO_RESULTS">
                          <i>No results found.</i>
                        </DataTest>
                      )}
                    <InfiniteScroll
                      dataLength={results.length}
                      next={() => onSearch(value, page + 1)}
                      hasMore={totalResultsCount > results.length}
                      height={370}
                    >
                      {results.map((data, key) => (
                        <TypeaheadModalItem
                          key={data.id}
                          data-test={formatDataTestId('SEARCH_RESULT')}
                        >
                          {itemRender(data, key)}
                        </TypeaheadModalItem>
                      ))}
                    </InfiniteScroll>
                  </>
                )}
              />
            </div>
          </div>
        </ModalSection>
        <ModalFooter>
          <Button name="TYPEAHEADCANCELBUTTON" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

TypeaheadModal.propTypes = {
  title: string.isRequired,
  onCancel: func.isRequired,
  onSearch: func.isRequired,
  itemRender: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: array.isRequired,
  page: number,
  totalResultsCount: number.isRequired,
  storeState: shape(GenericStoreStructure).isRequired,
};

TypeaheadModal.defaultProps = {
  page: 0,
};

export default TypeaheadModal;
