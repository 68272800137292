import React, { Component } from 'react';
import { number } from 'prop-types';

import { LoadingGradientWrapper } from './LoadingGradientStyles';

const INITIAL_STATE = {};
class LoadingGradient extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;
  }

  render() {
    const { lines } = this.props;
    const linesArr = Array.from({ length: lines });
    return (
      <LoadingGradientWrapper>
        {linesArr.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={index} className="block-placeholder" />
        ))}
      </LoadingGradientWrapper>
    );
  }
}

LoadingGradient.defaultProps = {
  lines: 3,
};

LoadingGradient.propTypes = {
  lines: number,
};

export default LoadingGradient;
