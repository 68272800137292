/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import DebounceInput from 'react-debounce-input';
import { SearchIcon } from '@axiom/ui';

import { InputWrapper } from './SearchInputStyles';

const SearchInput = props => (
  <InputWrapper>
    <DebounceInput
      debounceTimeout={500}
      // eslint-disable-next-line react/prop-types
      aria-label={props['aria-label'] || props.placeholder || 'Search'}
      placeholder="Search"
      type="search"
      {...props}
    />
    <SearchIcon />
  </InputWrapper>
);

export default SearchInput;
