/* eslint-disable react/destructuring-assignment */

import { exact } from 'prop-types';

import { isUuid } from '../utils/is-uuid';

import { DropdownOptionsShape } from './dropdown-options';

export const BarredLocationsOptionsShape = DropdownOptionsShape;

export const BarredLocationByIdShape = (props, propName, componentName) => {
  const obj = props[propName];
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (!isUuid(key)) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to ${componentName}. Validation failed.`
      );
    }
  }

  return null;
};

export const BarredLocationsShape = exact({
  options: BarredLocationsOptionsShape,
  byId: BarredLocationByIdShape,
});
