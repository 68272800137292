import {
  GROUP_ADMIN_SELECT_GROUP,
  GROUP_ADMIN_SELECT_USER,
  GROUP_ADMIN_RESET_SELECTION,
} from '../constants';

export const INITIAL_STATE = {
  selectedGroup: null,
  selectedUser: null,
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GROUP_ADMIN_SELECT_GROUP: {
      return {
        ...state,
        selectedGroup: typeof payload === 'string' ? payload : payload.id,
        selectedUser: null,
      };
    }
    case GROUP_ADMIN_SELECT_USER: {
      return {
        ...state,
        selectedGroup: null,
        selectedUser: typeof payload === 'string' ? payload : payload.id,
      };
    }
    case GROUP_ADMIN_RESET_SELECTION: {
      return {
        ...state,
        selectedGroup: null,
        selectedUser: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
