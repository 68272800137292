import Ajv from 'ajv';
import { ZodObject, ZodRawShape, z } from 'zod';

import { axiomValidationOptions } from './options';

const ajv = new Ajv({
  ...axiomValidationOptions(),
  coerceTypes: true,
});

// eslint-disable-next-line @typescript-eslint/no-require-imports,unicorn/prefer-module
require('ajv-keywords')(ajv, 'transform');

export const emailValidation = {
  type: 'object',
  additionalProperties: false,
  properties: {
    email: {
      type: 'string',
      format: 'email',
      transform: ['trim', 'toLowerCase'],
    },
  },
};
export const emailValidator = ajv.compile(emailValidation);

export const uuidValidation = {
  type: 'string',
  format: 'uuid',
};
export const uuidValidator = ajv.compile(uuidValidation);

export const arrayValidatorCreator = (validation: unknown) =>
  ajv.compile({ type: 'array', items: validation });

export const enumValidatorCreator = (values: unknown, additionalOpts = {}) =>
  ajv.compile({ enum: values, ...additionalOpts });

export const uuidArrayValidator = arrayValidatorCreator(uuidValidation);

export const requireAtLeastOneDefined = (
  obj: Record<string | number | symbol, unknown>
) => Object.values(obj).some(v => v !== undefined);

export const arraySchemaCreator = (elements: [string, ...string[]]) =>
  z.enum(elements).array();

export const ConfigurableSchema = <Type>(schema: ZodObject<ZodRawShape>) => {
  return ({
    allowPartial = false,
  }: {
    strict?: boolean;
    allowPartial?: boolean;
  }): Type => {
    let modifiedSchema = schema.strict() as unknown as ZodObject<ZodRawShape>;

    if (allowPartial) {
      modifiedSchema = modifiedSchema.refine(
        requireAtLeastOneDefined
      ) as unknown as ZodObject<ZodRawShape>;
    }

    return modifiedSchema as Type;
  };
};
